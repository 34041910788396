import React from 'react';
import { FMCardFiftyFiftyProps } from '../../types/fm-card-fifty-fifty.types';
import FMButton from '../fm-button/fm-button';
import './fm-card-fifty-fifty.scss';
const FMCardFiftyFifty = (props: FMCardFiftyFiftyProps) => {
    const styles = {
        backgroundImage: `url(${props.media.src})`,
    };
    return (
        <>
            <div className='fm-card-fifty-fifty'>
                <div
                    className={`image-display image-display--${props.mediaSide}`}
                >
                    <div className='display-card-image-wrapper'>
                        {props.media && (
                            <div
                                className='display-card-image banner-image'
                                aria-label='Card image'
                                style={styles}
                                role={'img'}
                            />
                        )}
                    </div>
                    <div className={`fm-card-text`}>
                        {props?.title && (
                            <h2 className='fm-card__title'>{props.title}</h2>
                        )}
                        {props.headline &&
                            (props.title ? (
                                <h3 className='fm-card__headline'>
                                    {props.headline}
                                </h3>
                            ) : (
                                <h2 className='fm-card__headline'>
                                    {props.headline}
                                </h2>
                            ))}

                        {props.description && (
                            <div className='fm-card__description'>
                                {props.description}
                            </div>
                        )}
                        <div className={'fm-card__button__container'}>
                            {props.button && props.button.label && (
                                <div className='fm-card__button'>
                                    <FMButton
                                        type={props.button.type}
                                        label={props.button.label}
                                        chevron={props.button.chevron}
                                        disabled={props.button.disabled}
                                        onClick={props.button.onClick}
                                        preventDefault={
                                            props.button.preventDefault
                                        }
                                        href={props.button.href}
                                    />
                                </div>
                            )}
                            {props.secondaryButton &&
                                props.secondaryButton.label && (
                                    <div className='fm-card__secondary__button'>
                                        <FMButton
                                            type={props.secondaryButton.type}
                                            label={props.secondaryButton.label}
                                            chevron={
                                                props.secondaryButton.chevron
                                            }
                                            disabled={
                                                props.secondaryButton.disabled
                                            }
                                            onClick={
                                                props.secondaryButton.onClick
                                            }
                                            preventDefault={
                                                props.secondaryButton
                                                    .preventDefault
                                            }
                                            href={props.secondaryButton.href}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FMCardFiftyFifty;
