import React, { useState } from 'react';
import { PrimaryButton } from '../../../components/common';
import { ActivityIndicator } from '../../../components/common/activity-indicator/activity-indicator';
import { AddVehicle } from '../../../components/sections/add-vehicle/add-vehicle';
import { VehicleTabs } from '../../../components/sections/vehicle-portal/components/vehicle-tabs/vehicle-tabs';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import './FMALogin.scss';
import { useFmaLoginValueContent } from './hook/use-fma-login';
import { useHistory } from 'react-router-dom';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { PageType } from '../../../components/sections/view-template/page-type';
import { useAnalytics } from '../../../hooks/use-analytics';
import { Heading } from '../../../components/common/heading/heading';
import serverSideService from '../../../services/server-side-service/server-side-service';
import localStorageWrapper from '../../../components/utils/local-storage-wrapper/localStorageWrapper';
import { pageTypesForH3 } from '../../support-constants';
import { scrollToTop } from '../../../components/sections/vehicle-health/vha-utils';

interface FmaLoginProps {
    redirectData: RedirectData;
    aemPath?: string;
    eventName?: string;
    handleVinChange?: (vin: string) => void;
    selectedVinCallback?: (vin: string) => void;
    isTabs?: boolean;
}

export interface RedirectData {
    path: string;
    id: string;
}

const FMALogin = ({
    redirectData,
    eventName,
    aemPath,
    handleVinChange,
    selectedVinCallback,
    isTabs,
}: FmaLoginProps) => {
    const authService = new AuthenticationService();
    const fmaLoginValueContent = useFmaLoginValueContent(aemPath);
    const [authenticationState] = useAuthentication();
    const [selectedVehicleAttributes, setSelectedVehicleAttributes] = useState<
        VehicleAttributes
    >();
    const history = useHistory();
    const [fireEvents] = useAnalytics();

    let page: PageType;
    switch (redirectData.id) {
        case 'owner-manual':
            page = 'Owner Manuals';
            break;
        case 'owner-manual-details':
            page = 'Owner Manuals Details';
            break;
        case 'sync-maps':
            page = 'Sync Maps Updates';
            break;
        default:
            page = 'SupportRecallsPage';
    }

    const handleClick = async () => {
        if (authenticationState === AuthenticationState.Authenticated) {
            switch (redirectData.id) {
                case 'recalls':
                    localStorageWrapper.setItem(
                        'USER_VIN',
                        selectedVehicleAttributes?.vin || ''
                    );
                    history.push(
                        redirectData.path
                            .replace(':vin?/', '')
                            .replace(':vinOrModel?/:year?/', '')
                    );
                    break;
                case 'warranty-information':
                case 'sync-maps':
                case 'recalls-details':
                    handleVinChange &&
                        handleVinChange(selectedVehicleAttributes?.vin || '');
                    break;
                case 'owner-manual':
                    if (
                        serverSideService.isClientSide() &&
                        selectedVehicleAttributes?.vin
                    ) {
                        localStorage.setItem(
                            'USER_VIN',
                            selectedVehicleAttributes.vin
                        );
                    }
                    history.push(redirectData.path);
                    break;
                case 'owner-manual-details':
                    if (
                        serverSideService.isClientSide() &&
                        selectedVehicleAttributes?.vin
                    ) {
                        localStorage.setItem(
                            'USER_VIN',
                            selectedVehicleAttributes.vin
                        );
                    }
                    handleVinChange &&
                        handleVinChange(selectedVehicleAttributes?.vin || '');
                    history.push(redirectData.path);
                    scrollToTop();
                    break;
            }
        } else {
            authService.login();
        }
    };

    const handleVehicleChange = (vehicleAttributes: VehicleAttributes) => {
        vehicleAttributes && setSelectedVehicleAttributes(vehicleAttributes);
        selectedVinCallback &&
            selectedVinCallback(vehicleAttributes?.vin || '');
    };
    const type = pageTypesForH3.includes(redirectData.id) ? 'h3' : 'h2';
    return (
        <>
            {fmaLoginValueContent &&
                authenticationState === AuthenticationState.Unauthenticated && (
                    <div data-testid="fma-login" className="fma-login">
                        <Heading
                            type={type}
                            className="login-title selector-label"
                        >
                            {fmaLoginValueContent.unauthenticatedTitle}
                        </Heading>
                        <p>{fmaLoginValueContent.unauthenticatedDescription}</p>
                        <div className="button-wrapper">
                            <PrimaryButton
                                onClick={() => {
                                    if (eventName) {
                                        fireEvents(
                                            eventName,
                                            undefined,
                                            {
                                                ctaType: 'log in',
                                            },
                                            false
                                        );
                                    }
                                    handleClick();
                                }}
                                color="dark"
                                chevron={true}
                                fill="fill"
                                disabled={
                                    fmaLoginValueContent.loginDisabledCheck
                                }
                                role="link"
                                dataTestId={'unauthenticated-button-text'}
                            >
                                {fmaLoginValueContent.unauthenticatedButtonText}
                            </PrimaryButton>
                        </div>
                    </div>
                )}

            {fmaLoginValueContent &&
                authenticationState === AuthenticationState.Pending && (
                    <div className="fma-login">
                        <h3 className="selector-label login-title">
                            {fmaLoginValueContent.unauthenticatedTitle}
                        </h3>
                        <p>{fmaLoginValueContent.unauthenticatedDescription}</p>
                        <ActivityIndicator />
                    </div>
                )}

            {fmaLoginValueContent &&
                authenticationState === AuthenticationState.Authenticated && (
                    <div className="fma-login">
                        {isTabs ? (
                            <>
                                <label
                                    htmlFor="fma-login"
                                    className="input-label"
                                    aria-hidden="true"
                                >
                                    {fmaLoginValueContent.authenticatedTitle}
                                </label>
                            </>
                        ) : (
                            <>
                                <Heading
                                    type={type}
                                    className="selector-label login-title flex-grow-1"
                                >
                                    {fmaLoginValueContent.authenticatedTitle}
                                </Heading>
                            </>
                        )}

                        <VehicleTabs
                            page="VIN"
                            onSelectedVehicleChange={handleVehicleChange}
                            dropDownOnly={true}
                            dropDownPlaceholder={
                                fmaLoginValueContent.noVehiclesMessage
                            }
                            redirectData={redirectData}
                        />
                        <div className="button-wrapper">
                            <PrimaryButton
                                onClick={() => {
                                    if (redirectData.id === 'sync-maps') {
                                        fireEvents(
                                            'sync-update-garage-vehicle',
                                            undefined,
                                            undefined,
                                            false
                                        );
                                    }
                                    handleClick();
                                }}
                                color="dark"
                                fill="fill"
                                chevron={true}
                                disabled={!selectedVehicleAttributes}
                                dataTestId={'authenticated-button-text'}
                            >
                                {fmaLoginValueContent.authenticatedButtonText}
                            </PrimaryButton>

                            <AddVehicle
                                buttonColor="dark"
                                buttonFill="outline"
                                isSupport={true}
                                page={page}
                                isTabs={isTabs}
                            ></AddVehicle>
                        </div>
                    </div>
                )}
        </>
    );
};

export default FMALogin;
