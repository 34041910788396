import { useContext } from 'react';
import { useAppInformationContent } from '../components/sections/breadcrumbs/hook/use-breadcrumb-content';
import ServerContext from '../contexts/serverContext';
import { ExperienceFragmentModel } from '../services/content-service/content-service.interface';
import seoService from '../services/seo-service/seo-service';
import { useExperienceContent } from './use-server-content';

interface SeoConfig extends ExperienceFragmentModel {
    hide: boolean;
    noIndex: boolean;
    pageTitle: string;
    metaDescription: string;
    googleTags: NameValuePair[];
    twitterTags: NameValuePair[];
    facebookTags: NameValuePair[];
}

interface NameValuePair {
    fieldName: string;
    fieldValue: string;
}

const useSeoConfig = (
    name: string,
    path?: string,
    shortcodes?: NameValuePair[]
): SeoConfig | null => {
    const context = useContext(ServerContext);
    !context.metaTags && (context.metaTags = []);
    const appInformationContent = useAppInformationContent();
    if (path) {
        if (path.startsWith('/')) path = path.substring(1, path.length);
        if (path.endsWith('/')) path = path.substring(0, path.length - 1);
    }
    let [content] = useExperienceContent<SeoConfig>(
        path ? `seo/${path}` : 'seo',
        name,
        'seoconfig'
    );
    if (content) {
        context.noIndex = content.noIndex;
    }

    const processShortcodes = (value: string) => {
        if (shortcodes) {
            shortcodes.forEach(shortcode => {
                const placeholder = `[${shortcode.fieldName}]`;
                while (value?.includes(placeholder)) {
                    value = value.replace(placeholder, shortcode.fieldValue);
                }
            });
            return value;
        } else return value;
    };

    if (appInformationContent && content) {
        if (!content.hide) {
            if ('root' in content) {
                // as content-service returns a different response when url has keyword category
                content = (content as any)['root'][':items']['seoconfig'];
            }
            if (content && context.metaTags.length === 0) {
                content.googleTags &&
                    content.googleTags.forEach(googleTag =>
                        context.metaTags.push(
                            `<meta name='${
                                googleTag.fieldName
                            }' content='${processShortcodes(
                                googleTag.fieldValue
                            )}'/>`
                        )
                    );
                content.twitterTags &&
                    content.twitterTags.forEach(twitterTag =>
                        context.metaTags.push(
                            `<meta name='${
                                twitterTag.fieldName
                            }' content='${processShortcodes(
                                twitterTag.fieldValue
                            )}'/>`
                        )
                    );
                content.facebookTags &&
                    content.facebookTags.forEach(facebookTag =>
                        context.metaTags.push(
                            `<meta property='${
                                facebookTag.fieldName
                            }' content='${processShortcodes(
                                facebookTag.fieldValue
                            )}'/>`
                        )
                    );
            }
            seoService.set(
                {
                    applicationName: appInformationContent.applicationName,
                    pageTitle: content
                        ? processShortcodes(content.pageTitle)
                        : '',
                    metaDescription: content
                        ? processShortcodes(content.metaDescription)
                        : '',
                },
                context
            );
        }
    }
    return content;
};

export default useSeoConfig;
