import './oil-life-details.lincoln.scss';
import { Accordion, PrimaryButton } from '../../../../common';
import { LinearProgress } from '../../../../common/linear-progress/linear-progress';
import oilLifeIcon from '../../../../../assets/oil-life-icon.svg';
import { useEffect, useState } from 'react';
import { getVin } from '../../../../utils/getVin-util/getVin-util';
import {
    getOilLifeDataFromAPI,
    getOilLifeStatus,
} from '../vha-alert-list/oillife/oilife';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { useOilLifeDetailsContent } from '../../hook/use-oil-life-details-content';
import { getServiceDueDate, isMobileView, scrollToTop } from '../../vha-utils';
import { BookAServiceCTA } from '../book-a-service/book-a-service';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import downloadCopyIcon from '../../../../../assets/download-copy-icon.png';

interface FaqProps {
    answer: string;
}

const FaqAnswer = (props: FaqProps) => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: props?.answer,
            }}
        />
    );
};

export const OilLifeDetails = () => {
    const selectedVin = getVin();
    const [oilLifeData, setOilLifeData] = useState<any>(null);
    const oilLifeDetailsContent = useOilLifeDetailsContent();

    const oilLifeDetails = oilLifeDetailsContent?.oilLifeDetails;
    const oilLifeFaqList = oilLifeDetails?.oilLifeFaqList;
    const oilLifeDetailLabel = oilLifeDetails?.oilLifeDetailLabel;
    const oilLifeMilesRemaining = oilLifeDetails?.oilLifeMilesRemaining;
    const oilLifePercentage = oilLifeDetails?.oilLifePercentage;
    const oilLifeServiceDue = oilLifeDetails?.oilLifeServiceDue;
    const oilLifeDetailDescription = oilLifeDetails?.oilLifeDetailDescription;
    const bookServiceCTALabel = oilLifeDetails?.bookServiceCTALabel;
    const bookServiceCTALink = oilLifeDetails?.bookServiceCTALink;
    const isDownloadCopy = oilLifeDetails?.enableDownloadCopy;
    const [fireEvents] = useAnalytics();
    const triggerLoadAnalytics = () => {
        fireEvents(
            ['vehicle-health-oil-life'],
            'vehicle-health-oil-life',
            undefined,
            false
        );
    };
    const goToBookAService = () => {
        if (serverSideService.isClientSide()) {
            window.location.href = bookServiceCTALink;
        }
    };

    useEffect(() => {
        scrollToTop();
        triggerLoadAnalytics();
        getOilLifeDataFromAPI(selectedVin).then(oilLifeAPIData => {
            if (oilLifeAPIData?.oilLifeData)
                setOilLifeData(oilLifeAPIData.oilLifeData);
        });
    }, []);

    const getOilLifeAccordion = () => {
        return (
            <>
                {oilLifeFaqList?.map((operation: any, key: number) => (
                    <Accordion
                        key={operation.faqItemQuestion}
                        header={operation.faqItemQuestion}
                        panel={FaqAnswer(operation.faqItemAnswer)}
                        className={'oil-life-panel'}
                        index={key.toString()}
                        expandMultiplePanels={false}
                    />
                ))}
            </>
        );
    };

    const printOilLifeDetailPage = () => {
        if (serverSideService.isClientSide()) {
            window.print();
        }
    };

    const downloadACopy = () => {
        return (
            <div
                className="oillife-details-download-copy-wrapper"
                onClick={printOilLifeDetailPage}
            >
                <img
                    src={downloadCopyIcon}
                    alt="Download copy icon"
                    className="oillife-details-download-copy-icon"
                    aria-label="oillife-details-download-copy-icon"
                />
            </div>
        );
    };

    return (
        oilLifeData && (
            <>
                <div className="oil-life-details-wrapper">
                    <div className="oil-life-header">
                        {oilLifeDetailLabel}
                        {isDownloadCopy && downloadACopy()}
                    </div>
                    <div className="oil-life-content-wrapper">
                        <div className="progress-bar">
                            <LinearProgress
                                progresslevel={oilLifeData.featureData?.iolm}
                                progressState={getOilLifeStatus(oilLifeData)}
                                icon={oilLifeIcon}
                                description={oilLifePercentage}
                            />
                        </div>
                        <div className="oil-life-data-wrapper">
                            <div className="miles-wrapper">
                                <div className="miles">
                                    {oilLifeData.featureData?.remainingMiles}
                                </div>
                                <div className="miles-label">
                                    {oilLifeMilesRemaining}
                                </div>
                            </div>
                            <div className="divider-line"></div>
                            <div className="service-due-wrapper">
                                <div className="service-date">
                                    {getServiceDueDate(oilLifeData)}
                                </div>
                                <div className="service-date-label">
                                    {oilLifeServiceDue}
                                </div>
                            </div>
                        </div>
                        {!isMobileView() && (
                            <div className="oil-life-book-service">
                                <PrimaryButton
                                    fill="fill"
                                    color="dark"
                                    chevron={false}
                                    onClick={goToBookAService}
                                    ariaLabel="Book a Service"
                                >
                                    {bookServiceCTALabel}
                                </PrimaryButton>
                            </div>
                        )}
                    </div>
                    <div className="oil-life-text">
                        {oilLifeDetailDescription}
                    </div>
                    {getOilLifeAccordion()}
                </div>
                {isMobileView() && (
                    <BookAServiceCTA
                        label={bookServiceCTALabel}
                        redirectUrl={bookServiceCTALink}
                    />
                )}
            </>
        )
    );
};
