import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
    VehicleChecklistContent,
    VehicleChecklistFragment,
} from '../../../models/experiencefragments/vehicle-checklist';
import { useExperienceContent } from '../../../hooks/use-server-content';
import './vehicle-checklist-carousel.scss';
import VehicleChecklistRecallsCard from '../../vehicle-checklist/vehicle-checklist-recalls/vehicle-checklist-recalls';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { MMOTAStatusResponse } from '../../../services/mmota-status-service/mmota-status.interface';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import { BRAND, VEHICLE_CHECKLIST_CARD_TYPE } from '../../../constants';
import VehicleChecklistSoftwareUpdate from '../../vehicle-checklist/vehicle-checklist-software-update/vehicle-checklist-software-update';
import VehicleChecklistOrderStatus from '../../vehicle-checklist/vehicle-checklist-order-status/vehicle-checklist-order-status';
import ServerContext from '../../../contexts/serverContext';
import ReservationOrderStatusService from '../../../services/reservation-order-status/reservation-order-status';
import VehicleChecklistDyf from '../../vehicle-checklist/vehicle-checklist-dyf/vehicle-checklist-dyf';
import VehicleChecklistHandover from '../../vehicle-checklist/vehicle-checklist-handover/vehicle-checklist-handover';
import VehicleChecklistSync from '../../vehicle-checklist/vehicle-checklist-sync/vehicle-checklist-sync';
import VehicleChecklistVhr from '../../vehicle-checklist/vehicle-checklist-vhr/vehicle-checklist-vhr';
import VhrService, {
    VhrLanding,
} from '../../../services/vhr-service/vhr-service';
import ServiceHandler from '../../../services/service-handler';
import VehicleCheckListWarranty from '../../vehicle-checklist/vehicle-checklist-warranty/vehicle-checklist-warranty';
import WarrantyService from '../../../services/warranty-service/warranty-service';
import VehicleChecklistFPR from '../../vehicle-checklist/vehicle-checklist-fpr/vehicle-checklist-fpr';
import VehicleChecklistSyncMap from '../../vehicle-checklist/vehicle-checklist-sync-map/vehicle-checklist-sync-map';
import VehicleChecklistCSP from '../../vehicle-checklist/vehicle-checklist-csp/vehicle-checklist-csp';
import {
    MapTile,
    SyncMapService,
    SyncTile,
} from '../../../services/sync-service/sync-map-service';
import { useSyncMapsStatusUpdates } from '../../../views/sync-maps-updates-view/hooks/use-syncs-maps-status-updates';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import DateTimeUtil from '../../utils/date-time-util/date-time-util';
import VehicleCheckListEsb from '../../vehicle-checklist/vehicle-checklist-esb/vehicle-checklist-esb';
import RecallsService, {
    RecallsApiResponse,
} from '../../../services/recalls-service/recalls-service';
import VehicleChecklistVehicleHealth from '../../vehicle-checklist/vehicle-checklist-vehicle-health/vehicle-checklist-vehicle-health';
import { PageType } from '../view-template/page-type';
import ConnectedVehicleService from '../../../services/connected-vehicle-service/connected-vehicle-service';
import { ConnectedVehicleResponse } from '../../../models/connected-vehicle';
import VehicleCheckListYFA from '../../vehicle-checklist/vehicle-checklist-yfa/vehicle-checklist-yfa';
import YfaService from '../../../services/yfa-service/yfa-service';
import VehicleCheckListSubscriptions from '../../vehicle-checklist/vehicle-checklist-subscriptions/vehicle-checklist-subscriptions';
import SubscriptionsService, {
    SubscriptionsReponse,
} from '../../../services/subscriptions-service/subscriptions-service';
import { FMCarousel } from '@own/fds-react';
import { Carousel } from '../../common/carousel/carousel';

interface Props {
    vehicleAttributes: VehicleAttributes;
    displayMMOTAModal: () => void;
    otaStatus: MMOTAStatusResponse | undefined;
    pageType: PageType;
    vehicleStatus: any;
}

export const VehicleChecklistCarousel = (props: Props) => {
    const [vehicleChecklistContent] = useExperienceContent<
        VehicleChecklistFragment
    >('vehicles', 'vehicle-checklist', 'vehicle_checklist');
    const [authenticationState] = useAuthentication();
    const [orderStatus, setOrderStatus] = useState<boolean>(false);
    const [recallDataForTile, setRecallDataForTile] = useState<
        RecallsApiResponse
    >();
    const [vhrData, setVhrData] = useState<VhrLanding | null>();
    const [warrantyDate, setWarrantyDate] = useState<string>();
    const [syncData, setSyncData] = useState<SyncTile>();
    const [syncMapData, setSyncMapData] = useState<MapTile>();
    const [warrantyPeriod, setWarrantyPeriod] = useState<number>();
    const [warrantyOdometer, setwarrantyOdometer] = useState<string>();
    const [esbContracts, setEsbContracts] = useState(false);
    const [isYfaEligible, setYfaEligible] = useState<boolean>(false);
    const [subscriptionsData, setSubscriptionsData] = useState<
        SubscriptionsReponse
    >();
    const isMobile = window?.innerWidth < 992;
    const [vehicleStatus, setVehicleStatus] = useState<
        ConnectedVehicleResponse | undefined
    >();
    const handoverSupportedModels =
        vehicleChecklistContent?.handoverSupportedModels;
    const isYfaEligibleModel =
        vehicleChecklistContent?.yfaEligibleModels?.some(item =>
            props?.vehicleAttributes?.model?.includes(item.modelAlias)
        ) && props?.vehicleAttributes?.engineType === 'BEV';
    const hasSubscriptionsContent = vehicleChecklistContent?.checkListTiles
        .map(tile => tile?.cardTypes)
        .includes(VEHICLE_CHECKLIST_CARD_TYPE.SUBSCRIPTIONS);

    const { syncGeneration } = useSyncMapsStatusUpdates(
        props.vehicleAttributes.vin || '',
        '',
        ''
    );
    const { vin, model, year } = props.vehicleAttributes;
    const dyfExemptModels2020 = ['Continental', 'MKZ', 'MKZ Hybrid'];
    const canadaCountryCode = 'ca';

    const LATEST_SYNC_GENERATION = '4';
    const filterOTA = (tile: VehicleChecklistContent) => {
        if (
            !props.otaStatus ||
            (syncGeneration !== LATEST_SYNC_GENERATION &&
                syncGeneration !== '' &&
                syncGeneration !== '-1')
        )
            return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.OTA;
        return tile;
    };
    const filterSyncStatus = (tile: VehicleChecklistContent) => {
        if (
            !syncData ||
            !syncData.showSyncTile ||
            syncGeneration === LATEST_SYNC_GENERATION ||
            syncGeneration == '' ||
            syncGeneration == '-1'
        )
            return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.SYNC;
        return tile;
    };
    const filterOrderStatus = (tile: VehicleChecklistContent) => {
        if (!orderStatus)
            return (
                tile.cardTypes !==
                VEHICLE_CHECKLIST_CARD_TYPE.RESERVATION_ORDER_STATUS
            );
        return tile;
    };
    const filterVhrStatus = (tile: VehicleChecklistContent) => {
        if (!vhrData) return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.VHR;
        return tile;
    };
    const filterVehicleHealth = (tile: VehicleChecklistContent) => {
        if (
            vehicleStatus?.vehiclestatus?.vin !== props.vehicleAttributes.vin ||
            props.pageType !== 'DASHBOARD'
        )
            return (
                tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.VEHICLE_HEALTH
            );
        return tile;
    };
    const supportsHandover = (): boolean => {
        const model: string = props?.vehicleAttributes?.model || '',
            year: number = props?.vehicleAttributes?.year || 0;

        if (handoverSupportedModels && handoverSupportedModels.length && year) {
            return handoverSupportedModels.some((item: any) => {
                if (
                    year >= item.minYear &&
                    model.toLowerCase() === item.modelAlias.toLowerCase()
                ) {
                    return true;
                }
            });
        }
        return false;
    };
    const filterHandoverStatus = (tile: VehicleChecklistContent) => {
        if (!supportsHandover())
            return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.HANDOVER;
        return tile;
    };
    const filterEsbStatus = (tile: VehicleChecklistContent) => {
        if (!esbContracts)
            return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.ESB;
        return tile;
    };
    const filterRecallsStatus = (tile: VehicleChecklistContent) => {
        if (
            (props.vehicleAttributes.vin && recallDataForTile === undefined) ||
            recallDataForTile?.recallsCount == 0
        )
            return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.RECALLS;
        return tile;
    };
    const filterYfa = (tile: VehicleChecklistContent) => {
        if (isYfaEligibleModel && isYfaEligible) return tile;
        return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.YFA;
    };
    const filterSubscriptions = (tile: VehicleChecklistContent) => {
        if (authenticationState == AuthenticationState.Authenticated)
            return tile;
        return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.SUBSCRIPTIONS;
    };
    const filterDyf = (tile: VehicleChecklistContent) => {
        if (
            appConfig.brand === BRAND.lincoln.LONG_NAME &&
            (year < 2020 ||
                (year === 2020 &&
                    appConfig.get2LetterCountryCode() === canadaCountryCode) ||
                dyfExemptModels2020.includes(model))
        ) {
            return tile.cardTypes !== VEHICLE_CHECKLIST_CARD_TYPE.DYF;
        }
        return tile;
    };
    const serverContext = useContext(ServerContext);
    const countryCode = serverContext.current3LetterCountryCode;
    const filters = [
        filterOTA,
        filterOrderStatus,
        filterVhrStatus,
        filterHandoverStatus,
        filterSyncStatus,
        filterEsbStatus,
        filterRecallsStatus,
        filterVehicleHealth,
        filterYfa,
        filterSubscriptions,
        filterDyf,
    ];
    const appConfig = new AppConfigurationService();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    useEffect(() => {
        if (
            !vehicleStatus &&
            props.vehicleAttributes.vin &&
            authenticationState == AuthenticationState.Authenticated
        )
            new ConnectedVehicleService()
                .getVehicleStatus(
                    props.vehicleAttributes.vin,
                    props.vehicleAttributes.year
                )
                .then(vehicleStatusData => {
                    setVehicleStatus(vehicleStatusData);
                });
    }, [props.vehicleAttributes.vin, authenticationState]);
    useEffect(() => {
        if (
            props.vehicleAttributes.vin &&
            authenticationState === AuthenticationState.Authenticated
        ) {
            new ReservationOrderStatusService()
                .request(countryCode)
                .then(response => setOrderStatus(response));
        }
    }, [props.vehicleAttributes.vin, authenticationState]);
    useEffect(() => {
        setWarrantyDate(undefined);
        setWarrantyPeriod(undefined);
        setwarrantyOdometer(undefined);
        if (props.vehicleAttributes.vin) {
            new VhrService()
                .request(props.vehicleAttributes.vin)
                .then(response => {
                    setVhrData(response);
                });
            if (appConfig.isRegionAPA()) {
                if (
                    appConfig.isCountryZAF() &&
                    props.vehicleAttributes.warrantyStartDate
                ) {
                    setWarrantyDate(
                        new DateTimeUtil().formatDateByRegion(
                            props.vehicleAttributes.warrantyStartDate
                        )
                    );
                }
                if (!appConfig.isCountryZAF()) {
                    new WarrantyService()
                        .getImgWarrantyService(props.vehicleAttributes.vin)
                        .then(response => {
                            if (response) {
                                setEsbContracts(
                                    response?.value?.contracts?.length > 0
                                );
                                const warrantyStartDate =
                                    response.value?.baseWarranty
                                        ?.warrantyStartDate;
                                const warrantyEndDate =
                                    response.value?.baseWarranty
                                        ?.warrantyEndDate;
                                const warrantyEndOdometer =
                                    response.value?.baseWarranty
                                        ?.warrantyEndOdometer;
                                if (
                                    warrantyStartDate &&
                                    warrantyEndDate &&
                                    warrantyEndOdometer
                                ) {
                                    const warrantyPeriod =
                                        new Date(
                                            warrantyEndDate
                                        ).getFullYear() -
                                        new Date(
                                            warrantyStartDate
                                        ).getFullYear();
                                    setWarrantyPeriod(warrantyPeriod);
                                    setwarrantyOdometer(warrantyEndOdometer);
                                }
                            }
                        });
                }
                if (appConfig.isCountryZAF()) {
                    ServiceHandler.ZafEsbService.getEsbForZAF(
                        props.vehicleAttributes.vin
                    ).then(response => {
                        if (response) {
                            setEsbContracts(response?.contracts?.length > 0);
                        }
                    });
                }
            } else {
                new WarrantyService()
                    .getWarrantyDate(
                        props.vehicleAttributes.vin,
                        authenticationState ===
                            AuthenticationState.Authenticated
                    )
                    .then(warrantyDate => {
                        if (warrantyDate) setWarrantyDate(warrantyDate);
                    });
            }
            new SyncMapService()
                .request(props.vehicleAttributes.vin)
                .then(response => {
                    if (response && response.syncTile) {
                        setSyncData(response.syncTile);
                    }
                    if (response && response.mapTile) {
                        setSyncMapData(response.mapTile);
                    }
                });
            new RecallsService()
                .request(props.vehicleAttributes.vin)
                .then(response => {
                    if (response) {
                        setRecallDataForTile(response);
                    } else {
                        setRecallDataForTile(undefined);
                    }
                })
                .catch(() => setRecallDataForTile(undefined));
        }
    }, [props.vehicleAttributes.vin, authenticationState]);

    useEffect(() => {
        if (
            authenticationState === AuthenticationState.Authenticated &&
            isYfaEligibleModel &&
            vin
        ) {
            new YfaService()
                .isEligible(vin)
                .then(response => setYfaEligible(response?.eligible));
        }
    }, [vin, authenticationState, isYfaEligibleModel]);

    useEffect(() => {
        if (
            authenticationState === AuthenticationState.Authenticated &&
            hasSubscriptionsContent &&
            vin
        ) {
            new SubscriptionsService()
                .getSubscriptions(vin)
                .then(response => setSubscriptionsData(response));
        }
        return () => {
            setSubscriptionsData(undefined);
        };
    }, [vin, authenticationState, hasSubscriptionsContent]);

    const getFilteredCards = (): VehicleChecklistContent[] => {
        let result: VehicleChecklistContent[] = [];
        if (vehicleChecklistContent) {
            result = vehicleChecklistContent.checkListTiles.filter(tile => {
                return filters.every(fn => fn(tile));
            });
        }
        return result;
    };
    const renderVehicleChecklistCard = (item: any): ReactNode => {
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.RECALLS)
            return (
                <VehicleChecklistRecallsCard
                    {...item}
                    {...props.vehicleAttributes}
                    recallDataWithVin={recallDataForTile}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.OTA)
            return (
                <VehicleChecklistSoftwareUpdate
                    {...item}
                    {...props.vehicleAttributes}
                    displayModal={props?.displayMMOTAModal}
                    otaApiStatus={props?.otaStatus}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.SYNC)
            return (
                <VehicleChecklistSync
                    {...item}
                    {...props.vehicleAttributes}
                    syncData={syncData}
                />
            );
        if (
            item.cardTypes ===
            VEHICLE_CHECKLIST_CARD_TYPE.RESERVATION_ORDER_STATUS
        )
            return (
                <VehicleChecklistOrderStatus
                    {...item}
                    {...props.vehicleAttributes}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.DYF)
            return (
                <VehicleChecklistDyf {...item} {...props.vehicleAttributes} />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.HANDOVER)
            return (
                <VehicleChecklistHandover
                    {...item}
                    {...props.vehicleAttributes}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.VHR)
            return (
                <VehicleChecklistVhr
                    {...item}
                    {...props.vehicleAttributes}
                    vhrData={vhrData}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.WARRANTY)
            return (
                <VehicleCheckListWarranty
                    {...item}
                    {...props.vehicleAttributes}
                    warrantyDate={warrantyDate}
                    warrantyPeriod={warrantyPeriod}
                    warrantyOdometer={warrantyOdometer}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.FPR)
            return (
                <VehicleChecklistFPR {...item} {...props.vehicleAttributes} />
            );
        if (
            item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.SYNC_MAPS ||
            item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.SOFTWARE_UPDATE_MAPS
        )
            return (
                <VehicleChecklistSyncMap
                    {...item}
                    {...props.vehicleAttributes}
                    syncMapData={syncMapData}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.CSP)
            return (
                <VehicleChecklistCSP {...item} {...props.vehicleAttributes} />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.ESB)
            return (
                <VehicleCheckListEsb {...item} {...props.vehicleAttributes} />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.VEHICLE_HEALTH)
            return (
                <VehicleChecklistVehicleHealth
                    {...item}
                    {...props.vehicleAttributes}
                />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.YFA)
            return (
                <VehicleCheckListYFA {...item} isYfaEligible={isYfaEligible} />
            );
        if (item.cardTypes === VEHICLE_CHECKLIST_CARD_TYPE.SUBSCRIPTIONS)
            return (
                <VehicleCheckListSubscriptions
                    {...item}
                    vin={vin}
                    data={subscriptionsData}
                />
            );
    };
    return vehicleChecklistContent &&
        !vehicleChecklistContent?.hideVehicleChecklist &&
        getFilteredCards().length ? (
        <div className={'vehicle-checklist-carousel-wrapper'}>
            {getFilteredCards().length > 3 ||
            (isMobile && getFilteredCards().length > 1) ||
            !currentVersion ? (
                <>
                    {currentVersion ? (
                        <>
                            <div className={'vehicle-checklist-title'}>
                                {vehicleChecklistContent?.checklistTitle}
                            </div>
                            <Carousel<VehicleChecklistContent>
                                items={getFilteredCards()}
                                render={renderVehicleChecklistCard}
                                paginationText={
                                    vehicleChecklistContent?.carouselIndicator
                                }
                            />
                        </>
                    ) : (
                        <FMCarousel
                            title={vehicleChecklistContent?.checklistTitle}
                            items={getFilteredCards()}
                            render={renderVehicleChecklistCard}
                            paginationText={
                                vehicleChecklistContent?.carouselIndicator
                            }
                            isUniMode={getFilteredCards()?.length < 4}
                        />
                    )}
                </>
            ) : (
                <div className={'no-carousel-container'}>
                    <div className={'vehicle-checklist-title'}>
                        {vehicleChecklistContent?.checklistTitle}
                    </div>
                    <div className={'no-carousel'}>
                        {getFilteredCards().map(item =>
                            renderVehicleChecklistCard(item)
                        )}
                    </div>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};
