import { useContent } from '../../../../hooks/use-server-content';

export interface OwnerManualProps {
    hideContent?: boolean;
    title: string;
    subtitle: string;
    subtitleDescription: string;
    sitemapLinkText: string;
    accordionTitle: string;
    relatedTopicsTitle: string;
    relatedTopicsContent: string;
    vehicleSelectorYearDropdownLabel: string;
    vehicleSelectorVinSubtitle: string;
    vehicleSelectorVinInput: string;
    vehicleSelectorModelDropdownLabel: string;
    vehicleSelectorSubmitButtonLabel: string;
    vehicleSelectorDisclaimerLabel: string;
    vehicleSelectorModelSubtitle: string;
    vehicleSelectorDividerText: string;
    detailsEmptyApiResponse: string;
    vinTooltipHeader: string;
    vinTooltipAriaLabel: string;
    vinTooltipContent: string;
    detailsSelectNewVehicle: string;
    detailsPastPrintings: string;
    detailsPastPrintingsAccordionTitle: string;
    detailsPastPrintingsAccordionSeeMore: string;
    detailsViewDocument: string;
    loginSwitchLabel: string;
    loggedInSwitchLabel: string;
    vehicleSwitchLabel: string;
    sitemapChevronLabel: string;
    sitemapAccordionSubtitleVehicleList: string;
    sitemapAccordionSubtitleYearList: string;
    sitemapSubtitleDescription: string;
    sitemapSubtitle: string;
    sitemapTitle: string;
    sitemapToOwnerManual: string;
    sitemapByYearToggle: string;
    sitemapByModelToggle: string;
    oldestYear: number;
    tooOldMsg: string;

    //ai stuff to add
    aiSearchTitle: string;
    aiSearchSubtitle: string;
    aiSearchClearButtonArialLabel: string;
    aiSearchButtonAriaLabel: string;
    aiSearchError: string;
    aiSearchInputAriaLabel: string;

    aiChatBreadCrumbName: string;
    aiChatInputPlaceHolder: string;
    aiChatInputAriaLabel: string;
    aiChatSubmitButtonAriaLabel: string;
    aiChatErrorMessage: string;
}

export const useOwnerManualContent = (): OwnerManualProps | null => {
    const [content, getValueByName] = useContent(
        'owner-manual',
        'owner-manual'
    );
    let contentFragment: any = { title: 'loading' };

    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });

    if (content === null && contentFragment.title === 'loading') {
        return contentFragment;
    } else {
        if (contentFragment.hideContent || !contentFragment.title) {
            contentFragment = null;
        }
    }
    return contentFragment as OwnerManualProps;
};
