import redis from 'redis';
import serverSideService from '../server-side-service/server-side-service';
export class RedisCacheService {
    private redisClient: any;
    constructor() {
        if (process.env.REACT_APP_PLATFORM === 'gcp') {
            if (serverSideService.isServerSide()) {
                this.redisClient = redis.createClient({
                    port: process.env.REACT_APP_REDIS_PORT,
                    host: process.env.REACT_APP_REDIS_HOST,
                    password: process.env.REACT_APP_REDIS_CERT_PWD,
                    tls: {
                        ca: [process.env.REACT_APP_REDIS_CA_CERT],
                    },
                });
                this.redisClient.on('error', (err: string) => {
                    console.info('Error connecting to Redis');
                    console.error(err);
                    this.redisClient.quit();
                });
                this.redisClient.on('ready', () => {
                    console.info('Redis Client is ready!');
                });
                this.redisClient.on('reconnecting', () => {
                    console.info('Connection lost! Reconnecting to Redis...');
                });
            }
        } else {
            const vcapServices = process.env.VCAP_SERVICES || '';
            if (vcapServices) {
                const rediscloudService = JSON.parse(vcapServices)['p.redis'];
                if (rediscloudService) {
                    const credentials = rediscloudService[0].credentials;
                    this.redisClient = redis.createClient(
                        credentials.port,
                        credentials.host,
                        {
                            // eslint-disable-next-line
                            no_ready_check: true,
                        }
                    );
                    this.redisClient.auth(credentials.password);
                }
            } else {
                if (
                    process.env.REACT_APP_REDIS_PORT &&
                    serverSideService.isServerSide()
                ) {
                    this.redisClient = redis.createClient(
                        process.env.REACT_APP_REDIS_PORT
                    );
                    this.redisClient.on('error', (err: string) => {
                        console.error(err);
                        this.redisClient.quit();
                    });
                    this.redisClient.on('ready', () => {
                        console.info('Redis Client is ready!');
                    });
                    this.redisClient.on('reconnecting', () => {
                        console.info(
                            'Connection lost! Reconnecting to Redis...'
                        );
                    });
                }
            }
        }
    }
    public getRedisClient(): any {
        return this.redisClient;
    }
}

const redisCacheService = new RedisCacheService();
export default redisCacheService;
