import React, { ReactNode, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { InvalidStatusResponse } from '../../../../../models/invalid-status-response';
import { SuccessStatusResponse } from '../../../../../models/success-status-response';
import {
    Link,
    PrimaryButton,
    SecondaryButton,
} from '../../../../../components/common';
import { ActivityIndicator } from '../../../../../components/common/activity-indicator/activity-indicator';
import { useCheckRebateStatusContent } from '../../hooks/use-check-rebate-status-content';
import { useAnalytics } from '../../../../../hooks/use-analytics';

import './check-rebate-status.scss';
import ServiceHandler from '../../../../../services/service-handler';
import { validate } from './check-rebate-status-utils';
import { RebateDetails } from './rebate-details';

interface Props {
    click: () => void;
}

export const CheckRebateStatus = (props: Props) => {
    const rebatesStepName = {
        rebatesStepName: 'check status',
    };
    const [fireEvents] = useAnalytics();
    useAnalytics(['redeem-rebates-status-generic'], undefined, rebatesStepName);
    const TABLET_BREAKPOINT = 768;
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const [invalidApi, setInvalidApi] = useState<InvalidStatusResponse>();
    const [apiResponse, setApiResponse] = useState<SuccessStatusResponse>();
    const [loading, setLoading] = useState(false);
    const [printcard, setprintcard] = useState<ReactNode | null>();
    const [size, setSize] = useState(window.innerWidth);

    const checkRebatresScroll = () => {
        if (size < 350) {
            window.scrollTo({
                top: 2300,
                behavior: 'smooth',
            });
        } else if (size < 500) {
            window.scrollTo({
                top: 1900,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({
                top: 1150,
                behavior: 'smooth',
            });
        }
    };

    const rebatesStatus = useCheckRebateStatusContent();

    useEffect(() => {
        const handleResize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [data, setData] = useState({
        email: '',
        trackingNumber: '',
    });

    const [isTouched, setTouched] = useState({
        email: false,
        trackingNumber: false,
    });
    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            email: event.target.value,
        });
    };

    const onTrackingNumberChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setData({
            ...data,
            trackingNumber: event.target.value,
        });
    };

    const handleRebatesPrintClick = (index: number) => {
        setprintcard(null);
        printPage(index);
        fireEvents(
            'rr-confirm-status-cta-onclick-event',
            undefined,
            undefined,
            false
        );
    };

    const handleRebateResponseClick = () => {
        checkRebatresScroll();
        setTouched({
            ...isTouched,
            email: false,
            trackingNumber: false,
        });
        setData({
            ...data,
            email: '',
            trackingNumber: '',
        });
        setInvalidApi(undefined);
        setApiResponse(undefined);
    };

    const printPage = (index: any) => {
        const id = `rebates${index}`;

        const printcard: any = document.getElementById(id)?.innerHTML
            ? document.getElementById(id)?.innerHTML
            : 'error';
        const printcardParse: any = parse(printcard);
        setprintcard(printcardParse);
        setTimeout(() => {
            window.print();
        }, 10);
    };

    function isValid(arr: any[]) {
        return arr.some(o => o.errorID);
    }

    const getUser = () => {
        setLoading(true);
        ServiceHandler.CheckStatusService.checkRebatesStatus(
            data.email,
            data.trackingNumber
        )
            .then(response => {
                isValid(
                    response.submitStatusResponse.submitStatusRequestResult
                        .anyType
                )
                    ? setInvalidApi(response)
                    : setApiResponse(response);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        getUser();
    };

    useEffect(() => {
        const headingElement = document.getElementById('heading');
        if (headingElement) {
            headingElement.scrollIntoView();
            window.scrollTo(0, window.scrollY - 84);
        }
        if (apiResponse) {
            fireEvents('redeem-rebates-status-generic', undefined, {
                rebatesStepName: 'confirm status',
            });
        }
    }, [apiResponse]);

    return (
        <>
            {apiResponse ? (
                <>
                    <RebateDetails
                        rebatesStatus={rebatesStatus}
                        apiResponse={apiResponse}
                        printCard={printcard}
                        data={data}
                        primaryButtonRef={primaryButtonRef}
                        handleRebatesPrintClick={handleRebatesPrintClick}
                        handleRebateResponseClick={handleRebateResponseClick}
                    />
                </>
            ) : (
                rebatesStatus && (
                    <div className="check-rebates-form">
                        <div className="check-rebate-status">
                            <h2 className="form__title">
                                {rebatesStatus.checkRebatesTitle}
                            </h2>
                            <p className="form-text">
                                {rebatesStatus.questionsPartOneText}{' '}
                                <Link
                                    className="form-link"
                                    href={
                                        'mailto:' +
                                        rebatesStatus.questionsPartTwoEmail
                                    }
                                    onClick={() => {
                                        fireEvents(
                                            'rr-check-status-cta-onclick-event',
                                            undefined,
                                            {
                                                ctaType: 'email',
                                            },
                                            false
                                        );
                                    }}
                                >
                                    {rebatesStatus.questionsPartTwoEmail}
                                </Link>{' '}
                                {rebatesStatus.questionsPartThreeText}{' '}
                                {rebatesStatus.questionsPartFourNumber}
                            </p>
                        </div>
                        <div className="l-form">
                            {loading ? (
                                <ActivityIndicator />
                            ) : (
                                <>
                                    <form
                                        data-testId="form"
                                        onSubmit={handelSubmit}
                                        className="form_elements"
                                    >
                                        <div className="service-rebates-row">
                                            <div className="row-elements">
                                                <div className="form__div">
                                                    <input
                                                        data-testid="tracking-number"
                                                        placeholder=" "
                                                        id="tracking-number"
                                                        className={`form__input${
                                                            invalidApi
                                                                ? ' invalid'
                                                                : ''
                                                        }`}
                                                        aria-label={
                                                            'Enter' +
                                                            rebatesStatus.trackingNumberText +
                                                            ` ${
                                                                isTouched.trackingNumber
                                                                    ? validate(
                                                                          data,
                                                                          rebatesStatus
                                                                      )
                                                                          .trackingNumberRequired
                                                                    : ''
                                                            }`
                                                        }
                                                        value={
                                                            data.trackingNumber
                                                        }
                                                        onInput={
                                                            onTrackingNumberChange
                                                        }
                                                        onBlur={() =>
                                                            setTouched({
                                                                ...isTouched,
                                                                trackingNumber: true,
                                                            })
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="tracking-number"
                                                        className={`form__label${
                                                            invalidApi
                                                                ? ' invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        {
                                                            rebatesStatus.trackingNumberText
                                                        }
                                                    </label>
                                                </div>
                                                {isTouched.trackingNumber &&
                                                validate(data, rebatesStatus)
                                                    .trackingNumberRequired ? (
                                                    <div>
                                                        <span className="form__error">
                                                            {
                                                                validate(
                                                                    data,
                                                                    rebatesStatus
                                                                )
                                                                    .trackingNumberRequired
                                                            }
                                                        </span>
                                                    </div>
                                                ) : null}
                                                {size > TABLET_BREAKPOINT && (
                                                    <SecondaryButton
                                                        className=""
                                                        onClick={() => {
                                                            props.click();
                                                            fireEvents(
                                                                'rr-check-status-cta-onclick-event',
                                                                undefined,
                                                                {
                                                                    ctaType:
                                                                        'forgot your tracking number',
                                                                },
                                                                false
                                                            );
                                                        }}
                                                        noChevron={true}
                                                        type={'button'}
                                                    >
                                                        {
                                                            rebatesStatus.forgotTrackingNumber
                                                        }
                                                    </SecondaryButton>
                                                )}
                                            </div>
                                            <div className="row-elements">
                                                <div className="form__div">
                                                    <input
                                                        data-testid="Email"
                                                        id="email-id"
                                                        placeholder=" "
                                                        aria-label={
                                                            'Enter' +
                                                            rebatesStatus.emailText +
                                                            ` ${
                                                                isTouched.email
                                                                    ? validate(
                                                                          data,
                                                                          rebatesStatus
                                                                      )
                                                                          .emailRequired
                                                                    : ''
                                                            }`
                                                        }
                                                        className={`form__input${
                                                            invalidApi
                                                                ? ' invalid'
                                                                : ''
                                                        }`}
                                                        value={data.email}
                                                        onInput={onEmailChange}
                                                        onBlur={() =>
                                                            setTouched({
                                                                ...isTouched,
                                                                email: true,
                                                            })
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="email-id"
                                                        className={`form__label${
                                                            invalidApi
                                                                ? ' invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        {
                                                            rebatesStatus.emailText
                                                        }
                                                    </label>
                                                </div>
                                                {isTouched.email &&
                                                validate(data, rebatesStatus)
                                                    .emailRequired ? (
                                                    <div>
                                                        <span className="form__error">
                                                            {
                                                                validate(
                                                                    data,
                                                                    rebatesStatus
                                                                ).emailRequired
                                                            }
                                                        </span>
                                                    </div>
                                                ) : null}
                                                {size <= TABLET_BREAKPOINT && (
                                                    <SecondaryButton
                                                        type={'button'}
                                                        onClick={() => {
                                                            if (size < 350) {
                                                                window.scrollTo(
                                                                    {
                                                                        top: 2300,
                                                                        behavior:
                                                                            'smooth',
                                                                    }
                                                                );
                                                            } else if (
                                                                size < 500
                                                            ) {
                                                                window.scrollTo(
                                                                    {
                                                                        top: 1900,
                                                                        behavior:
                                                                            'smooth',
                                                                    }
                                                                );
                                                            }
                                                            props.click();
                                                            fireEvents(
                                                                'rr-check-status-cta-onclick-event',
                                                                undefined,
                                                                {
                                                                    ctaType:
                                                                        'forgot your tracking number',
                                                                },
                                                                false
                                                            );
                                                        }}
                                                        noChevron={true}
                                                    >
                                                        {
                                                            rebatesStatus.forgotTrackingNumber
                                                        }
                                                    </SecondaryButton>
                                                )}
                                            </div>
                                            <div className="row-button">
                                                <PrimaryButton
                                                    ref={primaryButtonRef}
                                                    color="dark"
                                                    fill="fill"
                                                    type={'submit'}
                                                    disabled={
                                                        !validate(
                                                            data,
                                                            rebatesStatus
                                                        ).allValid
                                                    }
                                                    chevron={true}
                                                    onClick={() => {
                                                        setInvalidApi(
                                                            undefined
                                                        );
                                                        setApiResponse(
                                                            undefined
                                                        );
                                                    }}
                                                >
                                                    {
                                                        rebatesStatus.formButtonText
                                                    }
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                        {invalidApi?.submitStatusResponse
                                            .submitStatusRequestResult
                                            .anyType[0].message ? (
                                            <p
                                                className="form__error form__warning"
                                                tabIndex={0}
                                            >
                                                {rebatesStatus.emailNotFound}
                                            </p>
                                        ) : null}
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                )
            )}
        </>
    );
};
