import React, { useEffect, useState } from 'react';
import { CategoryVideoQuietCard } from '../../category/categories/category-video-quiet-card/category-video-quiet-card';
import { SmartTilesFragment } from '../../../../models/experiencefragments/smarttiles';
import { VehicleSmashCard } from '../Vehicle-smash-card/Vehicle-smash-card';
import { PreferredDealer } from '../preferred-dealer/preferred-dealer';
import { FeatureTrio } from '../../vehicle-portal/components/feature-trio/feature-trio';
import ServerSideService from '../../../../services/server-side-service/server-side-service';
import { Category5050Carousel } from '../../category/categories/category-5050-carousel/category-5050-carousel';
import { VehicleHealth } from '../../../../models/service-maintenance-models/vehicle-health-alerts';
import { useExperienceFragmentContent } from '../../../utils/aem-content-utils/use-experience-fragment';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';

export const useVehicleHealthPageComponents = (): [
    VehicleHealth,
    React.JSX.Element[],
    SmartTilesFragment
] => {
    //Map AEM component type to React component that could be displayed on VHA page
    const appConfiguration = new AppConfigurationService().getAppConfiguration();
    const allowedComponents = new Map();
    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width:1023px)').matches
            : false
    );
    const handleWindowResize = () => {
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 1023px)').matches
                : false
        );
    };
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    allowedComponents.set(
        'global-owner/components/videoquietcard/v1/videoquietcard',
        CategoryVideoQuietCard
    );
    allowedComponents.set(
        'global-owner/components/article/v1/article',
        VehicleSmashCard
    );
    allowedComponents.set(
        'global-owner/components/preferreddealer/v1/preferreddealer',
        PreferredDealer
    );
    if (
        appConfiguration.threeLetterCountryCode !== 'gbr' &&
        appConfiguration.threeLetterCountryCode !== 'can' &&
        appConfiguration.threeLetterCountryCode !== 'mex'
    ) {
        allowedComponents.set(
            'global-owner/components/articles/v1/articles',
            isMobile ? Category5050Carousel : FeatureTrio
        );
    }

    const ITEMS = ':items';
    const ITEMS_ORDER = ':itemsOrder';
    const TYPE = ':type';

    const containerContent = useExperienceFragmentContent(
        'vehicles',
        'vehicle-health',
        'container'
    );
    const components: React.JSX.Element[] = [];
    const vehicleHealthComponent: VehicleHealth =
        containerContent?.[ITEMS]?.vehiclehealth;
    const smarttilesContent: SmartTilesFragment =
        containerContent?.[ITEMS]?.smarttiles;
    const articlesObj: any = {};
    const articlesContent: any = containerContent?.[ITEMS]?.articles;
    articlesObj.articles = { ...articlesContent };

    containerContent?.[ITEMS_ORDER]?.forEach(componentNode => {
        let componentProps = containerContent?.[ITEMS]?.[componentNode];
        let component;

        if (!isMobile && componentNode == 'articles') {
            componentProps = {};
            componentProps.articles =
                containerContent?.[ITEMS]?.[componentNode];
            component = allowedComponents.get(componentProps?.articles?.[TYPE]);
        } else {
            component = allowedComponents.get(componentProps?.[TYPE]);
        }

        if (component) {
            components.push(React.createElement(component, componentProps));
        }
    });
    return [
        vehicleHealthComponent,
        components.map(component => <>{component}</>),
        smarttilesContent,
    ];
};
