import { HttpsProxyAgent } from 'https-proxy-agent';
import HttpService from '../../../services/http-service/http-service';
import { ASTUTE, SUPPORT_HUB_REGEX } from '../../support-constants';

const httpsAgent = new HttpsProxyAgent(
    process.env.http_proxy || 'http://internet.ford.com:83'
);

export default class RteService {
    public async astuteCallByTopicId(topicId: number, sessionID?: string) {
        return await HttpService.post(
            `${ASTUTE.BASE_URL_RTE}/privilege/content`,
            {
                sessionID,
                privilegedArea: ASTUTE.PRIVILEGED_AREA_WEB_ID,
                intent: topicId.toString(),
            },
            {
                proxy: false,
            }
        )
            .then(response => {
                return response.data;
            })
            .catch(e => {
                console.error(
                    `astuteCallByTopicId - RTE Upstream Failure - ${e.message} for topic Id - ${topicId}`
                );
                throw new Error(
                    `astuteCallByTopicId - RTE Upstream Failure - ${e.message} for topic Id - ${topicId}`
                );
            });
    }

    public async getSuggestedResultsElastic(
        currentSearchTerm: string,
        sessionID: string
    ) {
        if (sessionID) {
            return await HttpService.post(
                `${ASTUTE.BASE_URL_RTE}/autocomplete`,
                {
                    sessionID,
                    utterance: currentSearchTerm,
                    suggestionCount: '10',
                },
                { httpsAgent, proxy: false }
            )
                .then(result => {
                    let suggestions = result.data.suggestions;
                    suggestions = suggestions.map((item: string) =>
                        item.replace(SUPPORT_HUB_REGEX.ALL_MARKUP_TAGS, '')
                    );
                    return suggestions;
                })
                .catch(e => {
                    console.error(
                        `RTE Get suggested results failure, couldn't get autocomplete: ${e.message}`
                    );
                    throw new Error(
                        `RTE Get suggested results failure, couldn't get autocomplete: ${e.message}`
                    );
                });
        }
    }
    public async getSessionIdForSearch(
        languageRegionCode: string,
        brand: string
    ): Promise<string> {
        languageRegionCode =
            languageRegionCode === 'fr-lu' ? 'fr-be' : languageRegionCode;
        return await HttpService.post(
            `${ASTUTE.BASE_URL_RTE}/init`,
            {
                market: languageRegionCode,
                clientId: brand,
            },
            { proxy: false }
        )
            .then(value => {
                return value.data.sessionID;
            })

            .catch(e => {
                console.error(
                    `getSessionIdForSearch - RTE Upstream Failure - ${e.message} for supportedMarket - ${languageRegionCode} and brand - ${brand}`
                );
            });
    }
    public async astuteSearch(currentSearchTerm: string, sessionID: string) {
        if (sessionID) {
            return await HttpService.post(
                `${ASTUTE.BASE_URL_RTE}/ask`,
                {
                    sessionID,
                    requestUtterance: currentSearchTerm,
                },
                { proxy: false }
            )
                .then(result => {
                    return result.data;
                })
                .catch(e => {
                    console.error(
                        `astuteSearch - RTE Upstream Failure - ${e.message} for supportedMarket - ${currentSearchTerm}`
                    );
                    throw new Error(
                        `astuteSearch - RTE Upstream Failure - ${e.message} for supportedMarket - ${currentSearchTerm}`
                    );
                });
        }
    }

    public async sendEmplifiFeedback(
        sessionID: string,
        topicId: number,
        rating: number,
        comments: string
    ) {
        return HttpService.post(
            `${ASTUTE.BASE_URL_RTE}/rating`,
            {
                sessionID,
                rating: rating,
                comment: comments,
                intent: topicId,
            },
            { httpsAgent, proxy: false }
        )
            .then(result => {
                return result.data;
            })
            .catch(async e => {
                console.error(
                    `sendEmplifiFeedback - RTE Upstream Failure - ${e.message} for supportedMarket - ${topicId}`
                );
                throw new Error(
                    `sendEmplifiFeedback - RTE Upstream Failure - ${e.message} for supportedMarket - ${topicId}`
                );
            });
    }
}
