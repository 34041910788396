import React, { useEffect, useMemo, useState } from 'react';
import './light-retrieve-booking-details-lincoln.scss';
import { OsbComments } from '../../comments/comments';
import {
    BOOKING_SUMMARY_CONTAINER_KEYS,
    DELIVERY_STEPS_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_SERVICE_TYPE,
    SERVICES_STEP_KEYS,
    SERVICE_FLOW,
    STEP_PROGRESS_BAR_KEYS,
    OSB_SERVICE_PRICE_TYPE,
    SERVICE_DELIVERY_TYPE,
    OSB_LIGHTT_VOUCHER_KEYS,
} from '../../../../sections/owners-osb/osb-constant';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import {
    getFormattedPrice,
    getLightVehicleData,
    getVoucherAppliedSelectedDeliveryOptions,
    getVoucherAppliedSelectedLocationOptions,
    getVoucherAppliedSelectedServices,
    showServiceLocationDetails,
    hasAutomaticVoucherApplied,
    buildNavigationUrl,
    getActualPrice,
    processDeliveryStepProgressbarDisplay,
    calculateTotalPriceSummary,
    getDefaultDeliveryOption,
    ErrorResponse,
    isConvenienceOptionIncluded,
    filterObjectsBySectionType,
    getObjectFromAEMJsonArray,
    isPOROrNoPriceServiceSelected,
} from '../../osb-utils';
import {
    useCalendarStep,
    useDealerStep,
    useHttp,
    useLightDeliveryOptionServiceStep,
    useOSBStep,
    usePersonalDetailStep,
    useServiceStep,
    useStepProgressBar,
    useVehicleStep,
    useServiceLocationStep,
    useOsbImageUpload,
} from '../../../../../hooks/owners-osb';
import ServiceHandler from '../../../../../services/service-handler';
import { VehicleData } from '../../../../../models/osb-model/osb-vehicle-step';
import CheckedIcon from '../../../../../assets/icons-notification-checkmark-secondary.svg';
import { LightJourneyVouchers } from '../../services-step/light-journey/light-vouchers/light-vouchers';
import {
    DealerServices,
    ServiceData,
    ServiceInfo,
} from '../../../../../models/osb-model/osb-dealerservice-info';
import {
    triggerRemoveVoucherCtaAnalytics,
    triggerAmendReviewBookingSummaryAnalytics,
    triggerAmendReviewServiceErrorAnalytics,
    triggerAmendReviewDeliveryServiceErrorAnalytics,
} from '../../analytics/osb-analytics';

import { useAnalytics } from '../../../../../hooks/use-analytics';
import {
    OsbPathCalenderStep,
    OsbPathDealerStep,
    OsbPathDeliveryStep,
    OsbPathPersonalDetailsStep,
    OsbPathServiceLocationStep,
    OsbPathServicesStep,
} from '../../osb-controller';
import { OSBVideoImageOverlay } from '../../common/osb-overlay/osb-video-image-overlay';
import { OSBBrightcoveVideoPlayer } from '../../common/osb-brightcove-video-player/osb-brightcove-video-player';
import { OSBVideoLink } from '../../common/osb-video-link/osb-video-link';
import {
    AcceptedFileInfo,
    OSBFileUploader,
} from '../../common/osb-file-uploader/osb-file-uploader';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import { FormatAddress } from '../../common/osb-common-components/osb-common-components';
import CampaignValidation from '../../campaign-validation/campaign-validation';
import { useCampaignStatesStep } from '../../../../../hooks/owners-osb/use-campaign-states-step';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';

interface Props {
    refreshBookingSummary?: () => void;
    loadVoucherAlertMessage: (
        alertMessage: string,
        alertSuccess: boolean
    ) => void;
}
export const LightRetrieveBookingDetails = (props: Props) => {
    const {
        manageOsbStepVisibility,
        updateProgressBarStatus,
    } = useStepProgressBar();
    const {
        osbServiceLocationDetail,
        setOSBServiceLocationStepPayload,
    } = useServiceLocationStep();
    const history = useHistory();
    const { osbPersonalDetail } = usePersonalDetailStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbCalendarStep } = useCalendarStep();
    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const {
        osbImageUploadStep,
        setOSBImageServiceStepPayload,
    } = useOsbImageUpload();
    const {
        osbLightDeliveryServiceStep,
        setOSBLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const { osbStep } = useOSBStep();
    const { osbDealerStep, setOSBDealerStepPayload } = useDealerStep();

    const [fireEvents] = useAnalytics();
    const [errorMsgService, setErrorMsgService] = useState('');
    const [reviewBookingContent, setReviewBookingContent] = useState<any>();

    const [openModel, setOpenModel] = useState(false);
    const [isRequiredComments, setIsRequiredComments] = useState(false);
    const { httpState, dispatch } = useHttp();
    const [dateAndMonthFormat, setdateandMonthFormat] = useState('');
    const [isVideoOverlayOpened, setIsVideoOverlayOpened] = useState(false);
    const [brightCoveAccountId, setBrightCoveAccountId] = useState('');
    const [brightCoveVideoId, setBrightCoveVideoId] = useState('');
    const [brightCovePlayerId, setBrightCovePlayerId] = useState('');
    const [brightCoveVideoName, setBrightCoveVideoName] = useState('');
    const selectedOtherOptionsServices = osbServiceStep.isMobileServiceSelected
        ? osbServiceLocationDetail.selectedOtherOptions
        : osbLightDeliveryServiceStep.selectedServices;
    const { setOSBCampaignStates } = useCampaignStatesStep();
    const setStatus = (status: boolean) => {
        setOpenModel(status);
    };

    const openModelData = () => {
        setOpenModel(true);
    };

    const bookable = useAllOSBState();

    const dealerProfile = osbDealerStep.selectedDealerProfile;

    let dateFormat = '';

    const {
        osbCampaignStates,
        getCampaignVoucherInfo,
    } = useCampaignStatesStep();
    const voucherDetails = getCampaignVoucherInfo();
    const potentialSavingAmount = useMemo(() => {
        return osbCampaignStates.campaignStates.reduce((total, item) => {
            if (item.state === 'VALID') {
                total += item.potentialSavings;
            }
            return total;
        }, 0);
    }, [osbCampaignStates]);

    function getReviewBookingContent() {
        ServiceHandler.OsbContentService.getOsbContentObject(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-review-booking'
        )
            .then(results => {
                dateFormat = results[
                    BOOKING_SUMMARY_CONTAINER_KEYS.DATE_FORMAT_SUMMARY
                ]
                    .toString()
                    .trim();
                setdateandMonthFormat(dateFormat);
                setReviewBookingContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    }

    const isRequiredCommentForBookig = () => {
        if (
            osbServiceStep.selectedServices.filter(
                item =>
                    item.serviceOption ==
                    SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE
            ).length === 1
        ) {
            setIsRequiredComments(true);
        }
    };

    const addDefaultService = async () => {
        if (osbServiceStep.dealerServiceInfo.dealerServices) {
            const generalAppointmentService = osbServiceStep.dealerServiceInfo.dealerServices.mainServices?.filter(
                item => item.serviceId.toString() === SERVICE_FLOW.GA_SERVICE_ID
            );
            if (generalAppointmentService) {
                const selectedServiceList: ServiceData[] = [];
                generalAppointmentService.forEach((item: ServiceInfo) => {
                    const serviceData: ServiceData = {
                        id: item.id || '', // TODO: fallback is not needed but added as a hack for TS error. Need fix.
                        serviceType: OSB_SERVICE_TYPE.SERVICES,
                        serviceName: item.name,
                        serviceCode: item.serviceId.toString(),
                        priceAfterDiscount: item.priceAfterDiscount.toString(),
                        price: item.price.toString(),
                        type: item.type,
                        subType: item.subType
                            ? item.subType
                            : item.additionalInfo.subType,
                        serviceOption:
                            SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE,
                        brightCoveAccountId:
                            item?.additionalInfo?.brightCoveAccountId || '',
                        brightCoveVideoId:
                            item?.additionalInfo?.brightCoveVideoId || '',
                        brightCovePlayerId:
                            item?.additionalInfo?.brightCovePlayerId || '',
                        brightCoveVideoName:
                            item?.additionalInfo?.brightCoveVideoName || '',
                    };
                    selectedServiceList.push(serviceData);
                });

                setOSBServiceStepPayload({
                    selectedServices: selectedServiceList,
                });
            }
        }
    };

    const addDefaultDeliveryService = async () => {
        const selectedServiceList: ServiceData[] = [];
        const defaultService: ServiceData | null = getDefaultDeliveryOption(
            osbServiceStep?.dealerServiceInfo?.dealerServices
                ?.additionalServices
        );

        if (defaultService) {
            if (defaultService.subType === DELIVERY_STEPS_KEYS.FROM_DB) {
                selectedServiceList.push(defaultService);
            }
        }
        if (selectedServiceList.length === 0) {
            const serviceData: ServiceData = {
                id: '0000', // TODO: verify whether this is the right id for hardcoded values.
                serviceType: DELIVERY_STEPS_KEYS.DROP_OFF_SERVICE_TYPE,
                serviceName: DELIVERY_STEPS_KEYS.DROP_OFF_SERVICE_TYPE || '',
                serviceCode: '0000',
                priceAfterDiscount: '0',
                price: '0',
                type: '',
                subType: '',
                serviceOption:
                    DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD,
            };
            selectedServiceList.push(serviceData);
        }
        setOSBLightDeliveryServiceStep({
            selectedServices: selectedServiceList,
        });
    };

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        if (osbStep.isRetrieveFlow && !osbServiceStep.isMobileServiceSelected) {
            const isDealerHasDeliveryServices = processDeliveryStepProgressbarDisplay(
                osbServiceStep.dealerServiceInfo,
                manageOsbStepVisibility
            );
            setOSBDealerStepPayload({
                isDealerHasDeliveryServices: isDealerHasDeliveryServices,
            });
        }
        async function fetchDefaultService() {
            if (
                osbServiceStep.selectedServices.length === 0 &&
                !osbServiceStep.isMobileServiceSelected
            ) {
                await addDefaultService();
            }
            if (
                osbLightDeliveryServiceStep.selectedServices.length === 0 &&
                !osbServiceStep.isMobileServiceSelected
            ) {
                await addDefaultDeliveryService();
            }
        }

        fetchDefaultService();
        const fetchService = async () => {
            const vehicleData = getLightVehicleData(osbVehicleStep);
            const selectedDealerCode =
                osbDealerStep.selectedDealerProfile.dealerCode || '';
            if (vehicleData) {
                await loadServiceData(
                    osbServiceStep.voucherCode,
                    vehicleData,
                    selectedDealerCode
                );
            }
        };
        fetchService();
        getReviewBookingContent();
    }, []);

    useEffect(() => {
        isRequiredCommentForBookig();
    }, [
        osbServiceStep.selectedServices,
        osbLightDeliveryServiceStep.selectedServices,
    ]);

    const goToDealerStep = () => {
        if (!osbStep.isRetrieveFlow) {
            triggerAmendReviewBookingSummaryAnalytics(
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_DEALER
            );
        }
        history.push(
            buildNavigationUrl(OsbPathDealerStep(), osbStep.UrlQueryParams)
        );
    };

    const goToCalendarStep = () => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DATETIME, false, true);
        if (osbStep.isRetrieveFlow) {
            triggerAmendReviewBookingSummaryAnalytics(
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_DATE_TIME
            );
            if (!osbServiceStep.isMobileServiceSelected) {
                processDeliveryStepProgressbarDisplay(
                    osbServiceStep.dealerServiceInfo,
                    manageOsbStepVisibility
                );
            }
        }
        history.push(
            buildNavigationUrl(OsbPathCalenderStep(), osbStep.UrlQueryParams)
        );
    };

    const loadServiceData = async (
        voucherCode: string,
        vehicleData: VehicleData,
        selectedDealerCode: string
    ) => {
        // TODO: Hack to avoid the race condition of updating global state and making a call to api service.
        if (!voucherCode) {
            bookable.campaignSelections = [];
        }
        await ServiceHandler.OsbDealerServices.getServicesList({
            dealerCode: selectedDealerCode,
            vehicleData: vehicleData,
            vouchers: voucherCode ? [voucherCode] : [],
            serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                : SERVICE_DELIVERY_TYPE.WORKSHOP,
            requestSourceType: osbStep.source,
            bookable: bookable,
            DSLAPIVersion: osbStep.DSLAPIVersion,
        })
            .then(results => {
                setErrorMsgService('');
                setOSBServiceStepPayload({
                    dealerServiceInfo: results.value,
                    voucherDesc:
                        results.value.dealerServices.serviceVouchers.length > 0
                            ? results.value.dealerServices.serviceVouchers[0]
                                  .description
                            : '',
                });
                if (
                    Array.isArray(results.value.dealerServices?.campaignStates)
                ) {
                    setOSBCampaignStates({
                        campaignStates: [
                            ...results.value.dealerServices.campaignStates,
                        ],
                    });
                }
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: ErrorResponse) => {
                if (error?.errorMessage) {
                    setErrorMsgService(error.errorMessage);
                } else {
                    const errorMessage = osbStep.internalErrorMessage;
                    setErrorMsgService(errorMessage);
                }
            });
    };

    const refreshServicesData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedServices = getVoucherAppliedSelectedServices(
            dealerServices,
            osbServiceStep.selectedServices,
            isVoucherApplied
        );
        setOSBServiceStepPayload({
            selectedServices: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedServices
            ),
        });
    };
    const refreshDeliveryData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedDeliveryOptions = getVoucherAppliedSelectedDeliveryOptions(
            dealerServices,
            osbLightDeliveryServiceStep.selectedServices,
            isVoucherApplied
        );

        setOSBLightDeliveryServiceStep({
            selectedServices: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedDeliveryOptions
            ),
        });
    };

    const refreshLocationData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedLocationOptions = getVoucherAppliedSelectedLocationOptions(
            dealerServices,
            osbServiceLocationDetail.selectedOtherOptions,
            isVoucherApplied
        );

        setOSBServiceLocationStepPayload({
            selectedOtherOptions: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedLocationOptions
            ),
        });
    };

    const setAppliedVoucher = async (voucherCode: string) => {
        dispatch({ type: 'REQUEST' });
        const vehicleData = getLightVehicleData(osbVehicleStep);
        const selectedDealerCode =
            osbDealerStep.selectedDealerProfile.dealerCode || '';
        if (vehicleData) {
            await loadServiceData(voucherCode, vehicleData, selectedDealerCode);
        }
    };

    const removeVoucherDetails = () => {
        triggerRemoveVoucherCtaAnalytics(
            osbDealerStep,
            osbVehicleStep,
            osbStep,
            fireEvents,
            osbServiceStep.voucherCode,
            LIGHT_JOURNEY_ANALYTICS.REMOVE_VOUCHER_CODE
        );
        setOSBServiceStepPayload({ voucherCode: '', voucherDesc: '' });
        setAppliedVoucher('');
        refreshServicesData(osbServiceStep.dealerServiceInfo.dealerServices);
        refreshDeliveryData(osbServiceStep.dealerServiceInfo.dealerServices);
        refreshLocationData(osbServiceStep.dealerServiceInfo.dealerServices);
    };

    const goToServicesStep = async (reviewAmendField: string) => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.SERVICE, false, true);
        if (osbStep.isRetrieveFlow) {
            triggerAmendReviewBookingSummaryAnalytics(
                osbVehicleStep,
                osbStep,
                fireEvents,
                reviewAmendField
            );
        }
        dispatch({ type: 'REQUEST' });
        const vehicleData = getLightVehicleData(osbVehicleStep);
        const selectedDealerCode =
            osbDealerStep.selectedDealerProfile.dealerCode || '';
        if (vehicleData) {
            await loadServiceData(
                osbServiceStep.voucherCode,
                vehicleData,
                selectedDealerCode
            );
        }
        if (osbStep.isRetrieveFlow && errorMsgService) {
            triggerAmendReviewServiceErrorAnalytics(
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
            );
        }
        if (osbStep.isRetrieveFlow && !osbServiceStep.isMobileServiceSelected) {
            processDeliveryStepProgressbarDisplay(
                osbServiceStep.dealerServiceInfo,
                manageOsbStepVisibility
            );
        }
        history.push(
            buildNavigationUrl(OsbPathServicesStep(), osbStep.UrlQueryParams)
        );
    };

    const goToServiceLocation = () => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.LOCATION, false, true);
        history.push(
            buildNavigationUrl(
                OsbPathServiceLocationStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    const goToDeliveryStep = async () => {
        if (!osbServiceStep.isMobileServiceSelected) {
            updateProgressBarStatus(
                STEP_PROGRESS_BAR_KEYS.DELIVERY,
                false,
                true
            );
            if (osbStep.isRetrieveFlow) {
                triggerAmendReviewBookingSummaryAnalytics(
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.AMEND_DELIVERY
                );
            }
            dispatch({ type: 'REQUEST' });
            const vehicleData = getLightVehicleData(osbVehicleStep);
            const selectedDealerCode =
                osbDealerStep.selectedDealerProfile.dealerCode || '';
            if (vehicleData) {
                await loadServiceData(
                    osbServiceStep.voucherCode,
                    vehicleData,
                    selectedDealerCode
                );
            }
            if (osbStep.isRetrieveFlow && errorMsgService) {
                triggerAmendReviewDeliveryServiceErrorAnalytics(
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
                );
            }
            history.push(
                buildNavigationUrl(
                    OsbPathDeliveryStep(),
                    osbStep.UrlQueryParams
                )
            );
        } else {
            goToServiceLocation();
        }
    };

    const gotToPersonalDetailsStep = () => {
        if (osbStep.isAuthenticatedFlow) {
            manageOsbStepVisibility(STEP_PROGRESS_BAR_KEYS.CONTACT, true);
        }
        if (!osbStep.isRetrieveFlow) {
            triggerAmendReviewBookingSummaryAnalytics(
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_CONTACT_DETAILS
            );
        }
        if (osbStep.isRetrieveFlow && !osbServiceStep.isMobileServiceSelected) {
            processDeliveryStepProgressbarDisplay(
                osbServiceStep.dealerServiceInfo,
                manageOsbStepVisibility
            );
        }
        history.push(
            buildNavigationUrl(
                OsbPathPersonalDetailsStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    const updatedComment = (payload: { [key: string]: string }) => {
        props.refreshBookingSummary?.();
        setOSBServiceStepPayload(payload);
    };

    const updatedOsbImage = (payload: {
        [key: string]: AcceptedFileInfo[];
    }) => {
        props.refreshBookingSummary?.();
        setOSBImageServiceStepPayload(payload);
    };

    const selectedServicesList = osbServiceStep.selectedServices;

    const voucherDescription = osbServiceStep.voucherDesc || '';

    const regNo = osbPersonalDetail?.registrationNumber;

    const showVideoOverlay = (
        accountId = '',
        videoId = '',
        playerId = '',
        videoName = ''
    ) => {
        if (accountId && videoId && playerId) {
            setIsVideoOverlayOpened(true);
            setBrightCoveAccountId(accountId);
            setBrightCoveVideoId(videoId);
            setBrightCovePlayerId(playerId);
            setBrightCoveVideoName(videoName);
        }
    };

    const hideVideoOverlay = () => {
        setIsVideoOverlayOpened(false);
        setBrightCoveAccountId('');
        setBrightCoveVideoId('');
        setBrightCovePlayerId('');
        setBrightCoveVideoName('');
    };

    // TODO : We aware that the below method is already used in light-booking-summary component. We can move this method in to common component.
    function specificServiceListFormat(
        serviceData: ServiceData[],
        formatType: any,
        dropOff?: boolean
    ) {
        return (
            <>
                {filterObjectsBySectionType(
                    serviceData,
                    formatType,
                    dropOff
                ).map((selectedService: any) => (
                    <div key={selectedService.id}>
                        <div className="light-service-group">
                            <div className="light-service-name">
                                {selectedService.serviceName}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.OTHER_CUSTOMSERVICE && (
                                        <div className="light-recall-service-desc">
                                            - {osbServiceStep.otherServiceDesc}
                                        </div>
                                    )}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.SERVICE_SUBTYPE_RECALL && (
                                        <div className="light-recall-service-desc">
                                            {osbServiceStep.recallServiceDescription.map(
                                                (desc, index) => (
                                                    <div key={index}>
                                                        {desc}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.SERVICE_SUBTYPE_VHA && (
                                        <div className="light-recall-service-desc">
                                            {osbServiceStep.vhaServiceDescription?.map(
                                                (desc, index) => (
                                                    <div key={index}>
                                                        {desc}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                {selectedService.subType &&
                                    selectedService.subType ===
                                        SERVICE_FLOW.SERVICE_SUBTYPE_OIL && (
                                        <div className="light-recall-service-desc">
                                            <div>
                                                {
                                                    osbServiceStep.oilLifeServiceReminderDescription
                                                }
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <OSBVideoLink
                                selectedService={selectedService}
                                showVideoOverlay={showVideoOverlay}
                            />
                            <div className="light-service-price">
                                {(voucherDescription.length > 0 &&
                                    osbServiceStep.voucherCode.length > 0) ||
                                hasAutomaticVoucherApplied(
                                    osbServiceStep.totalDiscountPrice,
                                    osbServiceStep.totalPrice
                                ) ? (
                                    Number(selectedService.priceAfterDiscount) >
                                    OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE ? (
                                        <>
                                            <div>
                                                {getFormattedPrice(
                                                    selectedService.price,
                                                    osbStep,
                                                    selectedService.serviceCode
                                                )}
                                            </div>
                                        </>
                                    ) : Number(
                                          selectedService.priceAfterDiscount
                                      ) ===
                                          OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE &&
                                      selectedService.serviceOption !=
                                          SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE ? (
                                        <label>{osbStep.freeLabel}</label>
                                    ) : (
                                        getFormattedPrice(
                                            selectedService.price,
                                            osbStep,
                                            selectedService.serviceCode
                                        )
                                    )
                                ) : Number(selectedService.price) >
                                  OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE ? (
                                    getFormattedPrice(
                                        selectedService.price,
                                        osbStep,
                                        selectedService.serviceCode
                                    )
                                ) : Number(selectedService.price) ===
                                      OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE &&
                                  selectedService.serviceOption !=
                                      SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE ? (
                                    <label> {osbStep.freeLabel}</label>
                                ) : (
                                    getFormattedPrice(
                                        selectedService.price,
                                        osbStep,
                                        selectedService.serviceCode
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    // TODO : We aware that the below method is already used in light-booking-summary component. We can move this method in to common component.
    function deliveryServiceListFormat(
        serviceData: ServiceData[],
        formatType: any,
        dropOff?: boolean
    ) {
        return (
            <>
                {filterObjectsBySectionType(
                    serviceData,
                    formatType,
                    dropOff
                ).map((selectedService: any, index: any) => (
                    <div key={index}>
                        <div className="light-service-group">
                            <div className="light-service-name">
                                {selectedService.serviceName}
                            </div>
                            <div className="light-service-price">
                                {(voucherDescription.length > 0 &&
                                    osbServiceStep.voucherCode.length > 0) ||
                                hasAutomaticVoucherApplied(
                                    osbServiceStep.totalDiscountPrice,
                                    osbServiceStep.totalPrice
                                ) ? (
                                    selectedService.priceAfterDiscount >
                                    OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE ? (
                                        <>
                                            <div>
                                                {getFormattedPrice(
                                                    selectedService.price,
                                                    osbStep
                                                )}
                                            </div>
                                        </>
                                    ) : selectedService.priceAfterDiscount ===
                                          OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE &&
                                      selectedService.serviceOption !==
                                          DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD ? (
                                        <label> {osbStep.freeLabel}</label>
                                    ) : selectedService.serviceOption !==
                                      DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD ? (
                                        getFormattedPrice(
                                            selectedService.price,
                                            osbStep
                                        )
                                    ) : (
                                        <label> </label>
                                    )
                                ) : Number(selectedService.price) >
                                  OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE ? (
                                    getFormattedPrice(
                                        selectedService.price,
                                        osbStep
                                    )
                                ) : Number(selectedService.price) ===
                                      OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE &&
                                  selectedService.serviceOption !==
                                      DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD ? (
                                    <label> {osbStep.freeLabel}</label>
                                ) : (
                                    selectedService.serviceOption !==
                                        DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD &&
                                    getFormattedPrice(
                                        selectedService.price,
                                        osbStep
                                    )
                                )}
                            </div>
                        </div>
                        <OSBVideoLink
                            selectedService={selectedService}
                            showVideoOverlay={showVideoOverlay}
                        />
                    </div>
                ))}
            </>
        );
    }

    return (
        <div className="osb-light-review-booking-container">
            {errorMsgService.length > 0 ? (
                <div className="services-flow-error">
                    {!httpState.isLoading &&
                        getObjectFromAEMJsonArray(
                            'Booking_Error',
                            reviewBookingContent
                        )}
                </div>
            ) : (
                ''
            )}

            {httpState.isLoading && <OsbLoader />}
            {osbDealerStep.selectedDealerProfile && (
                <div className="osb-light-review-dealer-section">
                    <div className="light-review-hr-line-style"></div>
                    <div className="edit-section">
                        <div className="light-review-section-title">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'Dealer_Label',
                                    reviewBookingContent
                                )}
                        </div>
                        {!osbStep.isRetrieveFlow &&
                            !osbStep.isDealerDeepLinked && (
                                <span
                                    className="light-review-change-link"
                                    onClick={goToDealerStep}
                                >
                                    {!httpState.isLoading &&
                                        getObjectFromAEMJsonArray(
                                            'Amend_Label',
                                            reviewBookingContent
                                        )}
                                </span>
                            )}
                    </div>

                    <div className="light-review-section-body">
                        {dealerProfile.dealerName}
                    </div>
                    <div className="light-review-section-body">
                        <FormatAddress
                            addressData={dealerProfile}
                            addressOrder={osbStep.dealerAddressOrder}
                            isDisplaySingleRow={false}
                        />
                    </div>
                </div>
            )}

            {osbVehicleStep.vehicleDetails.modelName &&
                osbVehicleStep.vehicleDetails.modelName.toUpperCase() !==
                    osbVehicleStep.defaultModelName.toUpperCase() && (
                    <div className="light-review-vehicle-section">
                        <div className="light-review-hr-line-style"></div>
                        <div className="edit-section">
                            <div className="light-review-section-title">
                                {!httpState.isLoading &&
                                    getObjectFromAEMJsonArray(
                                        'Vehicle_Label',
                                        reviewBookingContent
                                    )}
                            </div>
                            {!osbStep.isRetrieveFlow && (
                                <span
                                    className="light-review-change-link"
                                    onClick={() =>
                                        goToServicesStep(
                                            LIGHT_JOURNEY_ANALYTICS.AMEND_VEHICLE
                                        )
                                    }
                                >
                                    {!httpState.isLoading &&
                                        getObjectFromAEMJsonArray(
                                            'Amend_Label',
                                            reviewBookingContent
                                        )}
                                </span>
                            )}
                        </div>

                        <div className="light-review-section-body">
                            {osbVehicleStep.vehicleDetails.modelName} &nbsp;
                            {osbVehicleStep.vehicleDetails?.buildDate.substring(
                                0,
                                4
                            )}
                            &nbsp;
                        </div>
                    </div>
                )}

            {selectedServicesList && (
                <div className="light-review-service-section">
                    <div className="light-review-hr-line-style"></div>
                    <div className="edit-section">
                        <div className="light-review-section-title">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'Services_Label',
                                    reviewBookingContent
                                )}
                        </div>
                        <div
                            className="light-review-change-link"
                            data-testid="servicesAmend"
                            onClick={() =>
                                goToServicesStep(
                                    LIGHT_JOURNEY_ANALYTICS.AMEND_SERVICES
                                )
                            }
                        >
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'Amend_Label',
                                    reviewBookingContent
                                )}
                        </div>
                    </div>

                    <div>
                        <div className="light-review-section-services-body">
                            {Array.isArray(selectedServicesList) &&
                                selectedServicesList.length > 0 &&
                                selectedServicesList[0].serviceOption ===
                                    SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE && (
                                    <div className="light-service-name">
                                        {!httpState.isLoading &&
                                            getObjectFromAEMJsonArray(
                                                BOOKING_SUMMARY_CONTAINER_KEYS.GENERAL_APPOINTMENT_SERVICE_LABEL,
                                                reviewBookingContent
                                            )}
                                    </div>
                                )}
                            {osbServiceStep.selectedServices.filter(
                                item =>
                                    item.serviceOption !=
                                    SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE
                            ).length > 0 &&
                                (!httpState.isLoading
                                    ? osbServiceStep.isMobileServiceSelected
                                        ? getObjectFromAEMJsonArray(
                                              BOOKING_SUMMARY_CONTAINER_KEYS.MOBILE_SERVICE_TITLE,
                                              reviewBookingContent
                                          )
                                        : getObjectFromAEMJsonArray(
                                              BOOKING_SUMMARY_CONTAINER_KEYS.SPECIFIC_SERVICES_LABEL,
                                              reviewBookingContent
                                          )
                                    : '')}

                            {specificServiceListFormat(
                                selectedServicesList,
                                SERVICE_FLOW.SERVICE_PRICE_OR_FREE
                            )}

                            {selectedServicesList.length === 0 && (
                                <>
                                    {osbServiceStep.isMobileServiceSelected ? (
                                        <div>
                                            {getObjectFromAEMJsonArray(
                                                BOOKING_SUMMARY_CONTAINER_KEYS.MOBILE_SERVICE_TITLE,
                                                reviewBookingContent
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {getObjectFromAEMJsonArray(
                                                BOOKING_SUMMARY_CONTAINER_KEYS.GENERAL_APPOINTMENT_SERVICE_LABEL,
                                                reviewBookingContent
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {osbImageUploadStep.uploadedImage &&
                        osbImageUploadStep.uploadedImage?.length > 0 &&
                        getObjectFromAEMJsonArray(
                            SERVICES_STEP_KEYS.IMAGE_UPLOADED_LABEL,
                            reviewBookingContent
                        ) && (
                            <div className="image-upload-count">
                                {`${!httpState.isLoading &&
                                    getObjectFromAEMJsonArray(
                                        SERVICES_STEP_KEYS.IMAGE_UPLOADED_LABEL,
                                        reviewBookingContent
                                    )} (${
                                    osbImageUploadStep.uploadedImage?.length
                                })`}
                            </div>
                        )}
                </div>
            )}
            {showServiceLocationDetails(
                osbServiceStep.isMobileServiceSelected,
                osbServiceLocationDetail
            ) && (
                <div className="service-location-container">
                    <div className="light-review-hr-line-style"></div>
                    <div className="edit-section">
                        <div className="light-review-section-title">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    BOOKING_SUMMARY_CONTAINER_KEYS.SERVICE_LOCATION_TITLE,
                                    reviewBookingContent
                                )}
                        </div>
                        <div
                            className="light-review-change-link"
                            data-testid="serviceLocationAmend"
                            onClick={goToServiceLocation}
                        >
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'Amend_Label',
                                    reviewBookingContent
                                )}
                        </div>
                    </div>

                    <div className="service-location-details">
                        <div className="customer-address">
                            <FormatAddress
                                addressData={osbServiceLocationDetail}
                                addressOrder={osbStep.dealerAddressOrder}
                                isDisplaySingleRow={false}
                            />
                        </div>
                        <div className="parking-type">
                            {osbServiceLocationDetail.parkingType}
                        </div>
                        {osbServiceLocationDetail.comment.trim().length > 0 && (
                            <div className="sl-comments">
                                {`"${osbServiceLocationDetail.comment}"`}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {filterObjectsBySectionType(
                selectedOtherOptionsServices,
                SERVICE_FLOW.SERVICE_PRICE_OR_FREE
            ).length > 0 && (
                <div className="light-review-delivery-section">
                    <div className="light-review-hr-line-style"></div>
                    <div className="edit-section">
                        <div className="light-review-section-title">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'deliveryServicesLabel',
                                    reviewBookingContent
                                )}
                        </div>
                        {osbDealerStep.isDealerHasDeliveryServices && (
                            <div
                                className="light-review-change-link"
                                data-testid="servicesAmend"
                                onClick={goToDeliveryStep}
                            >
                                {!httpState.isLoading &&
                                    getObjectFromAEMJsonArray(
                                        'Amend_Label',
                                        reviewBookingContent
                                    )}
                            </div>
                        )}
                    </div>

                    <div>
                        <div className="light-review-section-services-body">
                            {deliveryServiceListFormat(
                                selectedOtherOptionsServices,
                                SERVICE_FLOW.SERVICE_PRICE_OR_FREE,
                                true
                            )}
                            {selectedOtherOptionsServices.length == 0 &&
                            !osbServiceStep.isMobileServiceSelected ? (
                                !httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'dropOffLabel',
                                    reviewBookingContent
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {selectedOtherOptionsServices.length > 0 && (
                <div className="light-review-delivery-and-location-section">
                    {selectedOtherOptionsServices.map(
                        (selectedService: any, index: any) => (
                            <>
                                <div key={index}>
                                    {selectedService.convenienceOptionTypes &&
                                        isConvenienceOptionIncluded(
                                            selectedService.convenienceOptionTypes,
                                            SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE
                                        ) &&
                                        (osbCalendarStep.selectedPickupDate ||
                                            osbServiceLocationDetail.streetName !==
                                                '') && (
                                            <>
                                                <div className="light-review-hr-line-style"></div>
                                                <div className="edit-section">
                                                    <div className="light-review-section-title">
                                                        {!httpState.isLoading &&
                                                            getObjectFromAEMJsonArray(
                                                                'pickUpAndDeliveryLocationLabel',
                                                                reviewBookingContent
                                                            )}
                                                    </div>
                                                    {osbDealerStep.isDealerHasDeliveryServices && (
                                                        <div
                                                            className="light-review-change-link"
                                                            data-testid="servicesAmend"
                                                            onClick={
                                                                goToDeliveryStep
                                                            }
                                                        >
                                                            {!httpState.isLoading &&
                                                                getObjectFromAEMJsonArray(
                                                                    'Amend_Label',
                                                                    reviewBookingContent
                                                                )}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                </div>
                                <div>
                                    <div className="light-review-section-services-body">
                                        {selectedOtherOptionsServices.map(
                                            (
                                                selectedService: any,
                                                index: any
                                            ) => (
                                                <div key={index}>
                                                    <div className="light-service-group">
                                                        <div className="light-service-name">
                                                            {selectedService.convenienceOptionTypes &&
                                                                isConvenienceOptionIncluded(
                                                                    selectedService.convenienceOptionTypes,
                                                                    SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE
                                                                ) &&
                                                                (osbCalendarStep.selectedPickupDate ||
                                                                    osbServiceLocationDetail.streetName !==
                                                                        '') && (
                                                                    <div className="pick-up-details">
                                                                        <div className="selected-pickup-date">
                                                                            {osbCalendarStep
                                                                                .selectedPickupDate
                                                                                .length >
                                                                                0 &&
                                                                                OsbUtilService.constructDisplayDate(
                                                                                    osbCalendarStep.selectedPickupDate,
                                                                                    dateAndMonthFormat,
                                                                                    OsbUtilService.getAppConfiguration()
                                                                                        .languageRegionCode
                                                                                )}
                                                                        </div>
                                                                        <div className="selected-pickup-time">
                                                                            {osbCalendarStep
                                                                                .selectedPickupTime
                                                                                .length >
                                                                                0 &&
                                                                                OsbUtilService.getTimeBasedOnAuthoring(
                                                                                    osbCalendarStep.selectedPickupTime,
                                                                                    osbStep.twelveHrsTimeFormat,
                                                                                    osbStep.amTimeLabel,
                                                                                    osbStep.pmTimeLabel,
                                                                                    osbStep.timeSuffixLabel
                                                                                )}
                                                                        </div>
                                                                        <div className="customer-address" />
                                                                        <FormatAddress
                                                                            addressData={
                                                                                osbServiceLocationDetail
                                                                            }
                                                                            addressOrder={
                                                                                osbStep.dealerAddressOrder
                                                                            }
                                                                            isDisplaySingleRow={
                                                                                false
                                                                            }
                                                                        />
                                                                        {osbServiceLocationDetail.comment.trim()
                                                                            .length >
                                                                            0 && (
                                                                            <div className="sl-comments">
                                                                                {`"${osbServiceLocationDetail.comment}"`}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            )}

            {filterObjectsBySectionType(
                [...selectedServicesList, ...selectedOtherOptionsServices],
                SERVICE_FLOW.SERVICE_POR_OR_EMPTY
            ).length > 0 && (
                <div className="light-review-without-price-section">
                    <div className="light-review-hr-line-style"></div>
                    <div className="edit-section">
                        <div className="light-review-section-title">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'servicesWithoutPriceLabel',
                                    reviewBookingContent
                                )}
                        </div>
                        {osbDealerStep.isDealerHasDeliveryServices && (
                            <div
                                className="light-review-change-link"
                                data-testid="servicesAmend"
                                onClick={() =>
                                    goToServicesStep(
                                        LIGHT_JOURNEY_ANALYTICS.AMEND_SERVICES
                                    )
                                }
                            >
                                {!httpState.isLoading &&
                                    getObjectFromAEMJsonArray(
                                        'Amend_Label',
                                        reviewBookingContent
                                    )}
                            </div>
                        )}
                    </div>

                    <div>
                        <div className="light-review-section-services-body">
                            {deliveryServiceListFormat(
                                selectedOtherOptionsServices,
                                SERVICE_FLOW.SERVICE_POR_OR_EMPTY
                            )}
                            {specificServiceListFormat(
                                selectedServicesList,
                                SERVICE_FLOW.SERVICE_POR_OR_EMPTY
                            )}
                            {selectedOtherOptionsServices.length == 0 &&
                            !osbServiceStep.isMobileServiceSelected ? (
                                !httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'dropOffLabel',
                                    reviewBookingContent
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isVideoOverlayOpened && (
                <OSBVideoImageOverlay
                    isOpen={isVideoOverlayOpened}
                    backButtonText={osbStep.videoPlayerBackButtonLabel}
                    hideVideoOverlay={hideVideoOverlay}
                    isVideo={true}
                >
                    <OSBBrightcoveVideoPlayer
                        accountId={brightCoveAccountId}
                        videoId={brightCoveVideoId}
                        playerId={brightCovePlayerId}
                        videoName={brightCoveVideoName}
                    />
                </OSBVideoImageOverlay>
            )}
            {osbCalendarStep.selectedDate && (
                <div className="light-review-dateTime-section">
                    <div className="light-review-hr-line-style"></div>
                    <div className="edit-section">
                        <div className="light-review-section-title">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'DateTime_Label',
                                    reviewBookingContent
                                )}
                        </div>
                        <span
                            className="light-review-change-link"
                            data-testid="calendarAmend"
                            onClick={goToCalendarStep}
                        >
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'Amend_Label',
                                    reviewBookingContent
                                )}
                        </span>
                    </div>

                    <div className="light-review-section-body">
                        {OsbUtilService.constructDisplayDate(
                            osbCalendarStep.selectedDate,
                            dateAndMonthFormat,
                            OsbUtilService.getAppConfiguration()
                                .languageRegionCode
                        )}
                    </div>
                    {!osbServiceStep.isMobileServiceSelected &&
                        osbCalendarStep.selectedTime && (
                            <div className="light-review-section-body">
                                {!httpState.isLoading &&
                                    getObjectFromAEMJsonArray(
                                        'Time_At_Label',
                                        reviewBookingContent
                                    )}{' '}
                                {OsbUtilService.getTimeBasedOnAuthoring(
                                    osbCalendarStep.selectedTime,
                                    osbStep.twelveHrsTimeFormat,
                                    osbStep.amTimeLabel,
                                    osbStep.pmTimeLabel,
                                    osbStep.timeSuffixLabel
                                )}
                            </div>
                        )}
                    {osbServiceStep.isMobileServiceSelected &&
                        osbCalendarStep?.selectedTimeRange && (
                            <div className="light-review-section-body">
                                {getObjectFromAEMJsonArray(
                                    'Time_Between_Label',
                                    reviewBookingContent
                                )}{' '}
                                {osbCalendarStep.selectedTimeRange}
                            </div>
                        )}
                </div>
            )}

            {osbPersonalDetail && (
                <div className="light-review-contact-section">
                    <div className="light-review-hr-line-style"></div>
                    <div className="edit-section">
                        <div className="light-review-section-title">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'Personal_Details_Label',
                                    reviewBookingContent
                                )}
                        </div>
                        <span
                            className="light-review-change-link"
                            data-testid="personalDetailsAmend"
                            onClick={gotToPersonalDetailsStep}
                        >
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'Amend_Label',
                                    reviewBookingContent
                                )}
                        </span>
                    </div>

                    <div className="light-review-section-body">
                        {osbPersonalDetail?.title && (
                            <span>{osbPersonalDetail?.title} </span>
                        )}
                        {getObjectFromAEMJsonArray(
                            BOOKING_SUMMARY_CONTAINER_KEYS.IS_LASTNAME_FOLLOWED_BY_FIRSTNAME_ORDER,
                            reviewBookingContent
                        )
                            ? osbPersonalDetail?.lastName +
                              ' ' +
                              osbPersonalDetail?.firstName
                            : osbPersonalDetail?.firstName +
                              ' ' +
                              osbPersonalDetail?.lastName}
                    </div>
                    <div className="light-review-section-body">
                        {osbPersonalDetail?.contactNumber}
                    </div>
                    <div className="light-review-section-body">
                        {osbPersonalDetail?.email}
                    </div>
                    {regNo && (
                        <div className="light-review-section-body">{regNo}</div>
                    )}
                </div>
            )}

            <div className="light-review-comments-section">
                <div className="light-review-hr-line-style"></div>

                {osbStep.osbEnableImage ? (
                    <OSBFileUploader
                        uploadImageInStore={
                            osbImageUploadStep.uploadedImage &&
                            osbImageUploadStep.uploadedImage?.length > 0
                                ? osbImageUploadStep.uploadedImage
                                : []
                        }
                        setUploadImageInStore={updatedOsbImage}
                        serviceAemContent={osbImageUploadStep.osbImageContent}
                    />
                ) : (
                    <></>
                )}

                <OsbComments
                    commentInStore={osbServiceStep.comment}
                    setCommentInStore={updatedComment}
                    serviceAemContent={
                        reviewBookingContent && reviewBookingContent?.elements
                    }
                    isRequiredComments={isRequiredComments}
                    emptyCommentErrorMsg={
                        !httpState.isLoading &&
                        reviewBookingContent &&
                        getObjectFromAEMJsonArray(
                            'placeHolderLabel',
                            reviewBookingContent
                        )
                    }
                />
            </div>

            {osbServiceStep.voucherCode.length ? (
                <div className="light-review-voucher-section">
                    <div className="light-review-hr-line-style"></div>
                    <div className="campaign-validation-in-review-booking">
                        <CampaignValidation />
                    </div>
                    <div className="light-review-voucher-title">
                        <img
                            src={CheckedIcon}
                            alt="Check"
                            className="voucher-check-img"
                        ></img>{' '}
                        &nbsp;
                        <div>
                            &nbsp;{osbServiceStep.voucherCode} &nbsp;{' '}
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'voucherApplied',
                                    reviewBookingContent
                                )}
                        </div>
                    </div>

                    <div className="light-voucher-description-section">
                        {voucherDetails.voucherDescription && (
                            <div
                                className="light-voucher-des"
                                dangerouslySetInnerHTML={{
                                    __html: voucherDetails.voucherDescription,
                                }}
                            />
                        )}
                        {osbCampaignStates.campaignStates[0].state ===
                            'VALID' &&
                            voucherDetails.potentialSavings > 0 && (
                                <div className="light-voucher-off">
                                    {getFormattedPrice(
                                        voucherDetails.potentialSavings?.toString(),
                                        osbStep
                                    )}
                                </div>
                            )}
                    </div>

                    <div
                        className="light-remove-voucher-link"
                        onClick={removeVoucherDetails}
                    >
                        {!httpState.isLoading &&
                            getObjectFromAEMJsonArray(
                                'removeVoucher',
                                reviewBookingContent
                            )}
                    </div>
                </div>
            ) : (
                <div className="light-review-voucher-section">
                    <div className="light-review-hr-line-style"></div>
                    <div
                        className="light-add-voucher"
                        onClick={() => openModelData()}
                    >
                        {!httpState.isLoading &&
                            getObjectFromAEMJsonArray(
                                'addVoucherLabel',
                                reviewBookingContent
                            )}

                        {openModel ? (
                            <div>
                                <LightJourneyVouchers
                                    openPopupData={openModel}
                                    setStatus={setStatus}
                                    refreshBookingSummary={
                                        props.refreshBookingSummary
                                    }
                                    refreshServicesData={refreshServicesData}
                                    refreshDeliveryData={refreshDeliveryData}
                                    refreshLocationData={refreshLocationData}
                                    loadVoucherAlertMessage={
                                        props.loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            )}
            <div className="light-review-total-section">
                <div className="light-review-hr-line-style"></div>
                <div className="voucher-disclaimer">
                    {isPOROrNoPriceServiceSelected(
                        selectedServicesList,
                        selectedOtherOptionsServices
                    ) &&
                        getObjectFromAEMJsonArray(
                            OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_DISCLAIMER,
                            reviewBookingContent
                        )}
                </div>
                <div className="light-total-body">
                    <div className="light-total-title">
                        {!httpState.isLoading &&
                            getObjectFromAEMJsonArray(
                                'totalCostLabel',
                                reviewBookingContent
                            )}
                        <div className="light-total-subtitle">
                            {!httpState.isLoading &&
                                getObjectFromAEMJsonArray(
                                    'includeVatLabel',
                                    reviewBookingContent
                                )}
                        </div>
                    </div>
                    <div className="light-total-price">
                        <div>
                            {calculateTotalPriceSummary(
                                osbStep,
                                osbServiceStep,
                                osbLightDeliveryServiceStep.selectedServices,
                                osbServiceLocationDetail.selectedOtherOptions,
                                potentialSavingAmount
                            )}
                        </div>
                        {potentialSavingAmount > 0 && (
                            <div className="discountedPrice">
                                {getActualPrice(
                                    potentialSavingAmount + '',
                                    osbServiceStep.totalPrice,
                                    osbStep
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
