import React, { useEffect, useState, useRef } from 'react';
import {
    DealerListResponse,
    DealerResultList,
} from '../../../../../services/redeem-rebate-service/redeem-rebate-service';
import ServiceHandler from '../../../../../services/service-handler';
import { PrimaryButton, SecondaryButton } from '../../../../common';
import { ActivityIndicator } from '../../../../common/activity-indicator/activity-indicator';
import { RedeemFormData } from '../redeem-rebates-steps';
import { useSelectDealerContent } from '../../hooks/redeem-rebates-steps/use-select-dealer-content';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { AppConfigurationService } from '../../../../../services/app-configuration-service/app-configuration-service';
import lincolnPlus from '../../../../../assets/lincoln-icons/plus.svg';

import './select-dealer.scss';

interface Props {
    setCurrentStep(stepNumber: number): void;
    setFormData(data: RedeemFormData): void;
    redeemFormData: RedeemFormData;
    zipCode?: string;
    city?: string;
    state?: string;
    dealerName?: string;
}

export const SelectDealer = (props: Props) => {
    const selectDealerContent = useSelectDealerContent();
    const rebatesStepName = {
        rebatesStepName: 'select dealer',
    };
    useAnalytics(['redeem-rebates-generic'], undefined, rebatesStepName);
    const [
        redeemRebateServiceProviders,
        setredeemRebateServiceProviders,
    ] = useState<DealerListResponse | null | undefined>();
    const [displayData, setDisplayData] = useState<DealerResultList[]>();
    const PER_PAGE = 5;
    const [totalCount, setTotalCount] = useState<number>(0);
    const searchResultTextPart1 = 'We have found ';
    let searchResultTextPart2;
    let searchResultTextProps;
    const isResponseEmpty =
        redeemRebateServiceProviders?.dealerResultList.filter(
            dealer => dealer.dealerName
        )?.length === 0;
    const startOver = () => {
        triggerCTAClickAnalytics('searchagain');
        props.setCurrentStep(1);
    };
    let apiResponse: Promise<DealerListResponse>;

    const loadMore = useRef<HTMLButtonElement>(null);

    const { brand, currentRoot } = new AppConfigurationService();

    const ONE = 1;
    const [enableShowMore, setEnableShowMore] = useState(PER_PAGE < totalCount);
    const initialIndex = totalCount < PER_PAGE ? totalCount : PER_PAGE;
    const [index, setIndex] = useState(initialIndex);
    const [fireEvents] = useAnalytics();
    const showMore = () => {
        loadMore.current?.focus();
        let newIndex = index + PER_PAGE;
        newIndex = Math.min(newIndex, totalCount);
        setIndex(newIndex);
        setEnableShowMore(newIndex < totalCount);
        triggerSelectDealerShowMoreOnclickAnalytics();
    };

    const triggerCTAClickAnalytics = (ctaContext: string) => {
        fireEvents(
            ['select-dealer-cta-onclick-event'],
            undefined,
            {
                selectDealerCTA: ctaContext,
            },
            false
        );
    };

    const triggerSelectDealerCheckboxAnalytics = () => {
        fireEvents(
            ['select-dealer-checkbox-onclick-event'],
            'select-dealer-checkbox-onclick-event',
            undefined,
            false
        );
    };

    const triggerSelectDealerShowMoreOnclickAnalytics = () => {
        fireEvents(
            ['select-dealer-show-more-onclick-event'],
            'select-dealer-show-more-onclick-event',
            undefined,
            false
        );
    };

    useEffect(() => {
        setEnableShowMore(PER_PAGE < totalCount);
        setIndex(totalCount < PER_PAGE ? totalCount : PER_PAGE);
    }, [totalCount]);

    useEffect(() => {
        if (props.redeemFormData.searchBy?.zipCode) {
            apiResponse = ServiceHandler.RedeemRebateService.getByZipCode(
                props.redeemFormData.searchBy?.zipCode
            );
        } else if (
            props.redeemFormData.searchBy?.city &&
            props.redeemFormData.searchBy?.state
        ) {
            apiResponse = ServiceHandler.RedeemRebateService.getByCityState(
                props.redeemFormData.searchBy?.city,
                props.redeemFormData.searchBy?.state
            );
        } else if (props.redeemFormData.searchBy?.dealerName) {
            apiResponse = ServiceHandler.RedeemRebateService.getByDealerName(
                props.redeemFormData.searchBy?.dealerName
            );
        }
        apiResponse &&
            apiResponse
                .then(serviceProvider => {
                    setredeemRebateServiceProviders(serviceProvider);
                    setDisplayData(serviceProvider?.dealerResultList);
                    setTotalCount(serviceProvider?.dealerResultList?.length);
                })
                .catch(console.error);
    }, []);

    if (props.redeemFormData.searchBy?.zipCode) {
        searchResultTextPart2 = ' dealers matching ';
        searchResultTextProps = <b>{props.redeemFormData.searchBy?.zipCode}</b>;
    } else if (
        props.redeemFormData.searchBy?.city &&
        props.redeemFormData.searchBy?.state
    ) {
        searchResultTextPart2 = ' dealer(s) near ';
        searchResultTextProps = (
            <b>
                {props.redeemFormData.searchBy?.city},{' '}
                {props.redeemFormData.searchBy?.state}
            </b>
        );
    } else if (props.redeemFormData.searchBy?.dealerName) {
        searchResultTextPart2 = ' dealer(s) matching ';
        searchResultTextProps = (
            <b>{props.redeemFormData.searchBy?.dealerName}</b>
        );
    }

    const filterResponseData = () => {
        if (
            redeemRebateServiceProviders &&
            redeemRebateServiceProviders.dealerResultList
        ) {
            const checkbox = document.getElementById(
                'filter-field'
            ) as HTMLInputElement;
            triggerSelectDealerCheckboxAnalytics();
            let response = redeemRebateServiceProviders.dealerResultList;
            if (checkbox && checkbox.checked) {
                response = redeemRebateServiceProviders.dealerResultList.filter(
                    dealer => dealer.creditMember
                );
            }
            setDisplayData(response);
            setTotalCount(response.length);
        }
    };

    const handleSelectDealer = (data: DealerResultList) => {
        props.setFormData({
            ...props.redeemFormData,
            selectedDealerDetails: {
                dealerCode: data.unEncryptedDlrpa,
                oarDealer: data.ownerAdvAwdsMember,
                creditDealer: data.creditMember,
                dealerName: data.dealerName,
                streetAddress: data.streetAddress,
                city: data.city,
                state: data.state,
                dealerZipCode: data.zip,
                phone: data.phone,
            },
        });
        triggerCTAClickAnalytics('selectdealer');
        props.setCurrentStep(3);
    };

    const getSelectDealerAriaLabel = (data: DealerResultList) => {
        return `${selectDealerContent.selectDealerButtonAriaLabel}
            button for ${data.dealerName} located at
            ${data.streetAddress}
            ${data.streetAddress.endsWith('.') ? ' ' : '. '}
            ${data.city}
            ${', '}
            ${data.state} Zipcode: ${data.zip}
            Contact Number:
            ${data.phone}`;
    };

    return selectDealerContent && redeemRebateServiceProviders ? (
        <div className="select-dealer-main">
            <div className="select-dealer-search-result">
                {isResponseEmpty ? (
                    <div className="search-result-text">
                        {selectDealerContent.errorMessage ||
                            `Sorry, we couldn't locate any dealers. Please try again.`}
                    </div>
                ) : (
                    <div className="search-result-text">
                        {searchResultTextPart1}
                        <b>{totalCount}</b>
                        {searchResultTextPart2}
                        {searchResultTextProps}
                    </div>
                )}
                <div className="search-again-button">
                    <PrimaryButton
                        color={'dark'}
                        fill={'outline'}
                        chevron={brand === 'lincoln'}
                        ariaLabel={
                            selectDealerContent.searchAgainButtonAriaLabel ||
                            'Go back and Search Again'
                        }
                        onClick={startOver}
                    >
                        {selectDealerContent.searchAgainButtonText ||
                            'Search Again'}
                    </PrimaryButton>
                </div>
            </div>
            {isResponseEmpty && (
                <hr className="select-dealer-divider no-dealers-found-divider" />
            )}
            {!isResponseEmpty && (
                <>
                    <hr className="select-dealer-divider" />
                    <div className="select-dealer-filter">
                        <input
                            id="filter-field"
                            type="checkbox"
                            className="select-dealer-checkbox"
                            aria-label={
                                selectDealerContent.filterAriaLabel ||
                                'Enable the checkbox to filter dealers who are enrolled in the Ford Service Credit Card program.'
                            }
                            onChange={filterResponseData}
                        />
                        <label htmlFor="filter-field">
                            {selectDealerContent.filterText ||
                                'Display only dealers who are enrolled in the Ford Service Credit Card program.'}
                        </label>
                    </div>
                    {displayData?.slice(0, index).map((data, dataIndex) => {
                        return (
                            <div key={data.dealerName}>
                                <h5 className="dealer-name">
                                    {data.dealerName}
                                </h5>
                                <div className="dealer-address">
                                    <p className="dealer-address-details">
                                        {data.streetAddress}
                                        {data.streetAddress.endsWith('.')
                                            ? ' '
                                            : '. '}
                                        {data.city}
                                        {', '}
                                        {data.state} {data.zip}
                                        <br />
                                        {data.phone}
                                    </p>
                                    <PrimaryButton
                                        ref={loadMore}
                                        fill="fill"
                                        color="dark"
                                        chevron={false}
                                        className="select-dealer-button"
                                        onClick={() => handleSelectDealer(data)}
                                        ariaLabel={getSelectDealerAriaLabel(
                                            data
                                        )}
                                    >
                                        {selectDealerContent.selectDealerButtonText ||
                                            'Select Dealer'}
                                    </PrimaryButton>
                                </div>
                                {dataIndex + 1 !== index && (
                                    <hr className="select-dealer-divider dealer-divider" />
                                )}
                            </div>
                        );
                    })}
                    <div className="show-more-with-search-plus">
                        <div>
                            {enableShowMore && (
                                <SecondaryButton
                                    onClick={showMore}
                                    className="show-more-button"
                                    aria-label={
                                        selectDealerContent.showMoreAriaLabel ||
                                        'Click to view more dealers'
                                    }
                                    noChevron={true}
                                >
                                    {selectDealerContent.showMoreText ||
                                        'Show More'}
                                    <img
                                        alt=""
                                        src={
                                            brand === 'lincoln'
                                                ? lincolnPlus
                                                : currentRoot +
                                                  'icons/search-plus.svg'
                                        }
                                        className="plus-circle"
                                    />
                                </SecondaryButton>
                            )}
                        </div>
                        <div className="page-count">
                            {selectDealerContent.displayingText || 'Displaying'}{' '}
                            {ONE}-{index} {selectDealerContent.ofText || 'of'}{' '}
                            {totalCount}
                        </div>
                    </div>
                    <hr className="select-dealer-divider show-more-dealer-divider" />
                </>
            )}
        </div>
    ) : (
        <ActivityIndicator className="full-height" />
    );
};
