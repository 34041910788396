import React, { useState, useEffect, useRef, useContext } from 'react';
import './service-history.scss';
import './service-history-print.scss';
import { BrandUtil } from '../../utils/brand-util/brand-util';
import { useSwipeable } from 'react-swipeable';
import { PrimaryButton, SecondaryButton } from '../../common';
import ServiceHandler from '../../../services/service-handler';
import { BreadcrumbWrapper } from '../../../components/common/breadcrumb-wrapper/breadcrumb-wrapper';
import {
    DSL_API_PATH,
    DSL_BASE_URL,
    KEYBOARD_KEYS,
    SESSION_KEY_API_CACHE,
} from '../../../constants';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { ServiceRecord } from '../../../models/service-history';
import { ServiceHistoryForm } from './service-history-form/service-history-form';
import { ServiceHistoryAccordion } from './service-history-accordion/service-history-accordion';
import { ServiceHistoryNotification } from './service-history-notification/service-history-notification';
import { ServiceDownloadHistory } from './service-download-history/service-download-history';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import { PageNotFoundView } from '../../../views/page-not-found-view/page-not-found-view';
import plus from '../../../assets/plus.png';
import infoIcon from '../../../assets/info-icon.svg';
import Tabs from '../../common/tabs/tabs';
import Tab from '../../common/tabs/tab';
import Popover from '../../common/popover/popover';

import { useServiceHistoryContent } from './hooks/use-service-history-content';
import { useServiceHistoryAddRecordContent } from './hooks/use-service-history-add-record-content';
import { useServiceHistoryVehInfoContent } from './hooks/use-service-history-vehicle-information-content';
import { CacheService } from '../../../services/cache-service/cache-service';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { useAnalytics } from '../../../hooks/use-analytics';
import { SupportTrio } from '../../common/support-trio/support-trio';
import ConnectedVehicleService from '../../../services/connected-vehicle-service/connected-vehicle-service';
import getNumberFormatByRegion from '../../utils/number-format-utill/number-format-mileage';
import DateTimeUtil from '../../utils/date-time-util/date-time-util';
import { replacePlaceholderByValue } from '../../utils/placeholder-util/replace-placeholder-by-value';
import successIcon from '../../../assets/success-checkmark.svg';
import { VehicleUtil } from '../../utils/vehicle-util/vehicle-util';
import { AppConfigurationService } from '../../../services/app-configuration-service/app-configuration-service';
import { ConnectedVehicleResponse } from '../../../models/connected-vehicle';
import { ViewTemplate } from '../view-template/view-template';
import { PageType } from '../view-template/page-type';
import ServerContext from '../../../contexts/serverContext';
import VehicleTypeService from '../../../services/vehicle-type-service/vehicle-type-service';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';

interface Props {
    vehicleAttributes: VehicleAttributes;
    userGuid?: string;
    uomDistance: number;
    page: PageType;
}

interface ServiceHistoryProps {
    serviceHistorySelectedIndex: number;
    serviceHistoryNaRegion: boolean;
    serviceHistoryImgRegion: boolean;
    serviceHistoryContent: any;
}
interface ServiceHistoryNotificationProps {
    serviceHistoryUpToDateNotification: string;
    serviceUpToDateIconAlt: string;
}

interface ServiceHistoryInformationProps {
    createNextServiceDueMessage: () => string;
    showServiceDuePopover: boolean;
    renderNextServiceDuePopover: any;
    serviceHistoryVehInfoContent: any;
    toggleServiceDuePopover: (e: any, value: boolean) => void;
    triggerServiceHistoryAnalytics: (eventName: string) => void;
}

interface BreadCrumbProps {
    breadCrumbTitle: string;
}
interface VehicleInformationProps {
    isVehicleStatusValid: any;
    vehicleName: any;
    serviceHistoryVehInfoContent: any;
    getNumberFormatByRegion: any;
    vehicleStatus: any;
    getDistanceLabel: any;
    showOdometerPopover: any;
    renderOdometerPopover: any;
    toggleOdometerPopover: any;
    triggerServiceHistoryAnalytics: any;
    dealerLocator: any;
    serviceHistoryContent: any;
}
interface ServiceFailureProps {
    naRegion: boolean;
    renderTabHeaders: any;
    serviceHistoryContent: any;
}

interface ServiceHistoryTitleRightProps {
    records: any[];
    showAddRecordForm: boolean;
    generatePDF: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    serviceHistoryContent: any;
    naRegion: boolean;
    isActive: boolean;
    onAddRecordForm: () => void;
    addFormButtonRef: React.RefObject<HTMLButtonElement>;
    plus: string;
}

interface ServiceHistoryTabsProps {
    records: any[];
    getFilteredRecords: () => any[];
    naRegion: boolean;
    mxRegion: boolean;
    imgRegion: boolean;
    renderTabHeaders: () => React.ReactElement;
    handlers: any;
    euRegion: boolean;
    end: number;
    serviceHistoryContent: any;
    onEditRecordForm: (serviceHistoryItem: any) => void;
    serviceHistoryRecordTab: () => string;
    props: any;
    handleDeleteRecord: (recordId: string) => void;
    serviceHistoryAddRecordContent: any;
    loadMoreFlag: boolean;
    loadLess: string;
    loadMore: string;
    toggleLoadMore: () => void;
    selectedIndex: number;
    vehicleAttributes: any;
    uomDistance: any;
}

const ServiceHistoryNotAvailable = (props: ServiceHistoryProps) => {
    const isRegionValid =
        props?.serviceHistoryNaRegion || props?.serviceHistoryImgRegion;
    const errorMessage =
        isRegionValid && props?.serviceHistorySelectedIndex === 1
            ? props?.serviceHistoryContent.noPriorRecordsFound
            : props?.serviceHistoryContent.noServiceRecordsText;

    return (
        <div
            className="service-history-not-available"
            role="tabpanel"
            dangerouslySetInnerHTML={{
                __html: errorMessage,
            }}
        ></div>
    );
};

const ServiceUpToDateNotification = (
    props: ServiceHistoryNotificationProps
) => (
    <div className="service-up-to-date">
        <p className="service-up-to-date-notification">
            {props.serviceHistoryUpToDateNotification}
        </p>
        <img
            className="notification-icon"
            src={successIcon}
            alt={props.serviceUpToDateIconAlt}
        />
    </div>
);

const NextServiceDueInformation = (props: ServiceHistoryInformationProps) => (
    <div className="next-service-due-section">
        <p className="your-next-service-date">
            {props.createNextServiceDueMessage()}
        </p>
        {props.showServiceDuePopover && props.renderNextServiceDuePopover()}
        {props.serviceHistoryVehInfoContent.nextServiceDueTooltip && (
            <button
                className="your-next-service-tooltip"
                onClick={(e: any) => {
                    props.toggleServiceDuePopover(e, true);
                    props.triggerServiceHistoryAnalytics('tooltip-onClick');
                }}
            >
                <img
                    className="your-next-service-tooltip-icon"
                    alt=""
                    aria-label={
                        props.serviceHistoryVehInfoContent
                            .yourNextServiceIsDueTooltipAlt
                    }
                    role="button"
                    src={infoIcon}
                />
            </button>
        )}
    </div>
);

const ServiceHistoryBreadCrumb = (props: BreadCrumbProps) => {
    return (
        <>
            <span tabIndex={0} className="current-page">
                {props.breadCrumbTitle}
            </span>
            <span className="back-slash">{'>'}</span>
        </>
    );
};

const VehicleInformationBar = (props: VehicleInformationProps) => (
    <section className="service-history-vehicle-details">
        <div className="service-history-vehicle-details-wrapper">
            <div className="service-history-vehicle-details-left">
                <div className="service-history-vehicle-details-name">
                    <h2
                        className={
                            !props.isVehicleStatusValid()
                                ? 'vehicle-name-centered'
                                : ''
                        }
                    >
                        {props.vehicleName?.toUpperCase()}
                    </h2>
                </div>
                <div className="service-history-vehicle-details-info">
                    {props.isVehicleStatusValid() && (
                        <section className={'service-history-vehicle-odometer'}>
                            <span className={'service-history-odometer-label'}>
                                {
                                    props.serviceHistoryVehInfoContent
                                        .odometerLabel
                                }
                                :
                            </span>
                            <span className={'service-history-odometer'}>
                                {getNumberFormatByRegion(
                                    String(
                                        props.vehicleStatus?.vehiclestatus
                                            .odometer.value
                                    )
                                )}{' '}
                                {props.getDistanceLabel()}
                            </span>
                            {props.showOdometerPopover &&
                                props.renderOdometerPopover()}
                            {props.serviceHistoryVehInfoContent
                                .odometerTooltipHeader &&
                                props.serviceHistoryVehInfoContent
                                    .odometerTooltipContent && (
                                    <span className="info-wrapper">
                                        <img
                                            className="odometer-info-icon info-icon"
                                            aria-label={
                                                'icon' +
                                                props
                                                    .serviceHistoryVehInfoContent
                                                    .odometerLabel +
                                                'tooltip'
                                            }
                                            role="button"
                                            alt=""
                                            onClick={(e: any) => {
                                                props.toggleOdometerPopover(
                                                    e,
                                                    true
                                                );
                                                props.triggerServiceHistoryAnalytics(
                                                    'odometer'
                                                );
                                            }}
                                            onKeyUp={(e: any) =>
                                                props.toggleOdometerPopover(
                                                    e,
                                                    true
                                                )
                                            }
                                            tabIndex={0}
                                        />
                                    </span>
                                )}
                        </section>
                    )}
                </div>
            </div>
            <div className="service-history-vehicle-title-right">
                <PrimaryButton
                    className="find-dealer-button"
                    color="dark"
                    chevron={false}
                    fill="fill"
                    onClick={props.dealerLocator}
                    ariaLabel={`${
                        props.serviceHistoryVehInfoContent.ctaAriaLabel
                    } ${props.serviceHistoryContent.openInNewtabArialabel ||
                        ''}`}
                    target={props.serviceHistoryVehInfoContent.ctaTarget}
                >
                    {props.serviceHistoryVehInfoContent.ctaLabel}
                </PrimaryButton>
            </div>
        </div>
    </section>
);

const ServiceFailureSection = (props: ServiceFailureProps) => {
    const errorMessage = props.serviceHistoryContent.serviceFailureErrorMessage;
    return (
        <>
            {props.naRegion && props.renderTabHeaders()}
            <div
                className={`service-history-not-available service-failure ${
                    props.naRegion ? 'error-na-region' : ''
                }`}
                dangerouslySetInnerHTML={{
                    __html: errorMessage,
                }}
            ></div>
        </>
    );
};

const ServiceHistoryTitleRight = (props: ServiceHistoryTitleRightProps) => (
    <div className="service-history-title-right">
        {props.records &&
            props.records?.length > 0 &&
            !props.showAddRecordForm && (
                <PrimaryButton
                    color={'light'}
                    fill={'fill'}
                    chevron={false}
                    onClick={props.generatePDF}
                    className={'no-shadow-transparent'}
                    aria-label={
                        props.serviceHistoryContent.downloadHistoryLabel
                    }
                >
                    <div className="service-history-download-button-format">
                        <img
                            className="service-history-download-icon"
                            alt={
                                props.serviceHistoryContent
                                    .downloadHistoryLabel + '-icon'
                            }
                        />
                        <span className="service-history-download-button-label">
                            {props.serviceHistoryContent.downloadHistoryLabel}
                        </span>
                    </div>
                </PrimaryButton>
            )}
        {props.naRegion && props.serviceHistoryContent.addRecordEnabled && (
            <PrimaryButton
                className={`service-history-add-button ${
                    props.isActive ? 'service-history-add-button-clicked' : ''
                }`}
                ref={props.addFormButtonRef}
                color={'dark'}
                fill={'fill'}
                chevron={false}
                onClick={props.onAddRecordForm}
                aria-label={props.serviceHistoryContent.addRecordLabel}
            >
                {props.serviceHistoryContent.addRecordLabel}
                <img
                    className="service-history-add-record-button-symbol"
                    src={plus}
                    alt={''}
                />
            </PrimaryButton>
        )}
    </div>
);

const ServiceHistoryTabs = (props: ServiceHistoryTabsProps) => {
    const renderRecords = props.records.length
        ? props.getFilteredRecords()
        : [];
    return (
        <div className="service-history-accordion">
            {(props.naRegion || props.mxRegion || props.imgRegion) &&
                props.renderTabHeaders()}
            {
                <div {...props.handlers}>
                    {renderRecords && renderRecords?.length > 0 ? (
                        <>
                            <ServiceHistoryAccordion
                                records={renderRecords}
                                euRegion={props.euRegion}
                                naRegion={props.naRegion}
                                imgRegion={props.imgRegion}
                                recordEnd={props.end}
                                serviceHistoryContent={
                                    props.serviceHistoryContent
                                }
                                onEditForm={(serviceHistoryItem: any) =>
                                    props.onEditRecordForm(serviceHistoryItem)
                                }
                                selectedTabName={props.serviceHistoryRecordTab()}
                                vehicleAttributes={props.vehicleAttributes}
                                deleteRecord={props.handleDeleteRecord}
                                selfServiceContent={
                                    props.serviceHistoryAddRecordContent
                                        .selfService
                                }
                                recordsType={props.serviceHistoryRecordTab()}
                                uomDistance={props.uomDistance}
                            />
                            <div>
                                {props.records.length > 5 ? (
                                    <div className="button-wrapper">
                                        <SecondaryButton
                                            className={'load-more'}
                                            onClick={props.toggleLoadMore}
                                        >
                                            {!props.loadMoreFlag
                                                ? props.loadLess
                                                : props.loadMore}
                                        </SecondaryButton>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </>
                    ) : (
                        <ServiceHistoryNotAvailable
                            serviceHistoryNaRegion={props.naRegion}
                            serviceHistoryImgRegion={props.imgRegion}
                            serviceHistorySelectedIndex={props.selectedIndex}
                            serviceHistoryContent={props.serviceHistoryContent}
                        />
                    )}
                </div>
            }
        </div>
    );
};

export const ServiceHistory = (props: Props) => {
    const serviceHistoryContent = useServiceHistoryContent();
    const serviceHistoryAddRecordContent = useServiceHistoryAddRecordContent();
    const serviceHistoryVehInfoContent = useServiceHistoryVehInfoContent();
    const { vin } = props.vehicleAttributes;
    const { isLincoln } = new BrandUtil();
    const vehicleUtil = new VehicleUtil();
    const appConfig = new AppConfigurationService();
    const isRegionNA = appConfig.isRegionNA();
    const [records, setRecords] = useState<any[]>([]);
    const [editRecord, setEditRecord] = useState<any[]>([]);
    const [isContentRequested, setContentRequested] = useState<boolean>(false);
    const addFormButtonRef = useRef<HTMLButtonElement>(null);
    const limitedViewToolTipRef = useRef<HTMLAnchorElement>(null);
    const [loadMoreFlag, setLoadMoreButtonFlag] = useState<boolean>(true);
    const [showAddRecordForm, setShowAddRecordForm] = useState<boolean>(false);
    const [start, setStart] = useState<number>(0);
    const [end, setEnd] = useState<number>(5);
    const [showRecords, setShowRecords] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [
        showAddServiceHistoryErrorModal,
        setShowAddServiceHistoryErrorModal,
    ] = useState<boolean>(false);
    const [ownerFlag, setOwnerFlag] = useState<boolean>(true);
    const [showPopover, setPopover] = useState<boolean>(false);
    const [showOdometerPopover, setOdometerPopover] = useState<boolean>(false);
    const [showServiceDuePopover, setServiceDuePopover] = useState<boolean>(
        false
    );
    const [notificationStatus, setNotificationStatus] = useState<string>('');
    const [notificationType, setNotificationType] = useState<string>('');
    const region = serviceHistoryContent.region;
    const euRegion = region === 'EU';
    const naRegion = region === 'NA';
    const mxRegion = region === 'MX';
    const imgRegion = region === 'IMG';
    const loadMore = serviceHistoryContent.loadMoreLabel;
    const loadLess = serviceHistoryContent.loadLessLabel;
    const [popoverPosition, setPopoverPosition] = useState({ left: 0, top: 0 });
    const [odometerPopoverPosition, setOdometerPopoverPosition] = useState({
        left: 0,
        top: 0,
    });
    const [vehicleName, setVehicleName] = useState<any>();
    const [vehicleStatus, setVehicleStatus] = useState<
        ConnectedVehicleResponse | undefined
    >();
    const [serviceDuepopoverPosition, setServiceDuePopoverPosition] = useState({
        left: 0,
        top: 0,
    });
    const [serviceUpToDate, setServiceUpToDate] = useState(false);
    const serverContext = useContext(ServerContext);
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = serverContext;

    const [fireEvents] = useAnalytics();
    const serviceHistoryContentTitle =
        serviceHistoryContent.serviceHistoryTitle;
    const serviceHistoryBreadcrumb = serviceHistoryContentTitle
        ?.toLowerCase()
        .replace(/<[^<>]*>/g, '');
    const serviceHistoryBreadcrumbTitle = serviceHistoryBreadcrumb?.replace(
        /(^\w)|(\s{1,3}\w)/g,
        letter => letter.toUpperCase()
    );
    // Analytics for Prior Record, Your Record and Add Record events
    const triggerRecordAnalytics = (eventName: any) => {
        let eventType = '';
        switch (eventName) {
            case 'prior-records': {
                eventType = 'service-history-prior-records';
                break;
            }
            case 'your-records': {
                eventType = 'service-history-your-records';
                break;
            }
            case 'add-record': {
                eventType = 'service-history-add-records';
                break;
            }
        }
        fireEvents(['target-trigger-view', eventType], eventType, {
            ymm: Promise.resolve(props.vehicleAttributes),
        });
    };

    const selectedVin = ServerSideService.isClientSide()
        ? window.sessionStorage.getItem('selectedVin')
        : false;

    const isMobile = ServerSideService.isClientSide()
        ? window.innerWidth <= 780
        : false;

    const transformResponse = (response: {
        userServices: Array<ServiceRecord>;
        dealerServices: Array<ServiceRecord>;
    }) => {
        const userServicesData = response.userServices.map(
            (userData: { serviceDate: string }) => ({
                ...userData,
                serviceCategory: 'owner',
                date: new Date(userData.serviceDate),
            })
        );
        const dealerServicesData = response.dealerServices.map(
            (dealerData: { serviceDate: string }) => ({
                ...dealerData,
                serviceCategory: 'dealer',
                date: new Date(dealerData.serviceDate),
            })
        );
        const mergeServicesData = [...userServicesData, ...dealerServicesData];
        const sortedData = [...mergeServicesData].sort((a, b) => {
            if (a.date === b.date) return 0;
            if (a.date < b.date) return -1;
            else return 1;
        });
        return sortedData;
    };

    const ServiceHistoryTitleCTA = () => (
        <section className="service-history-section">
            <section className="service-history-section-header">
                <div className="service-history-format">
                    <div
                        className="service-history-title"
                        dangerouslySetInnerHTML={{
                            __html: serviceHistoryContent.serviceHistoryTitle,
                        }}
                    ></div>
                    {(naRegion || mxRegion) &&
                        ownerFlag === false &&
                        serviceHistoryContent.showLimitedView && (
                            <>
                                {showPopover && renderPopover()}
                                {serverContext.webview === 'true' ? (
                                    ''
                                ) : (
                                    <div
                                        className="service-history-limited-view-format"
                                        onClick={(e: any) =>
                                            togglePopover(e, true)
                                        }
                                    >
                                        <img
                                            className="service-history-limited-view-icon"
                                            alt=""
                                        />
                                        <a
                                            className="service-history-limited-view-label"
                                            href="#"
                                            aria-label={
                                                'OPEN ' +
                                                serviceHistoryContent.limitedViewTooltipHeading +
                                                ' TOOLTIP'
                                            }
                                            ref={limitedViewToolTipRef}
                                            onClick={
                                                triggerServiceHistoryLimitedViewTooltipAnalytics
                                            }
                                        >
                                            {
                                                serviceHistoryContent.limitedViewLabel
                                            }
                                        </a>
                                    </div>
                                )}
                            </>
                        )}
                </div>
                <section className="hide-on-mobile">
                    {!isMobile && (
                        <ServiceHistoryTitleRight
                            records={records}
                            showAddRecordForm={showAddRecordForm}
                            generatePDF={generatePDF}
                            serviceHistoryContent={serviceHistoryContent}
                            naRegion={naRegion}
                            isActive={isActive}
                            onAddRecordForm={onAddRecordForm}
                            addFormButtonRef={addFormButtonRef}
                            plus={plus}
                        />
                    )}
                </section>
            </section>
        </section>
    );

    const setPopoverPositionState = (rect: DOMRect) => {
        const newPosition = {
            left: !isMobile ? rect.x + rect.width + 20 : 0,
            top: !isMobile ? rect.y + window.scrollY - 120 : 0,
        };
        setPopoverPosition(newPosition);
    };

    const setOdometerPopoverPositionState = (rect: DOMRect) => {
        const newPosition = {
            left: !isMobile ? rect.x + rect.width - 150 : 0,
            top: !isMobile ? rect.y + window.scrollY - 255 : 0,
        };
        setOdometerPopoverPosition(newPosition);
    };

    const updateLimitedViewPopoverPositions = () => {
        const popoverTriggerEle = document.getElementsByClassName(
            'service-history-limited-view-format'
        )[0];
        if (popoverTriggerEle) {
            const rect = popoverTriggerEle.getBoundingClientRect();
            setPopoverPositionState(rect);
        }
    };

    const setServicePopoverPositionState = (rect: DOMRect) => {
        const newPosition = {
            left: !isMobile ? rect.x - 310 : 0,
            top: !isMobile ? rect.y + window.scrollY - 200 : 0,
        };
        setServiceDuePopoverPosition(newPosition);
    };

    const updateServiceDuePopoverPositions = () => {
        const popoverTriggerEle = document.getElementsByClassName(
            'your-next-service-tooltip'
        )[0];
        if (popoverTriggerEle) {
            const rect = popoverTriggerEle.getBoundingClientRect();
            setServicePopoverPositionState(rect);
        }
    };

    const updateAllPopoverPositions = () => {
        updateLimitedViewPopoverPositions();
        updateServiceDuePopoverPositions();
    };

    const triggerErrorAnalytics = () => {
        fireEvents(
            ['target-trigger-view', 'service-history-error-page'],
            'service-history-error-page',
            {
                ymm: Promise.resolve(props.vehicleAttributes),
            }
        );
    };
    const getServiceHistoryRecords = () => {
        if (vin) {
            ServiceHandler.VehicleServiceHistory.getVehicleServiceHistory(vin)
                .then(value => {
                    setRecords(transformResponse(value));
                    setOwnerFlag(value.ownerFlag);
                    setShowRecords(true);
                    setShowError(false);
                    setServiceUpToDate(value.serviceUpToDate);
                })
                .catch(() => {
                    setShowError(true);
                    setShowRecords(false);
                    triggerErrorAnalytics();
                });
        }
    };

    const scrollPageToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    useEffect(() => {
        scrollPageToTop();
        getServiceHistoryRecords();
        if (selectedVin) {
            new VehicleTypeService()
                .request(
                    selectedVin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                )
                .then(response => {
                    setVehicleName(
                        vehicleUtil.getVehicleName(
                            response.vehicleData,
                            isRegionNA
                        )
                    );
                });
            new ConnectedVehicleService()
                .getVehicleStatus(selectedVin)
                .then(vehicleStatusData => {
                    setVehicleStatus(vehicleStatusData);
                });
        }
        window.addEventListener('resize', updateAllPopoverPositions);
        setTimeout(() => {
            setContentRequested(true);
        }, 3500);
        return () => {
            window.removeEventListener('resize', updateAllPopoverPositions);
        };
    }, []);

    const [isActive, setActive] = useState(false);
    const [selectedIndex, setSelectedTabIndex] = useState<number>(0);
    const [selectedTitle, setSelectedTitle] = useState<string>('');

    useEffect(() => {
        (document.querySelector(
            'button.tab-active'
        ) as HTMLButtonElement)?.focus();
    }, [selectedIndex]);

    const focusOnFormFirstElement = () => {
        setTimeout(() => {
            const firstElement = document.querySelector(
                '#mileage-label'
            ) as HTMLElement;
            firstElement.setAttribute('tabIndex', '0');
            firstElement.focus();
        }, 500);
    };
    const onAddRecordForm = () => {
        if (!isActive) {
            setActive(!isActive);
        }
        setEditRecord([]);
        setShowAddRecordForm(true);
        triggerRecordAnalytics('add-record');
        focusOnFormFirstElement();
    };

    async function clearApiCache() {
        const {
            current3LetterCountryCode,
            currentLanguageCode,
        } = ServiceHandler.AppConfigurationService;
        const SERVICE_HISTORY =
            DSL_BASE_URL! + !appConfig.isRegionEU()
                ? DSL_API_PATH.SERVICE_HISTORY_AUTH
                : DSL_API_PATH.SERVICE_HISTORY_UNAUTH;
        const cachedURL = `${SERVICE_HISTORY}?vin=${vin}&countryCode=${current3LetterCountryCode.toUpperCase()}&languageCode=${currentLanguageCode}`;
        new CacheService().evictFromCache(SESSION_KEY_API_CACHE, cachedURL);
        return 1;
    }

    const setFocusOnAddRecord = () => {
        setTimeout(() => {
            addFormButtonRef?.current?.focus();
        }, 200);
    };

    const handleCancelAddRecord = (isRecordUpdated: boolean) => {
        setActive(!isActive);
        setEditRecord([]);
        setShowAddRecordForm(false);
        if (isRecordUpdated) {
            const result = clearApiCache();
            result.then(() => {
                getServiceHistoryRecords();
            });
        }
        scrollPageToTop();
        setFocusOnAddRecord();
    };

    const handleAddEditStatus = (status: string, type: string) => {
        setNotificationStatus(status);
        setNotificationType(type);
    };

    const onEditRecordForm = (serviceHistoryItem: any) => {
        if (showAddRecordForm) {
            setShowAddRecordForm(false);
        }
        setEditRecord(serviceHistoryItem);
        focusOnFormFirstElement();
        setTimeout(() => {
            setActive(true);
            setShowAddRecordForm(true);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }, 100);
    };

    const handleDeleteRecord = (serviceId: string) => {
        ServiceHandler.VehicleServiceHistory.deleteServiceHistory(serviceId)
            .then(() => {
                const result = clearApiCache();
                result.then(() => {
                    getServiceHistoryRecords();
                });
                setNotificationStatus('success');
                setNotificationType('delete');
                scrollPageToTop();
            })
            .catch(() => {
                setNotificationStatus('error');
                setNotificationType('delete');
                scrollPageToTop();
            });
    };

    const handleErrorAddRecord = () => {
        setShowAddServiceHistoryErrorModal(!showAddServiceHistoryErrorModal);
        scrollPageToTop();
    };

    const serviceHistoryRecordTab = (): string => {
        if (selectedIndex === 0 || euRegion || mxRegion) {
            return 'your records';
        } else if (selectedIndex === 1) {
            return 'prior records';
        }
        return '';
    };

    // Analytics for Vehicle Info cta, Limited View Chat/Call links,Tooltip and Odometer Tooltio in Service History
    const triggerServiceHistoryAnalytics = (eventName: any) => {
        let event = '';
        switch (eventName) {
            case 'odometer': {
                event = 'service-history-odometer-tooltip-onclick-event';
                break;
            }
            case 'call': {
                event =
                    'service-history-limited-view-tooltip-call-onclick-event';
                break;
            }
            case 'chat': {
                event =
                    'service-history-limited-view-tooltip-chat-onclick-event';
                break;
            }
            case 'tooltip-onClick': {
                event = 'service-history-tooltip-onclick-event';
                break;
            }
        }
        fireEvents(
            [event],
            undefined,
            {
                fieldName: serviceHistoryRecordTab(),
            },
            false
        );
    };

    const dealerLocator = () => {
        NewAnalyticsService.fireReferralExitEventBasedOnUrl(
            serviceHistoryVehInfoContent.ctaHref,
            fireEvents
        );
        window.open(serviceHistoryVehInfoContent.ctaHref);
    };
    // Analytics for Download and Load More buttons in Service History
    const triggerServiceHistoryLoadMoreAndDownloadAnalytics = (
        eventName: any
    ) => {
        const event =
            eventName === 'download'
                ? 'service-history-download-onclick-event'
                : 'service-history-load-more-onclick-event';
        fireEvents(
            [event],
            undefined,
            {
                ymm: Promise.resolve(props.vehicleAttributes),
                fieldName: serviceHistoryRecordTab(),
            },
            false
        );
    };

    const toggleLoadMore = () => {
        if (records) {
            if (loadMoreFlag) {
                setStart(0);
                setEnd(records?.length);
                triggerServiceHistoryLoadMoreAndDownloadAnalytics('load-more');
            } else {
                setStart(0);
                setEnd(5);
                window.scrollTo(0, 100);
            }
            setLoadMoreButtonFlag(!loadMoreFlag);
        }
    };

    const togglePopover = (e: any, popoverState: boolean) => {
        if (e.currentTarget.getBoundingClientRect) {
            const rect = e.currentTarget.getBoundingClientRect();
            setPopoverPositionState(rect);
            setPopover(popoverState);
            if (!isMobile && popoverState) window.scrollTo(0, 60);
        } else {
            setPopover(false);
        }
        e.preventDefault();
    };

    const toggleOdometerPopover = (e: any, popoverState: boolean) => {
        if (
            (e.type === 'click' && e.currentTarget.getBoundingClientRect) ||
            (e.currentTarget.getBoundingClientRect && e.keyCode === 13)
        ) {
            const rect = e.currentTarget.getBoundingClientRect();
            setOdometerPopoverPositionState(rect);
            setOdometerPopover(popoverState);
            if (!isMobile && popoverState) window.scrollTo(0, 30);
        } else {
            setOdometerPopover(false);
        }
        e.preventDefault();
    };

    const toggleServiceDuePopover = (e: any, popoverState: boolean) => {
        if (e.currentTarget.getBoundingClientRect) {
            const rect = e.currentTarget.getBoundingClientRect();
            setServicePopoverPositionState(rect);
            setServiceDuePopover(popoverState);
            if (!isMobile && popoverState) window.scrollTo(0, 60);
        } else {
            setServiceDuePopover(false);
        }
        e.preventDefault();
    };

    const slideTabHeader = (index: number) => {
        const mobileTabHeader = document.getElementsByClassName(
            'tabs-nav'
        )[0] as HTMLDivElement;
        const leftPosition = index === 0 ? '0px' : '-120px';
        if (mobileTabHeader) {
            mobileTabHeader.style.transform = `translateX(${leftPosition})`;
        }
    };

    const switchTabs = (title: string, index: number) => {
        if (window.innerWidth <= 480) {
            setTimeout(() => slideTabHeader(index), 50);
        }
        if (index === 1) {
            triggerRecordAnalytics('prior-records');
        }
        if (index === 0) {
            triggerRecordAnalytics('your-records');
        }
        if (index !== selectedIndex) {
            setSelectedTabIndex(index);
            setSelectedTitle(title);
        }
    };

    const onTabClick = () => {
        return;
    };

    const handleSwipe = (direction: string) => {
        if (direction === 'left' && selectedIndex === 0) {
            setTimeout(() => slideTabHeader(1), 50);
            setSelectedTabIndex(1);
        } else if (direction === 'right' && selectedIndex === 1) {
            setTimeout(() => slideTabHeader(0), 50);
            setSelectedTabIndex(0);
        }
    };

    const getFilteredRecords = () => {
        const filteredRecords = [...records];
        if (euRegion || imgRegion) {
            return filteredRecords.slice(start, end);
        } else if (naRegion || mxRegion) {
            const selectedTabRecords = filteredRecords.filter(record => {
                if (selectedIndex === 0) {
                    return record.currentOwnershipPeriod === true;
                } else if (selectedIndex === 1) {
                    return record.currentOwnershipPeriod === false;
                }
            });
            return selectedTabRecords.slice(start, end);
        }
    };

    const handleLimitedViewTooltipClose = (e: any) => {
        togglePopover(e, false);
        setTimeout(() => limitedViewToolTipRef?.current?.focus(), 200);
    };

    const renderPopover = () => {
        const {
            limitedViewTooltipHeading,
            limitedViewTooltipContent,
            limitedViewTooltipRte,
        } = serviceHistoryContent;
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: limitedViewTooltipContent,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: limitedViewTooltipRte,
                    }}
                    className="popover-rte"
                />
            </div>
        );

        return (
            <Popover
                name="limited-view-popover"
                tooltipPosition="left"
                mask={true}
                handleClose={e => handleLimitedViewTooltipClose(e)}
                popoverPosition={popoverPosition}
                heading={limitedViewTooltipHeading}
                content={popoverContent}
                focusElement={'.service-history-limited-view-label'}
            />
        );
    };

    const renderOdometerPopover = () => {
        const {
            odometerTooltipContent,
            odometerTooltipHeader,
        } = serviceHistoryVehInfoContent;
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: odometerTooltipContent,
                    }}
                />
            </div>
        );
        return (
            <Popover
                name="service-history-odometer"
                tooltipPosition="bottom"
                mask={false}
                handleClose={e => toggleOdometerPopover(e, false)}
                popoverPosition={odometerPopoverPosition}
                heading={odometerTooltipHeader}
                content={popoverContent}
                focusElement={'.odometer-info-icon'}
            />
        );
    };

    const keyDownHandler = (e: any): void => {
        const RIGHT_ARROW_KEY = 39;
        const LEFT_ARROW_KEY = 37;
        if (e.keyCode === RIGHT_ARROW_KEY || e.keyCode === LEFT_ARROW_KEY) {
            const nextIndex = selectedIndex ? 0 : 1;
            setSelectedTabIndex(nextIndex);
        }
    };
    const renderTabHeaders = () => (
        <Tabs
            onChange={switchTabs}
            activeTabIndex={selectedIndex}
            activeTabTitle={selectedTitle}
            className={' blue-tabs'}
        >
            <Tab
                onTabClick={onTabClick}
                key={0}
                title={serviceHistoryContent.yourRecordsLabel}
                onKeyDownHandler={keyDownHandler}
            />
            <Tab
                onTabClick={onTabClick}
                key={1}
                title={serviceHistoryContent.priorRecordsLabel}
                onKeyDownHandler={keyDownHandler}
            />
        </Tabs>
    );

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
    });

    const pdfPageSetup = () => {
        const printPages = document.getElementsByClassName('print-page');
        const pages = document.getElementsByClassName('print-footer');
        const printlogos = document.getElementsByClassName(
            'print-header-ford-logo'
        );
        const pagenumbers = document.getElementsByClassName(
            'print-page-number'
        );
        const promoBanner = document.getElementsByClassName(
            'promoBanner'
        )[0] as HTMLElement;
        if (promoBanner) {
            promoBanner.style.display = 'none';
        }
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i] as HTMLElement;
            const logo = printlogos[i] as HTMLElement;
            const pageNumber = pagenumbers[i] as HTMLElement;
            const printPage = printPages[i] as HTMLElement;
            if (i > 0) {
                page.style.bottom = -i * 2 + 'px';
                logo.style.marginTop = 40 + i * 2 + 'px';
                pageNumber.style.bottom = 20 + -i * 2 + 'px';
            }
            if (i == pages.length - 1) {
                printPage.classList.add('print-page-last-record');
            }
        }
    };
    const generatePDF = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.media = 'print';

        style.appendChild(
            document.createTextNode(
                '@page { size: A4 Portrait !important;margin: 0in;}'
            )
        );
        head.appendChild(style);
        pdfPageSetup();

        window.print();
        setTimeout(() => {
            const promoBanner = document.querySelector(
                '.promoBanner'
            ) as HTMLElement;
            if (promoBanner) {
                promoBanner.style.display = 'block';
            }
        }, 500);
        triggerServiceHistoryLoadMoreAndDownloadAnalytics('download');
    };

    const getNotificatonMessage = () => {
        let value = '';
        const {
            editSuccessMessage,
            editFailureMessage,
            addSuccessMessage,
            addFailureMessage,
        } = serviceHistoryAddRecordContent;
        const {
            deleteSuccessMessage,
            deleteFailureMessage,
        } = serviceHistoryContent;
        switch (notificationType) {
            case 'edit': {
                value =
                    notificationStatus === 'success'
                        ? editSuccessMessage
                        : editFailureMessage;
                break;
            }
            case 'add': {
                value =
                    notificationStatus === 'success'
                        ? addSuccessMessage
                        : addFailureMessage;
                break;
            }
            case 'delete': {
                value =
                    notificationStatus === 'success'
                        ? deleteSuccessMessage
                        : deleteFailureMessage;
                break;
            }
            default: {
                break;
            }
        }
        return value;
    };

    const renderNotification = () => {
        return (
            <ServiceHistoryNotification
                notificationMessage={getNotificatonMessage()}
                notificationStatus={notificationStatus}
                customClass="deleteRecord"
                isLincoln={isLincoln}
            />
        );
    };

    const triggerServiceHistoryLimitedViewTooltipAnalytics = () => {
        fireEvents(
            ['service-history-limited-view-tooltip-onclick-event'],
            undefined,
            {
                fieldName: serviceHistoryRecordTab(),
            },
            false
        );
    };

    const attachChatCallEventListeners = (contactLink: any, type: string) => {
        if (contactLink) {
            contactLink.removeAttribute('title');
            if (!contactLink.hasAttribute('analytics-listener')) {
                contactLink.onclick = () => {
                    if (type === 'Call') {
                        triggerServiceHistoryAnalytics('call');
                    } else if (type === 'chat') {
                        triggerServiceHistoryAnalytics('chat');
                    }
                };
            }
            contactLink.setAttribute('analytics-listener', type);
            contactLink.setAttribute('tabindex', '0');
            contactLink.setAttribute(
                'aria-label',
                `${
                    contactLink.innerText
                } ${serviceHistoryContent.openInNewtabArialabel || ''}`
            );
            contactLink.onkeydown = (event: any) => {
                if (event.key === KEYBOARD_KEYS.SPACE) {
                    event.stopPropagation();
                    const linkHref = contactLink.getAttribute('href');
                    linkHref && window.open(linkHref, '_blank');
                }
            };
        }
    };
    useEffect(() => {
        const LIMITED_ANCHOR_SELECTOR =
            '.limited-view-popover .popover-rte p a';
        if (showPopover) {
            const callLink = document.querySelector(
                LIMITED_ANCHOR_SELECTOR +
                    '[title="Call" i],' +
                    LIMITED_ANCHOR_SELECTOR +
                    '[analytics-listener="Call"]'
            ) as HTMLAnchorElement;

            const chatLink = document.querySelector(
                LIMITED_ANCHOR_SELECTOR +
                    '[title="chat" i],' +
                    LIMITED_ANCHOR_SELECTOR +
                    '[analytics-listener="chat"]'
            ) as HTMLAnchorElement;

            const rteWrapper = document.querySelectorAll('.popover-rte>p');
            for (let i = 0; i < rteWrapper?.length; i++) {
                rteWrapper[i]?.removeAttribute('tabindex');
            }
            attachChatCallEventListeners(callLink, 'Call');
            attachChatCallEventListeners(chatLink, 'chat');
        }
    }, [showPopover]);

    if (showError) {
        return (
            <>
                {isContentRequested &&
                    Object.keys(serviceHistoryContent).length === 0 && (
                        <PageNotFoundView />
                    )}
                {Object.keys(serviceHistoryContent).length !== 0 &&
                !showRecords ? (
                    <>
                        <ServiceHistoryNotification
                            notificationMessage={
                                serviceHistoryContent.serviceFailureErrorMessage
                            }
                            notificationStatus="error"
                            isLincoln={isLincoln}
                        />
                        <ServiceHistoryTitleCTA />
                        {serviceHistoryAddRecordContent && showAddRecordForm && (
                            <section className="service-history-records-section">
                                <ServiceHistoryForm
                                    onCancelAddRecord={handleCancelAddRecord}
                                    serviceHistoryContent={
                                        serviceHistoryContent
                                    }
                                    serviceHistoryAddRecordContent={
                                        serviceHistoryAddRecordContent
                                    }
                                    addEditStatus={handleAddEditStatus}
                                    editableRecord={editRecord}
                                    showAddServiceHistoryErrorModal={
                                        handleErrorAddRecord
                                    }
                                    vehicleAttributes={props.vehicleAttributes}
                                    userGuid={props.userGuid}
                                />
                            </section>
                        )}
                        <ServiceFailureSection
                            naRegion={naRegion}
                            renderTabHeaders={renderTabHeaders}
                            serviceHistoryContent={serviceHistoryContent}
                        />
                    </>
                ) : (
                    <ActivityIndicator className="full-height" />
                )}
            </>
        );
    }
    const isVehicleStatusValid = () =>
        vehicleStatus &&
        vehicleStatus.vehiclestatus &&
        vehicleStatus.vehiclestatus.odometer &&
        vehicleStatus.vehiclestatus.odometer.value > 0;

    const getDistanceLabel = () =>
        props.uomDistance === 1
            ? serviceHistoryVehInfoContent.milesLabel
            : serviceHistoryVehInfoContent.kilometersLabel;

    const getFormattedDate = () => {
        const dueDate = records[0].nextService.dueDate;
        if (dueDate) {
            const dateParts = dueDate.split('/');
            const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
            return new DateTimeUtil().formatDateByRegion(formattedDate);
        }
    };

    const createNextServiceDueMessage = () => {
        const nextService = records[0].nextService;
        if (nextService.desc) return nextService.desc;
        const result = replacePlaceholderByValue(
            serviceHistoryVehInfoContent.nextServiceDueInformation,
            {
                nextServiceDueDate: getFormattedDate(),
                nextServiceDueMileage: getNumberFormatByRegion(
                    props.uomDistance === 1
                        ? nextService.mileageInMiles
                        : nextService.mileageInKilometres
                ),
                nextServiceMileageUnit: getDistanceLabel(),
            }
        );
        return result;
    };

    const renderNextServiceDuePopover = () => {
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            serviceHistoryVehInfoContent.nextServiceDueTooltip,
                    }}
                    className="next-service-due-tooltip-text"
                />
            </div>
        );

        return (
            <Popover
                name="next-service-due"
                tooltipPosition="right"
                mask={true}
                handleClose={e => toggleServiceDuePopover(e, false)}
                popoverPosition={serviceDuepopoverPosition}
                heading={''}
                content={popoverContent}
            />
        );
    };

    const isNextServiceInformationAvailable = () =>
        Array.isArray(records) &&
        records[0] &&
        records[0].nextService &&
        serviceHistoryVehInfoContent.nextServiceDueInformation;

    const isVehicleInfoContentValid = () =>
        vehicleName &&
        serviceHistoryVehInfoContent &&
        serviceHistoryVehInfoContent.ctaLabel &&
        serviceHistoryVehInfoContent.ctaHref;

    const isServiceUpToDate = () =>
        serviceHistoryVehInfoContent?.serviceHistoryUpToDateNotification &&
        serviceUpToDate;

    return (
        <ViewTemplate
            seoConfig={{
                pageTitle: props.page,
                applicationName: '',
            }}
            page={props.page}
            className="trailer-checklist-view"
            hideLinksList={true}
            hideSearchBar={serverContext.webview === 'true'}
        >
            {isContentRequested &&
                Object.keys(serviceHistoryContent).length === 0 && (
                    <PageNotFoundView />
                )}
            {Object.keys(serviceHistoryContent).length !== 0 && showRecords ? (
                <>
                    {serverContext.webview !== 'true' && (
                        <BreadcrumbWrapper
                            currentPage={
                                serverContext.webview !== 'true' && vehicleName
                            }
                            hashId="support"
                            title={
                                <ServiceHistoryBreadCrumb
                                    breadCrumbTitle={
                                        serviceHistoryBreadcrumbTitle
                                    }
                                />
                            }
                        />
                    )}
                    {notificationStatus && renderNotification()}
                    <ServiceDownloadHistory
                        records={records}
                        euRegion={euRegion}
                        naRegion={naRegion}
                        imgRegion={imgRegion}
                        serviceHistoryContent={serviceHistoryContent}
                        vehicleAttributes={props.vehicleAttributes}
                    />
                    <ServiceHistoryTitleCTA />
                    {isVehicleInfoContentValid() && (
                        <VehicleInformationBar
                            isVehicleStatusValid={isVehicleStatusValid}
                            vehicleName={vehicleName}
                            serviceHistoryVehInfoContent={
                                serviceHistoryVehInfoContent
                            }
                            getNumberFormatByRegion={getNumberFormatByRegion}
                            vehicleStatus={vehicleStatus}
                            getDistanceLabel={getDistanceLabel}
                            showOdometerPopover={showOdometerPopover}
                            renderOdometerPopover={renderOdometerPopover}
                            toggleOdometerPopover={toggleOdometerPopover}
                            triggerServiceHistoryAnalytics={
                                triggerServiceHistoryAnalytics
                            }
                            dealerLocator={dealerLocator}
                            serviceHistoryContent={serviceHistoryContent}
                        />
                    )}
                    <section className="next-service-section">
                        {isServiceUpToDate() && (
                            <ServiceUpToDateNotification
                                serviceHistoryUpToDateNotification={
                                    serviceHistoryVehInfoContent.serviceHistoryUpToDateNotification
                                }
                                serviceUpToDateIconAlt={
                                    serviceHistoryVehInfoContent.serviceUpToDateIconAlt
                                }
                            />
                        )}
                        {isNextServiceInformationAvailable() && (
                            <NextServiceDueInformation
                                createNextServiceDueMessage={
                                    createNextServiceDueMessage
                                }
                                showServiceDuePopover={showServiceDuePopover}
                                renderNextServiceDuePopover={
                                    renderNextServiceDuePopover
                                }
                                serviceHistoryVehInfoContent={
                                    serviceHistoryVehInfoContent
                                }
                                toggleServiceDuePopover={
                                    toggleServiceDuePopover
                                }
                                triggerServiceHistoryAnalytics={
                                    triggerServiceHistoryAnalytics
                                }
                            />
                        )}
                    </section>
                    <section className="hide-on-desktop">
                        {isMobile && (
                            <ServiceHistoryTitleRight
                                records={records}
                                showAddRecordForm={showAddRecordForm}
                                generatePDF={generatePDF}
                                serviceHistoryContent={serviceHistoryContent}
                                naRegion={naRegion}
                                isActive={isActive}
                                onAddRecordForm={onAddRecordForm}
                                addFormButtonRef={addFormButtonRef}
                                plus={plus}
                            />
                        )}
                    </section>
                    <section className="service-history-records-section">
                        {serviceHistoryAddRecordContent &&
                            showAddRecordForm &&
                            editRecord && (
                                <ServiceHistoryForm
                                    onCancelAddRecord={handleCancelAddRecord}
                                    serviceHistoryContent={
                                        serviceHistoryContent
                                    }
                                    serviceHistoryAddRecordContent={
                                        serviceHistoryAddRecordContent
                                    }
                                    editableRecord={editRecord}
                                    addEditStatus={handleAddEditStatus}
                                    showAddServiceHistoryErrorModal={
                                        handleErrorAddRecord
                                    }
                                    vehicleAttributes={props.vehicleAttributes}
                                    userGuid={props.userGuid}
                                />
                            )}
                        <ServiceHistoryTabs
                            records={records}
                            getFilteredRecords={() =>
                                getFilteredRecords() || []
                            }
                            naRegion={naRegion}
                            mxRegion={mxRegion}
                            imgRegion={imgRegion}
                            renderTabHeaders={renderTabHeaders}
                            handlers={handlers}
                            euRegion={euRegion}
                            end={end}
                            serviceHistoryContent={serviceHistoryContent}
                            onEditRecordForm={onEditRecordForm}
                            serviceHistoryRecordTab={serviceHistoryRecordTab}
                            props={props}
                            handleDeleteRecord={handleDeleteRecord}
                            serviceHistoryAddRecordContent={
                                serviceHistoryAddRecordContent
                            }
                            loadMoreFlag={loadMoreFlag}
                            loadLess={loadLess}
                            loadMore={loadMore}
                            toggleLoadMore={toggleLoadMore}
                            selectedIndex={selectedIndex}
                            vehicleAttributes={props.vehicleAttributes}
                            uomDistance={props.uomDistance}
                        />
                    </section>
                    {serverContext.webview !== 'true' && (
                        <SupportTrio
                            folder="common"
                            experienceFragmentName="service-history-support"
                        />
                    )}
                </>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </ViewTemplate>
    );
};
