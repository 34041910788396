import React from 'react';
import { FMFullWidthBannerProps } from '../../types/fm-full-width-banner';
import FMButton from '../fm-button/fm-button';
import './fm-full-width-banner.scss';
const FMFullWidthBanner = (props: FMFullWidthBannerProps) => {
    return (
        <div className='fm-full-width-banner'>
            {props.title && (
                <div className={'fm-full-width-banner__title'}>
                    {props.title}
                </div>
            )}
            <img
                src={props.imgPath}
                alt={props.imgAlt}
                className={'fm-full-width-banner__image'}
            />
            <div className={'fm-full-width-banner__body-container'}>
                {props.subCopy && (
                    <div className={'fm-full-width-banner__subcopy'}>
                        {props.subCopy}
                    </div>
                )}
                {props.button && props.button.label && (
                    <div className='fm-full-width-banner__button'>
                        <FMButton
                            type={props.button.type}
                            label={props.button.label}
                            chevron={props.button.chevron}
                            disabled={props.button.disabled}
                            onClick={props.button.onClick}
                            preventDefault={props.button.preventDefault}
                            href={props.button.href}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default FMFullWidthBanner;
