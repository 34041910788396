import React, { useState, useEffect, useContext, useRef } from 'react';
import './maintenance-schedule-details.scss';
import { VehicleUtil } from '../../../utils/vehicle-util/vehicle-util';
import { useMaintenanceScheduleContent } from '../hooks/use-maintenance-schedule-content';
import { PageNotFoundView } from '../../../../views/page-not-found-view/page-not-found-view';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { PrimaryButton } from '../../../common';
import { ErrorMessage } from '../../error-message/error-message';
import { MaintenanceScheduleCarousel } from '../maintenance-schedule-carousel/maintenance-schedule-carousel';
import ServiceHandler from '../../../../services/service-handler';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { SupportTrio } from '../../../common/support-trio/support-trio';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import ConnectedVehicleService from '../../../../services/connected-vehicle-service/connected-vehicle-service';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import { ConnectedVehicleOdometer } from '../../connected-vehicle-odometer/connected-vehicle-odometer';
import useUserProfile from '../../../../hooks/use-user-profile';
import ServerContext from '../../../../contexts/serverContext';
import { useLocation } from 'react-router';
import VehicleTypeService, {
    VehicleType,
} from '../../../../services/vehicle-type-service/vehicle-type-service';
import { ConnectedVehicleResponse } from '../../../../models/connected-vehicle';
import { PageType } from '../../view-template/page-type';
import { ViewTemplate } from '../../view-template/view-template';
import isWebviewCheck from '../../../utils/webviewCheck/webviewCheck';
import MaintenanceScheduleBreadcrumb from './maintenance-schedle-breadcrmb';
interface Props {
    vin: string;
    uomDistance: number;
    page: PageType;
}

export const MaintenanceScheduleDetails = (props: Props) => {
    const maintenanceScheduleContent = useMaintenanceScheduleContent();
    const isContentAvailable = !!Object.keys(maintenanceScheduleContent).length;
    const mounted = useRef<any>();
    const {
        maintenanceScheduleHeadline,
        maintenanceScheduleDescription,
        maintenanceScheduleAlternateDescription,
        maintenanceScheduleIOLMDescription,
        ctaLabel,
        ctaHref,
        ctaTarget,
        ctaAnalyticsContext,
        maintenanceCTALabel,
        maintenanceCTALink,
        maintenanceScheduleCTAAnalyticsContext,
        maintenanceScheduleErrorMessage,
        maintenanceScheduleCarouselHeadline: carouselHeading,
        maintenanceScheduleCarouselToggle: toggleText,
        toggleSubheadline,
        maintenanceScheduleSupplementaryDescription: supplementaryDescription,
        serviceTimeIntervalMonths: monthUnit,
        serviceTimeIntervalSingleYear: singleYearUnit,
        serviceTimeIntervalYears: yearUnit,
        miLabelForSubHeadline,
        kmLabelForSubHeadline,
        odometerLabel,
        odometerTooltipHeader,
        odometerTooltipContent,
        openInNewtabArialabel,
        noMileageAlternativeCarouselToggle,
        noMileageAlternativeToggleSubheadline,
    } = maintenanceScheduleContent;

    const serverContext = useContext(ServerContext);
    const route = useLocation().pathname.split('/');
    const maintenanceLandingPath = route[1];
    const currentRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length)
        : '';
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = serverContext;
    const [vehicleName, setVehicleName] = useState<any>();
    const [uomDistance, setUomDistance] = useState<number>(0);
    const [maintenanceResponse, setMaintenanceResponse] = useState<any>([]);
    const [vehicleTypeResponse, setVehicleTypeResponse] = useState<
        VehicleType | undefined
    >();
    const [showError, setShowError] = useState<boolean>(false);
    const appConfig = new AppConfigurationService();
    const contentNA = appConfig.isRegionNA();
    const [fireEvents] = useAnalytics();
    const vehicleUtil = new VehicleUtil();
    const [authenticatedUser, setAuthenticatedUser] = useState('');
    const [vehicleStatus, setVehicleStatus] = useState<
        ConnectedVehicleResponse | undefined
    >();
    const [vehiclesData, setVehiclesData] = useState<
        VehicleAttributes | undefined
    >(undefined);
    const authenticationService = new AuthenticationService();
    const profile = useUserProfile();

    const isWebview = isWebviewCheck();
    const { vin } = props;

    const triggerLoadAnalytics = (selectedVehicleData: any) => {
        fireEvents(
            ['target-trigger-view', 'maintenance-schedule'],
            'maintenance-schedule',
            { ymm: Promise.resolve(selectedVehicleData) },
            false
        );
    };

    const getVinVehicleResponse = () => {
        new VehicleTypeService()
            .request(
                vin,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            )
            .then(response => {
                const { vehicleData } = response;
                setVehicleName(
                    vehicleUtil.getVehicleName(vehicleData, contentNA)
                );
                setVehiclesData(vehicleUtil.getVehicleObject(vehicleData, vin));
                setShowError(false);
            })
            .catch(() => {
                setShowError(true);
            });
    };

    const triggerErrorAnalytics = () => {
        if (vehiclesData) {
            fireEvents(
                ['target-trigger-view', 'maintenance-schedule-error'],
                'maintenance-schedule-error',
                { ymm: Promise.resolve(vehiclesData) }
            );
        } else {
            fireEvents(
                ['target-trigger-view', 'maintenance-schedule-error'],
                'maintenance-schedule-error'
            );
        }
    };

    const getMaintenanceResponse = () => {
        if (vin) {
            ServiceHandler.MaintenanceScheduleService.getMaintenanceSchedule(
                vin
            )
                .then(maintenanceResult => {
                    setMaintenanceResponse(maintenanceResult?.values);
                    getVinVehicleResponse();
                })
                .catch(() => {
                    setShowError(true);
                    setMaintenanceResponse([]);
                });
        }
    };

    const getIOMCapableResponse = () => {
        if (vin) {
            ServiceHandler.VehicleTypeService.request(
                vin,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            )
                .then(vehicleType => {
                    setVehicleTypeResponse(vehicleType);
                })
                .catch(() => {
                    setVehicleTypeResponse(undefined);
                    setShowError(true);
                });
        }
    };
    useEffect(() => {
        getMaintenanceResponse();
        getIOMCapableResponse();
    }, []);

    const getVehicleStatus = () => {
        if (!vehicleStatus && vin && authenticationService.getCatBundle())
            new ConnectedVehicleService()
                .getVehicleStatus(vin)
                .then(vehicleStatusData => {
                    setVehicleStatus(vehicleStatusData);
                });
    };
    const attachOnClickEvent = () => {
        const registerElement: any = document.querySelector(
            "a[href='#register']"
        );
        const loginElement: any = document.querySelector("a[href='#login']");
        if (registerElement) {
            registerElement.addEventListener('click', function(event: any) {
                event.preventDefault();
                authenticationService.register();
            });
        }

        if (loginElement) {
            loginElement.addEventListener('click', function(event: any) {
                event.preventDefault();
                authenticationService.login();
            });
        }
    };
    useEffect(() => {
        getVehicleStatus();
        if (!mounted.current) {
            mounted.current = true;
        } else {
            attachOnClickEvent();
        }
    });

    useEffect(() => {
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setAuthenticatedUser('loggedIn');
                } else {
                    setAuthenticatedUser('notLoggedIn');
                }
            })
            .catch(() => {
                setShowError(true);
            });
        if (profile) {
            setUomDistance(profile.profile.uomDistance);
        }
    }, [profile]);

    useEffect(() => {
        if (vehiclesData && !showError) triggerLoadAnalytics(vehiclesData);
    }, [vehiclesData]);

    useEffect(() => {
        if (showError) triggerErrorAnalytics();
    }, [showError]);

    const maintenanceCTALinkWithVin = (): string => {
        if (vin && maintenanceCTALink) {
            return maintenanceCTALink.replace(':vin', vin);
        }
        return maintenanceCTALink;
    };

    const triggerMaintenanceScheduleCTAOnClickEvent = (ctaContext: string) => {
        if (ctaContext) {
            fireEvents(
                ['maintenance-schedule-cta-onclick-event'],
                undefined,
                {
                    fieldName: ctaContext,
                },
                false
            );
        }
    };

    const dealerLocator = () => {
        window.open(maintenanceCTALinkWithVin());
        triggerMaintenanceScheduleCTAOnClickEvent(
            maintenanceScheduleCTAAnalyticsContext
        );
    };

    const findOutMore = () => {
        window.open(ctaHref);
        triggerMaintenanceScheduleCTAOnClickEvent(ctaAnalyticsContext);
    };
    const renderMaintenanceDetails = () => {
        if (showError) {
            return (
                <div className="maintenance-error">
                    <ErrorMessage
                        errorMessage={maintenanceScheduleErrorMessage}
                    />
                </div>
            );
        }

        return (
            <>
                <div>
                    {!isWebview && (
                        <>
                            <div
                                className="maintenance-schedule-details-page-subtext"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        authenticatedUser === 'loggedIn'
                                            ? maintenanceScheduleDescription
                                            : maintenanceScheduleAlternateDescription,
                                }}
                            />

                            <div
                                className="maintenance-schedule-details-page-olm-text"
                                dangerouslySetInnerHTML={{
                                    __html: vehicleTypeResponse?.isIolmCapable
                                        ? maintenanceScheduleIOLMDescription
                                        : '',
                                }}
                            />
                        </>
                    )}
                    {ctaLabel && !isWebview && (
                        <div>
                            <PrimaryButton
                                className="find-out-button"
                                color="dark"
                                role="link"
                                chevron={false}
                                fill="fill"
                                onClick={findOutMore}
                                target={ctaTarget}
                                newTabAriaLabel={openInNewtabArialabel}
                            >
                                {ctaLabel}
                            </PrimaryButton>
                        </div>
                    )}
                    {vehicleName ? (
                        <section className="service-schedule-vehicle-details">
                            <div className="service-schedule-vehicle-details-wrapper">
                                <div className="service-schedule-vehicle-details-left">
                                    <div className="service-schedule-vehicle-details-name">
                                        <h2
                                            className={
                                                contentNA ? 'na-vehicle' : ''
                                            }
                                        >
                                            {vehicleName?.toUpperCase()}
                                        </h2>
                                    </div>
                                    <ConnectedVehicleOdometer
                                        vinNumber={vin}
                                        uomDistance={uomDistance}
                                        miLabelForSubHeadline={
                                            miLabelForSubHeadline
                                        }
                                        kmLabelForSubHeadline={
                                            kmLabelForSubHeadline
                                        }
                                        odometerLabel={odometerLabel}
                                        odometerTooltipHeader={
                                            odometerTooltipHeader
                                        }
                                        odometerTooltipContent={
                                            odometerTooltipContent
                                        }
                                    />
                                </div>
                                {maintenanceCTALabel && !isWebview && (
                                    <div className="service-schedule-vehicle-title-right">
                                        <PrimaryButton
                                            role="link"
                                            color="dark"
                                            chevron={false}
                                            fill="fill"
                                            newTabAriaLabel={
                                                openInNewtabArialabel
                                            }
                                            onClick={dealerLocator}
                                        >
                                            {maintenanceCTALabel}
                                        </PrimaryButton>
                                    </div>
                                )}
                            </div>
                        </section>
                    ) : (
                        <ActivityIndicator />
                    )}
                </div>
                {maintenanceResponse && maintenanceResponse.length > 0 && (
                    <MaintenanceScheduleCarousel
                        maintenanceResponse={maintenanceResponse}
                        carouselHeading={carouselHeading}
                        toggleText={toggleText}
                        toggleSubheadline={toggleSubheadline}
                        noMileageToggleText={noMileageAlternativeCarouselToggle}
                        noMileageToggleSubheadline={
                            noMileageAlternativeToggleSubheadline
                        }
                        isNARegion={contentNA}
                        supplementaryDescription={supplementaryDescription}
                        monthUnit={monthUnit}
                        singleYearUnit={singleYearUnit}
                        yearUnit={yearUnit}
                        miLabelForSubHeadline={miLabelForSubHeadline}
                        kmLabelForSubHeadline={kmLabelForSubHeadline}
                        vehicleStatus={vehicleStatus}
                        uomDistance={props.uomDistance}
                        openInNewtabArialabel={openInNewtabArialabel}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <ViewTemplate
                page={props.page}
                className="trailer-checklist-view"
                hideLinksList={isWebview}
                hideSearchBar={isWebview}
                showDisclosureContent={isWebview ? false : true}
            >
                {isContentAvailable ? (
                    <>
                        <MaintenanceScheduleBreadcrumb
                            breadcrumbTitle={
                                maintenanceScheduleContent.maintenanceScheduleHeadline
                            }
                            maintenanceLandingPath={maintenanceLandingPath}
                            currentRoot={currentRoot}
                            vehicleName={vehicleName}
                            isWebview={isWebview}
                        />
                        <section className="maintenance-schedule-details-page">
                            <section className="maintenance-schedule-details-page-header">
                                <h1
                                    className="maintenance-schedule-details-page-title"
                                    dangerouslySetInnerHTML={{
                                        __html: maintenanceScheduleHeadline,
                                    }}
                                />
                            </section>
                            {renderMaintenanceDetails()}
                        </section>
                        {!isWebview && (
                            <SupportTrio
                                folder="common"
                                experienceFragmentName="maintenance-schedule-support"
                            />
                        )}
                    </>
                ) : (
                    <PageNotFoundView />
                )}
            </ViewTemplate>
        </>
    );
};
