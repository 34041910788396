import React, { useState, useEffect, useReducer } from 'react';
import './light-dealer-flow-lincoln.scss';
import {
    DealerAdditionalInfo,
    DealerProfileInfo,
} from '../../../../../../models/osb-model/osb-dealer-info';
import GoogleMapService from '../../../../../../services/osb-service/google-map-service/google-map-service';
import DealerSearchService from '../../../../../../services/osb-service/google-map-service/dealer-search-service';
import {
    getVehicleAttributesForAnalytics,
    setRouteToPersist,
    isAuthenticationActive,
    getObjectFromAEMJson,
    buildNavigationUrl,
    findDealerFromMarketDealers,
    getGoMainHeaderHeight,
} from '../../../osb-utils';
import {
    useDealerStep,
    getInitialDealerState,
    useVehicleStep,
    useHttp,
    useOSBStep,
    useStepProgressBar,
    usePageRefresh,
    useViewport,
} from '../../../../../../hooks/owners-osb/';
import { dealerStepReducer } from '../../../../../../reducers/osb-reducers';
import { DealerStep } from '../../../../../../models/osb-model/osb-dealer-step';
import { FordAccount } from '../../../ford-account/ford-account';
import { LightDealerSearch } from '../light-dealer-search/light-dealer-search';
import { DealerDetailsLightJourney } from '../light-dealer-details/light-dealer-details';
import parse from 'html-react-parser';
import {
    DEALER_STEP_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_CLIENT_STORAGE_KEYS,
    STEP_PROGRESS_BAR_KEYS,
    ACTIVITY_INDICATOR_KEYS,
} from '../../../osb-constant';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import {
    triggerDealerSearchLoadAnalytics,
    triggerDealerSearchResultsAnalytics,
    triggerBookServiceGlobalCTAOnClickAnalytics,
} from '../../../analytics/osb-analytics';
import { OSBPayload } from '../../../../../../services/shortcode-service/shortcode-service';
import { OSBUseLocation } from '../../../common/osb-use-location/osb-use-location';
import { OsbPathDealerStep } from '../../../osb-controller';
import { LightOsbHomeRetrieveBooking } from '../osb-home-retrieve-booking/light-osb-home-retrieve-booking';
import { OsbLoader } from '../../../../../common/osb-loader/osb-loader';
import useUserProfile from '../../../../../../hooks/use-user-profile';
import { useHistory, useLocation } from 'react-router-dom';
import { Picture } from '../../../../../common/picture/picture';
import AuthenticationService from '../../../../../../services/authentication-service/authentication-service';
import serverSideService from '../../../../../../services/server-side-service/server-side-service';
import { DealerLandingPage } from '../dealer-landing-page/dealer-landing-page';
import { VehicleDetail } from '../../../../../../models/profile-with-vehicles';
import DealerDetailsWrapper from '../light-dealer-details/dealer-details-wrapper';
import useMarketDealerInfoService from '../../../../../../hooks/owners-osb/use-market-dealerInfo-service';

export const LightDealerFlow = () => {
    const deepLinkParams = new URLSearchParams(useLocation().search);
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const [
        numberOfLeadingCharToStrip,
        setNumberOfLeadingCharToStrip,
    ] = useState<number>(2);
    const { osbVehicleStep } = useVehicleStep();
    const { osbStep, invalidateAuthentication } = useOSBStep();
    const {
        osbDealerStep,
        callContentService,
        setOSBDealerStepPayload,
    } = useDealerStep();
    const { httpState, dispatch } = useHttp();
    const { isMobileView } = useViewport();
    const [fireEvents] = useAnalytics();
    const [dealerStep, setDealerStep] = useReducer(
        dealerStepReducer,
        getInitialDealerState()
    );
    const history = useHistory();
    const [isloadAnalyticsTriggered, setIsloadAnalyticsTriggered] = useState<
        boolean
    >(false);
    const setComponentPayload = (
        payload:
            | {
                  [key: string]:
                      | string
                      | DealerProfileInfo
                      | boolean
                      | number
                      | []
                      | {};
              }
            | DealerStep
    ): void => {
        setDealerStep({ type: 'SET', payload: payload });
    };
    const [
        dealerStepSpecialServicesContent,
        setDealerStepSpecialServicesContent,
    ] = useState<any>();
    const [dealerStepContent, setDealerStepContent] = useState<any>();
    const [dealerStepMoreContent, setDealerStepMoreContent] = useState<any>();
    const [errorMsgDealer, setErrorMsgDealer] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMsgDealerDetails, setErrorMsgDealerDetails] = useState('');
    const [locationErrorMsgDetails, setLocationErrorMsgDetails] = useState('');
    const [searchResultsCount, setSearchResultsCount] = useState('');
    const [
        resolvedPreferredDealerList,
        setResolvedPreferredDealerList,
    ] = useState<DealerProfileInfo[] | undefined>(undefined);
    const [isViewMoreButtonVisible, setIsViewMoreButtonVisible] = useState<
        boolean
    >(false);
    const [allDealersShown, setAllDealersShown] = useState<DealerProfileInfo[]>(
        []
    );

    const { setOSBStepPayload } = useOSBStep();

    const profile = useUserProfile();
    const { updateProgressBarStatus } = useStepProgressBar();
    const [progressPercentToDisplay, setProgressPercentToDisplay] = useState<
        string
    >(ACTIVITY_INDICATOR_KEYS.DEFAULT_PROGRESS_PERCENT);
    const [isUseLocationClicked, setIsUseLocationClicked] = useState<boolean>(
        false
    );
    const [preferredDealerIds, setPreferredDealerIds] = useState<string[]>([]);
    const limitArray = (array: DealerProfileInfo[], limit: any) => {
        if (array) {
            return array.slice(0, limit);
        }
    };
    usePageRefresh();

    const refreshDealerDetailsList = async (
        dealerProfiles: DealerProfileInfo[]
    ) => {
        if (isViewMoreButtonVisible) {
            dealerProfiles =
                limitArray(
                    dealerProfiles,
                    getObjectFromAEMJson(
                        DEALER_STEP_KEYS.INITIAL_DEALERS_SHOWN,
                        dealerStepMoreContent
                    )
                ) || [];
        } else {
            dealerProfiles =
                limitArray(
                    dealerProfiles,
                    getObjectFromAEMJson(
                        DEALER_STEP_KEYS.MAX_DEALERS_SHOWN,
                        dealerStepMoreContent
                    )
                ) || [];
        }

        setComponentPayload({ filteredDealerProfiles: dealerProfiles });
    };

    const getOpeningHours = (osbDealers: any, dealerId: string) => {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.openingHours;
    };
    const getDealerName = (osbDealers: any, dealerId: string) => {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.dealerName;
    };
    const changeFirstCharToLowerCase = (serviceName: string) => {
        return serviceName.charAt(0).toLowerCase() + serviceName.slice(1);
    };
    const getSpecialServicesInfo = (element: any) => {
        const dealerSpecialServices: string[] = [];
        const specialServicesList = getObjectFromAEMJson(
            DEALER_STEP_KEYS.SPECIAL_SERVICES_LIST,
            dealerStepSpecialServicesContent
        );
        const serviceListArr: string[] = specialServicesList.split(',');
        for (const service of serviceListArr) {
            if (element[service] === true) {
                dealerSpecialServices.push(
                    getObjectFromAEMJson(
                        changeFirstCharToLowerCase(service),
                        dealerStepSpecialServicesContent
                    )
                );
            }
        }
        return dealerSpecialServices;
    };

    function getDealerFilterServiceAvailability(osbDealers: any, data: any) {
        try {
            return JSON.parse(
                getPropertyFromDealerAdditionalInfo(
                    osbDealers,
                    osbStep.numberOfLeadingCharactersToStrip > 0
                        ? data.DealerID.substring(
                              osbStep.numberOfLeadingCharactersToStrip
                          )
                        : data.DealerID,
                    'dealerFilterServiceAvailability'
                )
            );
        } catch (error) {
            return [];
        }
    }

    function getPropertyFromDealerAdditionalInfo(
        osbDealers: any,
        dealerId: string,
        property: keyof DealerAdditionalInfo
    ) {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        if (
            Object.prototype.hasOwnProperty.call(
                dealer?.additionalInfo,
                property
            )
        ) {
            return dealer.additionalInfo[property];
        }
        return [];
    }

    const getDelivery = (osbDealers: any, dealerId: string) => {
        const dealer = osbDealers?.find(
            (dealerProfile: DealerProfileInfo) =>
                dealerProfile.dealerCode === dealerId
        );
        return dealer.delivery;
    };
    const createDealerProfileFromBingDealer = async (
        osbDealers: DealerProfileInfo[]
    ) => {
        const results = await fetchMarketDealerInfo(deepLinkParams);
        if (!results) return;
        setIsViewMoreButtonVisible(
            osbDealers.length >
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.INITIAL_DEALERS_SHOWN,
                    dealerStepMoreContent
                )
        );
        const dealerList: DealerProfileInfo[] = [];
        const _osbEnabledDealerFromBing = dealerStep.osbEnabledDealerFromBing;
        _osbEnabledDealerFromBing.forEach((element: any) => {
            const dealerProfile: DealerProfileInfo = {
                dealerCode:
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID,
                dealerName: getDealerName(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID
                ),
                postalCode: element.PostCode,
                street: element.AddressLine1,
                town: element.Locality,
                openingHours: getOpeningHours(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID
                ),
                customerRating: element.CustomerRatingsService,
                customerRespondentsService: element.CustomerRespondentsService,
                distance: element.distance,
                primaryPhone: element.PrimaryPhone,
                location: element.location,
                didVideoCheck: Boolean(element.VideoCheck),
                didMobileService: Boolean(element.MobileService),
                delivery: getDelivery(
                    osbDealers,
                    numberOfLeadingCharToStrip > 0
                        ? element.DealerID.substring(numberOfLeadingCharToStrip)
                        : element.DealerID
                ),
                dealerType:
                    results?.dealers?.find(
                        e =>
                            e.dealerCode ===
                            (numberOfLeadingCharToStrip > 0
                                ? element.DealerID.substring(
                                      numberOfLeadingCharToStrip
                                  )
                                : element.DealerID)
                    )?.dealerType + ''.trim().toString(),
                additionalInfo: {
                    earliestAvailableTimeslot: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'earliestAvailableTimeslot'
                    ),
                    dealerFilterServiceAvailability: getDealerFilterServiceAvailability(
                        osbDealers,
                        element
                    ),
                    nextAppointmentDateAm: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDateAm'
                    ),
                    nextAppointmentDatePm: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDatePm'
                    ),
                    nextAppointmentDateWeekend: getPropertyFromDealerAdditionalInfo(
                        osbDealers,
                        numberOfLeadingCharToStrip > 0
                            ? element.DealerID.substring(
                                  numberOfLeadingCharToStrip
                              )
                            : element.DealerID,
                        'nextAppointmentDateWeekend'
                    ),
                },
                specialServices: getSpecialServicesInfo(element),
                serviceDepartmentCV:
                    element.HasServiceDepartmentCV || element.TransitService,
                serviceDepartmentPV: element.HasServiceDepartmentPV,
                electricService: element.BEVService,
            };
            dealerList.push(dealerProfile);
        });
        setAllDealersShown(dealerList);
        setOSBDealerStepPayload({
            osbEnabledDealerFromBing: dealerList,
            dealerProfiles: dealerList,
        });
        return limitArray(
            dealerList,
            getObjectFromAEMJson(
                DEALER_STEP_KEYS.INITIAL_DEALERS_SHOWN,
                dealerStepMoreContent
            )
        );
    };
    const getDealersFromMarketDealers = async (
        osbEnabledDealerIds: string[]
    ) => {
        const results = await fetchMarketDealerInfo(deepLinkParams);
        if (!results) return [];
        const dealerProfileInfos: DealerProfileInfo[] = [];
        osbEnabledDealerIds.forEach(id => {
            const dealerProfileInfo = findDealerFromMarketDealers(
                id,
                results?.dealers
            );
            if (dealerProfileInfo != null) {
                dealerProfileInfos.push(dealerProfileInfo);
            }
        });
        return dealerProfileInfos;
    };
    const getOSBDealers = async (dealerData: any) => {
        if (dealerData.length <= 0) {
            setErrorMsgDealer('OSB_NO_DATAFOUND');
            setLoading(false);
        } else {
            // form osb dealer id list for the OSB dealer information
            setErrorMsgDealer('');
            const osbEnabledDealerIds = dealerData.map(function(dlr: {
                [x: string]: any;
            }) {
                if (numberOfLeadingCharToStrip > 0) {
                    return dlr['DealerID'].substring(
                        numberOfLeadingCharToStrip
                    );
                } else {
                    return dlr['DealerID'];
                }
            });
            setLoading(true);
            const [
                dealerProfileInfos,
                dealerProfileFromBing,
            ] = await Promise.all([
                getDealersFromMarketDealers(osbEnabledDealerIds),
                getDealersFromMarketDealers(osbEnabledDealerIds).then(
                    async dealerProfileInfos => {
                        return (
                            (await createDealerProfileFromBingDealer(
                                dealerProfileInfos
                            )) || []
                        );
                    }
                ),
            ]);
            setLocationErrorMsgDetails('');
            setErrorMsgDealerDetails('');
            setLoading(false);
            setComponentPayload({
                dealerProfiles: dealerProfileFromBing,
                filteredDealerProfiles: dealerProfileFromBing,
                selectedDealerProfile: dealerProfileInfos[0] || {},
                selectedDealerName: dealerProfileInfos[0].dealerName || '',
            });
            setProgressPercentToDisplay(
                ACTIVITY_INDICATOR_KEYS.DEFAULT_PROGRESS_PERCENT
            );
            if (!dealerProfileFromBing?.length) {
                setOSBStepPayload({ displayProgressBar: false });
            } else {
                setOSBStepPayload({ displayProgressBar: true });
            }
        }
    };

    const fetchChosenLocation = (input: string) => {
        invalidateAuthentication();
        if (
            !osbStep.isAuthenticatedFlow ||
            (osbStep.isAuthenticatedFlow && isAuthenticationActive(osbStep))
        ) {
            setComponentPayload({ selectedLocation: input });
        } else {
            if (!isAuthenticationActive(osbStep)) {
                new AuthenticationService().login();
            }
        }
    };

    const onSearchDealersByDistanceSuccess = async (
        bingDealers: any,
        origin: string,
        coordinate?: any,
        callback?: any
    ) => {
        const results = await fetchMarketDealerInfo(deepLinkParams);
        if (!results) return;
        setOSBDealerStepPayload({
            searchType:
                origin === 'searchByLocation'
                    ? DEALER_STEP_KEYS.LOCATION
                    : DEALER_STEP_KEYS.DEALER_NAME,
        });

        if (bingDealers.length) setLoading(false);
        const _osbEnabledDealerFromBing = DealerSearchService.filterOnOsbDealerIds(
            results?.dealers,
            bingDealers,
            numberOfLeadingCharToStrip
        );

        // increase bing search logic - begin
        const osbEnabledDealerFromBingCount = _osbEnabledDealerFromBing.length;
        const maxDealerShown = getObjectFromAEMJson(
            DEALER_STEP_KEYS.MAX_DEALERS_SHOWN,
            dealerStepMoreContent
        );
        const _searchDistanceIndex = dealerStep.searchDistanceIndex;
        if (
            osbEnabledDealerFromBingCount < maxDealerShown &&
            origin === 'searchByLocation' &&
            _searchDistanceIndex <
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.RADIUS_OPIONS,
                    dealerStepMoreContent
                ).length
        ) {
            callback(null);
        } else if (origin === 'searchByLocation') {
            GoogleMapService.getDealerDistance(
                coordinate,
                100,
                _osbEnabledDealerFromBing,
                dealerData => {
                    dealerStep.osbEnabledDealerFromBing = dealerData;
                    setOSBDealerStepPayload({
                        osbEnabledDealerFromBing: dealerData,
                    });
                    getOSBDealers(dealerData);
                }
            );
        } else {
            dealerStep.osbEnabledDealerFromBing = _osbEnabledDealerFromBing;
            setOSBDealerStepPayload({
                osbEnabledDealerFromBing: _osbEnabledDealerFromBing,
            });
            getOSBDealers(_osbEnabledDealerFromBing);
        }
    };
    const geocodeLocation = (results?: any) => {
        if (results && results !== null) {
            dealerStep.geocoordinate = results;
            setLocationErrorMsgDetails('');
        } else {
            results = dealerStep.geocoordinate;
        }
        osbDealerStep.geocoordinate = dealerStep.geocoordinate;

        if (results.length <= 0) {
            setLoading(false);
            setLocationErrorMsgDetails(
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.NO_LOCATIONS_LABEL,
                    dealerStepContent
                )
            );
        }
        const coordinate: any = results[0];
        const _searchDistanceIndex: number =
            dealerStep.searchDistanceIndex ||
            getObjectFromAEMJson(
                DEALER_STEP_KEYS.RADIUS_OPIONS,
                dealerStepMoreContent
            ).indexOf(
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.DEFAULT_DISTANCE,
                    dealerStepMoreContent
                )
            );
        let radius = getObjectFromAEMJson(
            DEALER_STEP_KEYS.RADIUS_OPIONS,
            dealerStepMoreContent
        )[_searchDistanceIndex];
        if (
            getObjectFromAEMJson(
                DEALER_STEP_KEYS.DISTANCE_UNIT,
                dealerStepMoreContent
            ).toLowerCase() === 'miles'
        ) {
            // convert radius to KM
            radius = radius * 1.60934;
        }
        dealerStep.searchDistanceIndex = _searchDistanceIndex + 1;
        const polygon = osbStep.polygon;
        const maxResult = 100;
        DealerSearchService.bingDealerSearchByLocation(
            results,
            radius,
            maxResult,
            (dealers: any) => {
                onSearchDealersByDistanceSuccess(
                    dealers,
                    'searchByLocation',
                    coordinate,
                    () => {
                        geocodeLocation();
                    }
                );
            },
            polygon
        );
    };

    const fetchSelectedLocation = (location: string, type?: string) => {
        setIsUseLocationClicked(false);
        setLoading(true);

        if (type === DEALER_STEP_KEYS.LOCATION) {
            setOSBDealerStepPayload({ selectedLocation: location });
            GoogleMapService.getGeocodeLocation(
                osbStep.geoCountryCode,
                location,
                geocodeLocation
            );
            osbDealerStep.searchLocationType = 'manual location';
        } else {
            const maxResult = 1000;
            const polygon = osbStep.polygon;
            // prefix call
            // const prefix = getPrefix(osbStep.osbThreeLetterCountryCode);
            DealerSearchService.bingDealerByName(
                maxResult,
                (dealers: any) => {
                    onSearchDealersByDistanceSuccess(
                        dealers,
                        'searchByDealer',
                        location
                    );
                    setOSBDealerStepPayload({
                        selectedDealerName: dealers[0].DealerName,
                    });
                },
                polygon,
                location,
                osbStep.osbTwoLetterCountryCode
            );
        }
        setOSBStepPayload({
            preferredDealerJourney: false,
        });
    };

    const triggerDealerSearchError = () => {
        const osbDealerSearchError: OSBPayload = {
            errorDescription: osbDealerStep.errorDescription,
        };
        fireEvents(
            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SEARCH_DEALER_ERROR,
            LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
            {
                ymm: Promise.resolve(
                    getVehicleAttributesForAnalytics(osbVehicleStep)
                ),
                osbPayload: osbDealerSearchError,
            },
            false
        );
    };
    const showCurrentLocation = () => {
        setIsUseLocationClicked(true);
        setComponentPayload({ selectedLocation: '' });
        osbDealerStep.searchLocationType = 'geolocation';
        const results: {}[] = [];
        let coordinateObject = {};
        navigator.geolocation.getCurrentPosition(
            position => {
                coordinateObject = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                GoogleMapService.getReverseGeoCoding(
                    position.coords.latitude,
                    position.coords.longitude,
                    function(data: any) {
                        if (data) {
                            fetchChosenLocation(data);
                            fetchSelectedLocation(
                                data,
                                DEALER_STEP_KEYS.LOCATION
                            );
                        }
                    }
                );
                results.push(coordinateObject);
                setLocationErrorMsgDetails('');
                setErrorMsgDealerDetails('');
            },
            error => {
                if (error.code === 1) {
                    setLocationErrorMsgDetails(
                        getObjectFromAEMJson(
                            DEALER_STEP_KEYS.PERMISSION_DENIED_LABEL,
                            dealerStepContent
                        )
                    );
                    osbDealerStep.errorDescription =
                        'user denied the request for geolocation';
                } else if (error.code === 2) {
                    setLocationErrorMsgDetails(
                        getObjectFromAEMJson(
                            DEALER_STEP_KEYS.POSITION_UNAVAILABLE_LABEL,
                            dealerStepContent
                        )
                    );
                    osbDealerStep.errorDescription =
                        'location information is unavailable';
                } else if (error.code === 3) {
                    setLocationErrorMsgDetails(
                        getObjectFromAEMJson(
                            DEALER_STEP_KEYS.TIMEOUT_LABEL,
                            dealerStepContent
                        )
                    );
                    osbDealerStep.errorDescription =
                        'the request to get user location timed out';
                } else {
                    setLocationErrorMsgDetails(
                        getObjectFromAEMJson(
                            DEALER_STEP_KEYS.UNKNOWN_LABEL,
                            dealerStepContent
                        )
                    );
                    osbDealerStep.errorDescription =
                        'an unknown error occurred';
                }
                triggerDealerSearchError();
            },
            { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 }
        );
    };

    const getDealerStepContent = async () => {
        dispatch({ type: 'REQUEST' });
        await callContentService('dealer-step-loader-error')
            .then(results => {
                setDealerStepContent(results.elements);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };

    const getDealerStepMoreContent = async () => {
        dispatch({ type: 'REQUEST' });
        await callContentService('dealer-step-search-rating')
            .then(results => {
                setDealerStepMoreContent(results.elements);
                dealerStep.searchDistanceIndex = getObjectFromAEMJson(
                    DEALER_STEP_KEYS.RADIUS_OPIONS,
                    results.elements
                ).indexOf(
                    getObjectFromAEMJson(
                        DEALER_STEP_KEYS.DEFAULT_DISTANCE,
                        results.elements
                    )
                );
                setComponentPayload({
                    dealerProfiles:
                        limitArray(
                            osbDealerStep.dealerProfiles,
                            getObjectFromAEMJson(
                                DEALER_STEP_KEYS.INITIAL_DEALERS_SHOWN,
                                results.elements
                            )
                        ) || [],
                    isDealerFilterOptionsEnabled: getObjectFromAEMJson(
                        DEALER_STEP_KEYS.ENABLE_DEALER_FILTER_OPTIONS,
                        results.elements
                    ),
                });
                setSearchResultsCount(
                    getObjectFromAEMJson(
                        DEALER_STEP_KEYS.INITIAL_DEALERS_SHOWN,
                        results.elements
                    )
                );
                setIsViewMoreButtonVisible(
                    osbDealerStep.osbEnabledDealerFromBing.length >
                        getObjectFromAEMJson(
                            DEALER_STEP_KEYS.INITIAL_DEALERS_SHOWN,
                            results.elements
                        )
                );
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };

    const goToLightDealerSearch = () => {
        setOSBStepPayload({ displayProgressBar: false });
        setProgressPercentToDisplay(
            ACTIVITY_INDICATOR_KEYS.DEFAULT_PROGRESS_PERCENT
        );
        invalidateAuthentication();
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.BACK_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        if (
            !osbStep.isAuthenticatedFlow ||
            (osbStep.isAuthenticatedFlow && isAuthenticationActive(osbStep))
        ) {
            setComponentPayload({
                dealerProfiles: [],
                selectedLocation: '',
                selectedDealerName: '',
            });

            setOSBDealerStepPayload({
                selectedLocation: '',
                selectedDealerName: '',
                dealerProfiles: [],
                filteredDealerProfiles: [],
                selectedFilterServices: [],
            });
        } else {
            if (!isAuthenticationActive(osbStep)) {
                new AuthenticationService().login();
            }
        }
    };

    const getDealerStepSpecialServicesContent = async () => {
        dispatch({ type: 'REQUEST' });
        await callContentService('dealer-special-services')
            .then(results => {
                setDealerStepSpecialServicesContent(results.elements);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };
    useEffect(() => {
        if (profile?.vehicles) {
            const newPreferredDealerIds = profile.vehicles.reduce(
                (acc: string[], item: VehicleDetail) => {
                    if (item.preferredDealer) {
                        return [
                            ...acc,
                            item.preferredDealer.substring(
                                osbStep.numberOfLeadingCharactersToStrip
                            ),
                        ];
                    }
                    return acc;
                },
                []
            );
            setPreferredDealerIds(newPreferredDealerIds);
        } else {
            setPreferredDealerIds([]);
        }
    }, [profile?.vehicles]);
    useEffect(() => {
        history.push(
            buildNavigationUrl(OsbPathDealerStep(), osbStep.UrlQueryParams)
        );
    }, [osbStep.displayProgressBar]);

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
        setRouteToPersist(
            OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
            OsbPathDealerStep(),
            osbStep.localStorageExpiry
        );
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DEALER, false, true);
        setComponentPayload(osbDealerStep);
        setNumberOfLeadingCharToStrip(osbStep.numberOfLeadingCharactersToStrip);
        getDealerStepContent();
        getDealerStepMoreContent();
        getDealerStepSpecialServicesContent();
        setAllDealersShown(osbDealerStep.osbEnabledDealerFromBing);
        GoogleMapService.loadScript('google');
    }, []);

    useEffect(() => {
        if (
            osbVehicleStep.defaultModelName !== '' &&
            !isloadAnalyticsTriggered
        ) {
            triggerDealerSearchLoadAnalytics(
                osbStep,
                osbVehicleStep,
                fireEvents
            );
            setIsloadAnalyticsTriggered(true);
        }
    }, [osbVehicleStep.defaultModelName]);

    useEffect(() => {
        if (dealerStep.dealerProfiles?.length > 0) {
            triggerDealerSearchResultsAnalytics(
                osbDealerStep,
                osbStep,
                osbVehicleStep,
                fireEvents
            );
        }
    }, [dealerStep.dealerProfiles]);
    useEffect(() => {
        const fetchPreferredDealerList = async () => {
            if (preferredDealerIds && preferredDealerIds.length > 0) {
                const resolvedList = await getDealersFromMarketDealers(
                    preferredDealerIds
                );
                setResolvedPreferredDealerList(resolvedList || []);
            }
        };
        fetchPreferredDealerList();
    }, [preferredDealerIds]);

    const showMaxDealers = () => {
        if (allDealersShown) {
            triggerBookServiceGlobalCTAOnClickAnalytics(
                LIGHT_JOURNEY_ANALYTICS.VIEW_MORE_BTN_CTA_NAME,
                fireEvents,
                osbVehicleStep
            );
            setComponentPayload({
                dealerProfiles:
                    limitArray(
                        allDealersShown,
                        getObjectFromAEMJson(
                            DEALER_STEP_KEYS.MAX_DEALERS_SHOWN,
                            dealerStepMoreContent
                        )
                    ) || [],
                filteredDealerProfiles:
                    limitArray(
                        osbDealerStep.filteredDealerProfiles,
                        getObjectFromAEMJson(
                            DEALER_STEP_KEYS.MAX_DEALERS_SHOWN,
                            dealerStepMoreContent
                        )
                    ) || [],
            });
            setIsViewMoreButtonVisible(false);
            setSearchResultsCount(
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.MAX_DEALERS_SHOWN,
                    dealerStepMoreContent
                )
            );
        }
    };

    const getDealerProfilesToDisplay = () => {
        if (
            osbDealerStep.selectedFilterServices &&
            osbDealerStep.selectedFilterServices.length > 0
        ) {
            return dealerStep.filteredDealerProfiles;
        } else {
            return dealerStep.dealerProfiles;
        }
    };

    const clearErrorMessage = () => {
        locationErrorMsgDetails && setLocationErrorMsgDetails('');
    };
    const fetchErrorCode = (errorCode: string) => {
        if (
            errorCode ===
            DEALER_STEP_KEYS.DEALER_SEARCH_INVALID_INPUT_ERROR_CODE
        ) {
            setLocationErrorMsgDetails(
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.NO_LOCATIONS_LABEL,
                    dealerStepContent
                )
            );
        }
    };

    return (
        <>
            {osbStep.enableNewLandingPage && (
                <>
                    <DealerLandingPage
                        preferredDealersProfile={resolvedPreferredDealerList}
                        dealerStepContent={dealerStepContent}
                        dealerStepMoreContent={dealerStepMoreContent}
                        dealerStepSpecialServicesContent={
                            dealerStepSpecialServicesContent
                        }
                        errorMsgDealer={errorMsgDealer}
                        errorMsgDealerDetails={errorMsgDealerDetails}
                        fetchSelectedLocation={fetchSelectedLocation}
                        selectedLocation={dealerStep.selectedLocation}
                        dealerServiceLoading={loading}
                        cfLoading={httpState.isLoading}
                        selectedDealerName={dealerStep.selectedDealerName}
                        locationErrorMsgDetails={locationErrorMsgDetails}
                        fetchChosenLocation={fetchChosenLocation}
                        showCurrentLocation={showCurrentLocation}
                        autocompleteSearchStringLength={getObjectFromAEMJson(
                            DEALER_STEP_KEYS.AUTOCOMPLETE_SEARCH_STRING_LENGTH,
                            dealerStepMoreContent
                        )}
                        clearErrorMessage={clearErrorMessage}
                        fetchErrorCode={fetchErrorCode}
                        isUseLocationLinkClicked={isUseLocationClicked}
                        triggerSearchTypeChanged={() =>
                            setIsUseLocationClicked(false)
                        }
                    />
                    {dealerStep.dealerProfiles.length > 0 &&
                        !osbStep.preferredDealerJourney && (
                            <DealerDetailsWrapper
                                selectedLocation={dealerStep.selectedLocation}
                                preferredDealerIds={preferredDealerIds}
                                dealers={getDealerProfilesToDisplay()}
                                dealerStepMoreContent={dealerStepMoreContent}
                                searchResultsCount={searchResultsCount}
                                showViewMoreButton={isViewMoreButtonVisible}
                                showMaxDealers={showMaxDealers}
                                dealerStepSpecialServicesContent={
                                    dealerStepSpecialServicesContent
                                }
                                dealerStepContent={dealerStepContent}
                                refreshDealerDetailsList={
                                    refreshDealerDetailsList
                                }
                                isDealerFilterOptionsEnabled={
                                    dealerStep.isDealerFilterOptionsEnabled
                                }
                            />
                        )}
                </>
            )}
            {!osbStep.enableNewLandingPage && (
                <div className="light-dealer-step-wrapper">
                    {dealerStep.dealerProfiles.length <= 0 && (
                        <>
                            <div>
                                {isMobileView && (
                                    <Picture
                                        largeDesktopImagePath={
                                            osbStep.backgroundImagePathLargeDesktop
                                        }
                                        desktopImagePath={
                                            osbStep.backgroundImagePathDesktop
                                        }
                                        tabletImagePath={
                                            osbStep.backgroundImagePathTablet
                                        }
                                        mobileImagePath={
                                            osbStep.backgroundImagePathMobile
                                        }
                                        imageAltText={''}
                                    />
                                )}
                                <div className="dealer-page-bck-img">
                                    <div className="light-journey-title">
                                        {parse(osbStep.serviceBookingTitle)}{' '}
                                    </div>
                                    <div className="light-dealer-flow-container-wrapper">
                                        <div className="light-dealer-flow-container">
                                            {loading && (
                                                <OsbLoader
                                                    osbLoaderMessage={getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.DEALER_LOADER_MESSAGE,
                                                        dealerStepMoreContent
                                                    )}
                                                    progressPercent={
                                                        progressPercentToDisplay
                                                    }
                                                />
                                            )}
                                            {httpState.isLoading ? (
                                                <OsbLoader />
                                            ) : (
                                                <>
                                                    <div className="dealer-search-container">
                                                        <LightDealerSearch
                                                            errorMsgDealer={
                                                                errorMsgDealer
                                                            }
                                                            errorMsgDealerDetails={
                                                                errorMsgDealerDetails
                                                            }
                                                            fetchSelectedLocation={
                                                                fetchSelectedLocation
                                                            }
                                                            selectedLocation={
                                                                dealerStep.selectedLocation
                                                            }
                                                            selectedDealerName={
                                                                dealerStep.selectedDealerName
                                                            }
                                                            locationErrorMsgDetails={
                                                                locationErrorMsgDetails
                                                            }
                                                            fetchChosenLocation={
                                                                fetchChosenLocation
                                                            }
                                                            showCurrentLocation={
                                                                showCurrentLocation
                                                            }
                                                            dealerStepContent={
                                                                dealerStepContent
                                                            }
                                                            dealerStepMoreContent={
                                                                dealerStepMoreContent
                                                            }
                                                            autocompleteSearchStringLength={getObjectFromAEMJson(
                                                                DEALER_STEP_KEYS.AUTOCOMPLETE_SEARCH_STRING_LENGTH,
                                                                dealerStepMoreContent
                                                            )}
                                                            clearErrorMessage={
                                                                clearErrorMessage
                                                            }
                                                            fetchErrorCode={
                                                                fetchErrorCode
                                                            }
                                                            isUseLocationLinkClicked={
                                                                isUseLocationClicked
                                                            }
                                                            triggerSearchTypeChanged={() =>
                                                                setIsUseLocationClicked(
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <OSBUseLocation
                                                        showCurrentLocation={
                                                            showCurrentLocation
                                                        }
                                                        useLocationLabel={getObjectFromAEMJson(
                                                            DEALER_STEP_KEYS.USE_MY_LOCATION_LABEL,
                                                            dealerStepMoreContent
                                                        )}
                                                    />
                                                    {osbStep.enableOsbHomeRetrieveBooking &&
                                                        !osbStep.isWebViewJourney && (
                                                            <>
                                                                <div className="hr-line-above-retrieve"></div>
                                                                <LightOsbHomeRetrieveBooking
                                                                    osbStep={
                                                                        osbStep
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                </>
                                            )}
                                            {!httpState.isLoading &&
                                                osbStep.fordAccountEnable &&
                                                !profile &&
                                                !osbStep.isWebViewJourney && (
                                                    <>
                                                        <div className="hr-line-below-retrieve"></div>
                                                        <FordAccount />
                                                    </>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                {!isMobileView && (
                                    <Picture
                                        largeDesktopImagePath={
                                            osbStep.backgroundImagePathLargeDesktop
                                        }
                                        desktopImagePath={
                                            osbStep.backgroundImagePathDesktop
                                        }
                                        tabletImagePath={
                                            osbStep.backgroundImagePathTablet
                                        }
                                        mobileImagePath={
                                            osbStep.backgroundImagePathMobile
                                        }
                                        imageAltText={''}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {dealerStep.dealerProfiles.length > 0 && (
                        <DealerDetailsLightJourney
                            errorMsgDealer={errorMsgDealer}
                            errorMsgDealerDetails={errorMsgDealerDetails}
                            fetchSelectedLocation={fetchSelectedLocation}
                            selectedLocation={dealerStep.selectedLocation}
                            selectedDealerName={dealerStep.selectedDealerName}
                            locationErrorMsgDetails={locationErrorMsgDetails}
                            fetchChosenLocation={fetchChosenLocation}
                            showCurrentLocation={showCurrentLocation}
                            dealerStepContent={dealerStepContent}
                            dealerStepMoreContent={dealerStepMoreContent}
                            dealerStepSpecialServicesContent={
                                dealerStepSpecialServicesContent
                            }
                            dealerProfiles={getDealerProfilesToDisplay()}
                            isViewMoreButtonVisible={isViewMoreButtonVisible}
                            showMaxDealers={showMaxDealers}
                            goToLightDealerSearch={goToLightDealerSearch}
                            searchResultsCount={searchResultsCount}
                            loading={loading}
                            autocompleteSearchStringLength={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.AUTOCOMPLETE_SEARCH_STRING_LENGTH,
                                dealerStepMoreContent
                            )}
                            refreshDealerDetailsList={refreshDealerDetailsList}
                            isDealerFilterOptionsEnabled={
                                dealerStep.isDealerFilterOptionsEnabled
                            }
                            clearErrorMessage={clearErrorMessage}
                            fetchErrorCode={fetchErrorCode}
                            progressPercentToDisplay={progressPercentToDisplay}
                            isUseLocationClicked={isUseLocationClicked}
                            triggerSearchTypeChanged={() =>
                                setIsUseLocationClicked(false)
                            }
                        />
                    )}
                </div>
            )}
        </>
    );
};
