import React from 'react';
import { PrimaryButton } from '../../../../common';
import { DSL_BASE_URL, DSL_API_PATH } from '../../../../../constants';
import { RedeemFormData } from '../redeem-rebates-steps';
import { useReviewAndSubmitContent } from '../../hooks/redeem-rebates-steps/use-review-and-submit-content';
import { useExperienceContent } from '../../../../../hooks/use-server-content';
import { RebatesDisclaimerFragment } from '../../../../../models/experiencefragments/rebates-disclaimer';
import { useAnalytics } from '../../../../../hooks/use-analytics';

import './review-and-submit.scss';

interface Props {
    redeemFormData: RedeemFormData;
    setCurrentStep(stepNumber: number): void;
}

export const ReviewAndSubmit = (props: Props) => {
    const rebatesStepName = {
        rebatesStepName: 'step5',
    };
    const [fireEvents] = useAnalytics();
    useAnalytics(['redeem-rebates-generic'], undefined, rebatesStepName);
    const modelYear = parseInt(props.redeemFormData.year || '');
    const vin = props.redeemFormData.vin;
    const DOLLAR = '$';
    const selectedDate: any = props.redeemFormData.serviceDate;
    const dateFormatUS = new Date(selectedDate).toLocaleDateString('en-US');
    const rebateReviewContent = useReviewAndSubmitContent();
    const [disclaimerContent] = useExperienceContent<RebatesDisclaimerFragment>(
        'rebates',
        'RebatesDisclaimers',
        'rebates'
    );
    let disctext = '<div>';
    const disclaimercount: any = {};
    const imageId = vin ? Buffer.from(vin).toString('base64') : '';
    const vehicleImageApiResponse =
        modelYear && modelYear >= 2013
            ? `${DSL_BASE_URL +
                  DSL_API_PATH.VEHICLE_IMAGE_V2}?imageId=${imageId}&year=${modelYear}`
            : '';
    const handleDealerEdit = () => {
        props.setCurrentStep(2);
        step5CtaAnalytics('edit');
    };
    const handleRebateEdit = () => {
        props.setCurrentStep(3);
        step5CtaAnalytics('edit');
    };
    const handleOwnerDetailsEdit = () => {
        props.setCurrentStep(4);
        step5CtaAnalytics('edit');
    };
    const handleSubmitOnReview = () => {
        props.setCurrentStep(6);
        step5CtaAnalytics('submit');
    };
    const step5CtaAnalytics = (ctaType: string) => {
        fireEvents(
            ['review-submit-cta-onclick-event'],
            undefined,
            {
                redeemSubmitCTA: ctaType,
            },
            false
        );
    };

    const getDiscText = (disclaimerContent: any, rebateoffer: any) => {
        disctext = ' ';
        disclaimerContent.categorySection.categoryList.map((disc: any) => {
            if (disc.disclaimerTitle === rebateoffer.disclaimerFile) {
                if (disclaimercount[rebateoffer.disclaimerFile] != 1) {
                    disctext = disctext.concat(disc.disclaimerDesc);
                }
                disclaimercount[rebateoffer.disclaimerFile] = 1;
                disctext = disctext.concat('</div>');
            }
        });
        return disctext;
    };
    return (
        rebateReviewContent && (
            <>
                <form className="review_rebates_form">
                    <section className="vehicle_data_row">
                        {vehicleImageApiResponse ? (
                            <div className="data_row_field_info_img">
                                <img
                                    className="vehicle-image"
                                    src={vehicleImageApiResponse}
                                    alt="Owner Vehicle image"
                                ></img>
                            </div>
                        ) : (
                            <div className="data_row_field_info_img">
                                <img
                                    className="vehicle-image"
                                    src={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        rebateReviewContent.defaultImage
                                    }
                                    alt="Owner Vehicle image"
                                ></img>
                            </div>
                        )}

                        <div className="data_row_field_info">
                            <div className="heading">
                                {rebateReviewContent.vehicleText}
                            </div>
                            <div>
                                {props.redeemFormData.year}{' '}
                                {props.redeemFormData.make}{' '}
                                {props.redeemFormData.model}
                            </div>
                        </div>
                        <div className="data_row_field_info">
                            <div className="heading">
                                {rebateReviewContent.vinText}
                            </div>
                            <div>{props.redeemFormData.vin}</div>
                        </div>
                        <div className="data_row_field_info">
                            <div className="heading">
                                {rebateReviewContent.serviceDateText}
                            </div>
                            <div>{dateFormatUS}</div>
                        </div>
                    </section>
                    <section className="dealer_data_row">
                        <div className="dealer_data_col">
                            <div className="dealer_col_heading">
                                {rebateReviewContent.dealerText}
                            </div>
                            <div className="dealer_col_info">
                                <p>
                                    {
                                        props.redeemFormData
                                            .selectedDealerDetails?.dealerName
                                    }
                                </p>
                                <p>
                                    {
                                        props.redeemFormData
                                            .selectedDealerDetails
                                            ?.streetAddress
                                    }
                                    {props.redeemFormData.selectedDealerDetails?.streetAddress.endsWith(
                                        '.'
                                    )
                                        ? ' '
                                        : '. '}
                                    {
                                        props.redeemFormData
                                            .selectedDealerDetails?.city
                                    }
                                    ,{' '}
                                    {
                                        props.redeemFormData
                                            .selectedDealerDetails?.state
                                    }{' '}
                                    {
                                        props.redeemFormData
                                            .selectedDealerDetails
                                            ?.dealerZipCode
                                    }
                                </p>
                                <p>
                                    {
                                        props.redeemFormData
                                            .selectedDealerDetails?.phone
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="edit-button">
                            <PrimaryButton
                                color="dark"
                                fill="outline"
                                chevron={false}
                                ariaLabel="edit button"
                                onClick={handleDealerEdit}
                            >
                                {rebateReviewContent.editButtonText}
                            </PrimaryButton>
                        </div>
                    </section>
                    <section className="rebate_data_row">
                        <div className="rebate-edit-button">
                            <PrimaryButton
                                color="dark"
                                fill="outline"
                                chevron={false}
                                ariaLabel="edit button"
                                onClick={handleRebateEdit}
                            >
                                {rebateReviewContent.editButtonText}
                            </PrimaryButton>
                        </div>

                        {props.redeemFormData.rebateData &&
                            props.redeemFormData.rebateData.map(
                                (rebateoffer: {
                                    key: string;
                                    value: string;
                                    pdf: string;
                                    disclaimerFile: string;
                                    rebateOfferName: string;
                                    rebatePointsOrAmount: string;
                                    isPointsClicked: boolean;
                                }) => {
                                    return (
                                        <>
                                            {rebateoffer.value != '' ? (
                                                <div>
                                                    <div className="rebate_data_col">
                                                        <div className="rebate_col_heading">
                                                            {
                                                                rebateReviewContent.rebateText
                                                            }
                                                        </div>
                                                        <div className="rebate_col_info">
                                                            <p className="rebate_group_name">
                                                                {
                                                                    rebateoffer.key
                                                                }
                                                                {'*'}
                                                            </p>
                                                            <p>
                                                                {
                                                                    rebateoffer.rebateOfferName
                                                                }
                                                            </p>
                                                            {rebateoffer.isPointsClicked ? (
                                                                <p>
                                                                    {
                                                                        rebateoffer.rebatePointsOrAmount
                                                                    }
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {DOLLAR}
                                                                    {
                                                                        rebateoffer.rebatePointsOrAmount
                                                                    }{' '}
                                                                    Rebate on
                                                                    PrePaid
                                                                    Debit Card
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {disclaimerContent && (
                                                        <small className="rebate-legal-regular">
                                                            <div
                                                                className="rebate-disclaimer-text"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: getDiscText(
                                                                        disclaimerContent,
                                                                        rebateoffer
                                                                    ),
                                                                }}
                                                            ></div>
                                                        </small>
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}{' '}
                                        </>
                                    );
                                }
                            )}
                        <div className="rebate-edit-button-mobile-view">
                            <PrimaryButton
                                color="dark"
                                fill="outline"
                                chevron={false}
                                ariaLabel="edit button"
                                onClick={handleRebateEdit}
                            >
                                {rebateReviewContent.editButtonText}
                            </PrimaryButton>
                        </div>
                    </section>
                    <section className="owner_info_data_row">
                        <div className="owner_personal_info_data">
                            <div className="owner_personal_details">
                                <div className="personal_data_row_field_info">
                                    <div className="heading">
                                        {rebateReviewContent.nameText}
                                    </div>
                                    <div>
                                        {
                                            props.redeemFormData.finalizeDetails
                                                ?.firstName
                                        }{' '}
                                        {
                                            props.redeemFormData.finalizeDetails
                                                ?.lastName
                                        }
                                    </div>
                                </div>
                                <div className="personal_data_row_field_info">
                                    <div className="heading">
                                        {rebateReviewContent.emailText}
                                    </div>
                                    <div>
                                        {
                                            props.redeemFormData.finalizeDetails
                                                ?.email
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="owner_personal_details">
                                {props.redeemFormData.showMemberId && (
                                    <div className="personal_data_row_field_info">
                                        <div className="heading">
                                            {rebateReviewContent.memberIdText}
                                        </div>
                                        <div>
                                            {
                                                props.redeemFormData
                                                    .finalizeDetails?.memberId
                                            }
                                        </div>
                                    </div>
                                )}
                                <div className="personal_data_row_field_info">
                                    <div className="heading">
                                        {
                                            rebateReviewContent.rebateMailingAddressText
                                        }
                                    </div>
                                    <p>
                                        {
                                            props.redeemFormData.finalizeDetails
                                                ?.streetAddress
                                        }
                                    </p>
                                    <p>
                                        {
                                            props.redeemFormData.finalizeDetails
                                                ?.city
                                        }
                                        ,{' '}
                                        {
                                            props.redeemFormData.finalizeDetails
                                                ?.stateDropdown
                                        }{' '}
                                        {
                                            props.redeemFormData.finalizeDetails
                                                ?.zipCode
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="owner-info-edit-button">
                            <PrimaryButton
                                color="dark"
                                fill="outline"
                                chevron={false}
                                ariaLabel="edit button"
                                onClick={handleOwnerDetailsEdit}
                            >
                                {rebateReviewContent.editButtonText}
                            </PrimaryButton>
                        </div>
                    </section>
                    <div className="submit-button">
                        <PrimaryButton
                            color="dark"
                            fill="fill"
                            chevron={true}
                            ariaLabel="submit button"
                            onClick={handleSubmitOnReview}
                        >
                            {rebateReviewContent.submitButtonText}
                        </PrimaryButton>
                    </div>
                </form>
            </>
        )
    );
};
