import {
    ASTUTE,
    BRAND_ATTRIBUTES,
    COMPONENTS_FOR_STATIC_SITEMAP,
    SUPPORT_SITEMAPS,
} from '../../support-constants';
import AstuteService from '../astute-service/astute-service';
import path from 'path';
import fs from 'fs';
import { configs } from '../../../router-configuration';
import { VehicleListServiceAem } from '../../../services/vehicle-list-service-aem/vehicle-list-service-aem';
import { findRouterConfig } from '../../../components/utils/router-util/router-util';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { CategoryServiceAem } from '../../../services/category-service-aem/category-service-aem';
import {
    routesValues,
    catagoryNameForURL,
} from '../../../components/sections/coupons/constants/route-constants';
import { CouponApiData } from '../../../components/sections/coupons/hooks/use-coupon-data';
import { displayOfferUrl } from '../../../components/sections/coupons/components/display-coupons/display-offer-url';
import PopularVideoService from '../../../views/how-to-video-view/service/popular-video-service';
import { formatModelName } from '../../../services/content-service/content-service-util';
import { CacheService } from '../../../services/cache-service/cache-service';

const pathToSitemaps = `./${SUPPORT_SITEMAPS}`;
interface MarketDetails {
    environment: string;
    region: string;
    countryCode: string;
    threeLetterCountryCode: string;
    domain: string;
    root: string;
    languageRegionCode: string;
    brand: string;
}

interface SitemapVehicle {
    year: number;
    make: string;
    model: string;
    marketName: string;
}

export class SiteMapService {
    private _cacheService = new CacheService();
    private _popularVideoService = new PopularVideoService();

    public getBaseUrl(supportedLang: string, brand: string) {
        const marketDetails = this.getMarketDetails().find(
            market =>
                market.languageRegionCode === supportedLang &&
                market.brand === brand
        );
        const root = (marketDetails && marketDetails.root) || '';
        const domain = (marketDetails && marketDetails.domain) || '';
        return {
            domain,
            root,
        };
    }
    public generateUrlsForAnyPage(supportedLang: string, brand: string) {
        const staticUrls: string[] = [];
        if (COMPONENTS_FOR_STATIC_SITEMAP.length > 0) {
            const supportStaticUrls = COMPONENTS_FOR_STATIC_SITEMAP.map(
                page => {
                    if (
                        page !== 'AstuteSiteMap' ||
                        ASTUTE.SUPPORTED_MARKETS.includes(supportedLang)
                    ) {
                        const url = this.getPathFromRouterConfigs(
                            supportedLang,
                            brand,
                            page,
                            []
                        );
                        return (
                            url &&
                            url.split(',').filter(u => !u.endsWith('.html'))
                        );
                    }
                }
            ).flat();
            supportStaticUrls?.forEach(path => {
                path?.includes(',')
                    ? path.split(',').forEach((p: string) => staticUrls.push(p))
                    : path && staticUrls.push(path);
            });
        }
        return staticUrls;
    }
    public getPathFromRouterConfigs(
        supportedLang: string,
        brand: string,
        componentNm: string,
        params: Array<string>
    ): string {
        const currentAppConfiguration = this.getMarketDetails().find(
            market =>
                market.languageRegionCode === supportedLang &&
                market.brand === brand
        );

        if (currentAppConfiguration) {
            const overriddenComponentRegister = findRouterConfig(
                configs,
                currentAppConfiguration?.brand,
                currentAppConfiguration?.languageRegionCode
            );

            const matchingRoute: any = overriddenComponentRegister?.routes.find(
                ({ componentName }) => componentNm === componentName
            );
            if (componentNm === 'DyfLandingView') {
                return params
                    .reduce((acc, param) => {
                        return Array.isArray(acc)
                            ? acc[0].replace(/:\w+/, param)
                            : acc?.replace(/:\w+/, param);
                    }, matchingRoute && matchingRoute['path'][1])
                    ?.toString();
            }

            return params
                .reduce((acc, param) => {
                    return Array.isArray(acc)
                        ? acc[0].replace(/:\w+/, param)
                        : acc?.replace(/:\w+/, param);
                }, matchingRoute && matchingRoute['path'])
                ?.toString();
        }
        return '';
    }
    public getMarketDetails() {
        const mapMarkets: MarketDetails[] = [];
        new AppConfigurationService('prod').appConfigurations
            .filter(market => market.domain)
            .sort((ford, lincoln) =>
                ford.brand
                    .toLowerCase()
                    .localeCompare(lincoln.brand.toLowerCase())
            )
            .forEach(country => {
                mapMarkets.push({
                    environment: country.environment,
                    region: country.fmaRegion,
                    countryCode: country.countryCode,

                    threeLetterCountryCode: country.threeLetterCountryCode,
                    domain: country.domain.startsWith('http')
                        ? country.domain
                        : 'https://' + country.domain,
                    root: country.root.substring(0, country.root.length - 1),
                    languageRegionCode: country.languageRegionCode,
                    brand: country.brand,
                });
            });

        return mapMarkets;
    }
    public async generateUrlsForSiteMap(supportedLang: string, brand: string) {
        if (!ASTUTE.SUPPORTED_MARKETS.includes(supportedLang)) return null;
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        const siteMapData = await new AstuteService().getSitemapDataFromCache(
            supportedLang,
            sitemapTag,
            brand
        );

        return siteMapData.map(topic =>
            this.getPathFromRouterConfigs(
                supportedLang,
                brand,
                'AstuteTopic',
                topic.topicAbsoluteSeo?.substring(1).split('/') || []
            )?.concat(topic.topicAbsoluteSeo?.endsWith('/') ? '/' : '')
        );
    }
    public generateUrlsForOwnerManualsSiteMap(
        filteredVehicles: any[],
        supportedLang: string,
        brand: string
    ) {
        return filteredVehicles.map(vehicle =>
            this.getPathFromRouterConfigs(
                supportedLang,
                brand,
                'OwnerManualDetailsPage',
                [vehicle.model, vehicle.year]
            )?.replace('+', '')
        );
    }
    public generateUrlsForDyfFeatureGuide(
        filteredVehicles: any[],
        supportedLang: string,
        brand: string
    ) {
        if (!ASTUTE.SUPPORTED_MARKETS_WITH_ENDING_SLASH.includes(supportedLang))
            return null;
        return filteredVehicles
            .filter(
                vehicle =>
                    (vehicle.year >= 2013 && supportedLang == 'en-us') ||
                    (vehicle.year >= 2016 &&
                        (supportedLang == 'en-ca' || supportedLang == 'fr-ca'))
            )
            .map(vehicle =>
                this.getPathFromRouterConfigs(
                    supportedLang,
                    brand,
                    'DyfLandingView',
                    [vehicle.model, vehicle.year]
                )?.replace('+', '')
            );
    }
    public async generateUrlsForSiteMapHtml(
        root: string,
        supportedMarket: string,
        brand: string
    ) {
        if (!ASTUTE.SUPPORTED_MARKETS.includes(supportedMarket)) return null;
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        const siteMapData = await new AstuteService().getSitemapDataFromCache(
            supportedMarket,
            sitemapTag,
            brand
        );
        const currentRoot = root.substring(0, root.length - 1);
        return {
            sitemapHtmlLinks: siteMapData
                .map(topic => {
                    return `<a href="${currentRoot}${this.getPathFromRouterConfigs(
                        supportedMarket,
                        brand,
                        'AstuteTopic',
                        topic.topicAbsoluteSeo?.substring(1).split('/') || []
                    )?.concat(
                        topic.topicAbsoluteSeo?.endsWith('/') ? '/' : ''
                    )}">${topic.topicLabel}</a>`;
                })
                .join('<br>'),
        };
    }
    private getAllUrlsFromHtvResponse(
        supportedLang: string,
        brand: string,
        vehicle: SitemapVehicle,
        categoryVideos: { [x: string]: any }
    ): string[] {
        const urlPaths: string[] = [];
        const mainPath = this.getPathFromRouterConfigs(
            supportedLang,
            brand,
            'HTVVideoLibraryView',
            [vehicle.model, vehicle.year.toString()]
        )?.replace('+', '');

        urlPaths.push(mainPath);

        for (const categoryName in categoryVideos) {
            const catNamePath = this.getPathFromRouterConfigs(
                supportedLang,
                brand,
                'HTVCategoryLibrary',
                [
                    vehicle.model,
                    vehicle.year.toString(),
                    formatModelName(categoryName),
                ]
            )?.replace('+', '');

            urlPaths.push(catNamePath);

            categoryVideos[categoryName as string].forEach((videoData: any) => {
                let videoUrl = this.getPathFromRouterConfigs(
                    supportedLang,
                    brand,
                    'HowToViewVideo',
                    [
                        vehicle.model,
                        vehicle.year.toString(),
                        categoryName
                            ?.toLowerCase()
                            .replace(/[^a-zA-Z0-9- ]/g, '')
                            .trim()
                            .split(' ')
                            .join('-'),
                        videoData.id,
                    ]
                );

                videoUrl =
                    (videoUrl?.endsWith('/')
                        ? videoUrl?.slice(0, -1)
                        : videoUrl) +
                    `?name=${videoData.name
                        ?.toLowerCase()
                        .replace(/[^a-zA-Z0-9- ]/g, '')
                        .trim()
                        .split(' ')
                        .join('-')}`;

                urlPaths.push(videoUrl);
            });
        }
        return urlPaths;
    }
    public async generateUrlsForHowToVideosCategory(
        filteredVehicles: SitemapVehicle[],
        supportedLang: string,
        brand: string
    ): Promise<string[]> {
        const CACHE_KEY = `htv-sitemap-${brand}-${supportedLang}`;
        const CACHE_TIME = 60 * 60 * 24 * 7; // 1 week

        const filteredVehiclesWithYear = filteredVehicles.filter(
            vehicle =>
                vehicle.year >= 2013 &&
                ASTUTE.SUPPORTED_DYF_MARKETS.includes(supportedLang)
        );

        if (filteredVehiclesWithYear.length == 0) {
            return [];
        }
        console.log(
            `getting ${filteredVehiclesWithYear.length} vehicles from ${supportedLang} region and ${brand} brand`
        );

        let urlList: string[] = [];

        const cachedValue =
            (await this._cacheService.getFromRedis(CACHE_KEY)) || null;

        if (cachedValue) {
            console.log(`cache hit htv: ${CACHE_KEY}`, cachedValue?.length);
            urlList = cachedValue;
        } else {
            await Promise.all(
                filteredVehiclesWithYear.map(vehicle => {
                    return this._popularVideoService
                        .getVideosByCategories(
                            vehicle.year.toString(),
                            brand,
                            vehicle.marketName,
                            supportedLang
                        )
                        .then(categoryVideos => {
                            if (
                                categoryVideos &&
                                Object.keys(categoryVideos).length > 0
                            ) {
                                const allUrls = this.getAllUrlsFromHtvResponse(
                                    supportedLang,
                                    brand,
                                    vehicle,
                                    categoryVideos
                                );
                                urlList = urlList.concat(allUrls);
                            }
                        })
                        .catch(err => console.error('htv sitemap error: ', err))
                        .finally(() => Promise.resolve());
                })
            );

            this._cacheService.putInRedis(CACHE_KEY, urlList, CACHE_TIME);
        }

        return urlList;
    }
    public async getVehiclesByLanguage(
        languageRegionCode: string,
        currentRegion: string,
        brand: string
    ): Promise<SitemapVehicle[]> {
        const vehicles: SitemapVehicle[] = [];
        const vehicleList = await new VehicleListServiceAem().getVehicles(
            brand,
            currentRegion,
            languageRegionCode
        );

        vehicleList.forEach(vehiclesByYear => {
            vehiclesByYear.vehicles.forEach(vehicle => {
                const {
                    year,
                    make,
                    seoKey: model,
                    marketName: marketName,
                } = vehicle;
                vehicles.push({ year, make, model, marketName });
            });
        });
        return vehicles;
    }
    public async getCatSubcatPageUrls(
        languageRegionCode: string,
        currentRegion: string,
        brand: string
    ): Promise<
        {
            path: string;
            title: string;
        }[]
    > {
        const path = this.getPathFromRouterConfigs(
            languageRegionCode,
            brand,
            'CategoryHandler',
            []
        );
        let categoryList = [];
        try {
            categoryList = await new CategoryServiceAem().getCatSubcatPageUrls(
                brand,
                currentRegion,
                languageRegionCode
            );
        } catch (e) {
            console.error(e);
            return [];
        }
        return path
            ? categoryList.map(categoryInList => {
                  return {
                      path: path.replace('/*', categoryInList.path),
                      title: categoryInList.title,
                  };
              })
            : [];
    }
    public async generateSitemap() {
        await Promise.all(
            this.getMarketDetails().map(async (market: MarketDetails) => {
                const staticUrls = this.generateUrlsForAnyPage(
                    market.languageRegionCode,
                    market.brand
                );

                const vehicles: SitemapVehicle[] = await this.getVehiclesByLanguage(
                    market.languageRegionCode,
                    market.countryCode,
                    market.brand
                );

                const filteredVehicles = vehicles.filter(
                    vehicle =>
                        vehicle.make.toLowerCase() ===
                        market.brand.toLowerCase()
                );
                let paths = filteredVehicles.map(vehicle =>
                    this.getPathFromRouterConfigs(
                        market.languageRegionCode,
                        market.brand,
                        'VehicleYmmView',
                        [vehicle.model, vehicle.year.toString()]
                    )
                );

                const categoryUrls = await this.getCatSubcatPageUrls(
                    market.languageRegionCode,
                    market.countryCode,
                    market.brand
                );

                paths = this.getPathValue(categoryUrls, paths);
                const pathsWithSlash = this.getPathWithSlash(market, paths);
                const supportArticlesUrls = await this.generateUrlsForSiteMap(
                    market.languageRegionCode,
                    market.brand
                );

                let supportDynamicUrls: any;
                supportDynamicUrls = this.getSupportDynamicUrlFromSupportArticlesUrl(
                    supportArticlesUrls
                );
                const ownerManualsUrls = this.generateUrlsForOwnerManualsSiteMap(
                    filteredVehicles,
                    market.languageRegionCode,
                    market.brand
                );

                supportDynamicUrls = this.getSupportDynamicUrlsFromOwnerManualUrl(
                    ownerManualsUrls,
                    supportDynamicUrls
                );
                const dyfFeatureGuideUrls = this.generateUrlsForDyfFeatureGuide(
                    filteredVehicles,
                    market.languageRegionCode,
                    market.brand
                );

                supportDynamicUrls = this.getSupportDynamicUrlFromDyfFeatureGuideUrls(
                    dyfFeatureGuideUrls,
                    supportDynamicUrls
                );
                const htvVideosCategoryUrls = await this.generateUrlsForHowToVideosCategory(
                    filteredVehicles,
                    market.languageRegionCode,
                    market.brand
                );
                supportDynamicUrls = this.getSupportDynamicUrlFromHtvVideoCategoryUrl(
                    htvVideosCategoryUrls,
                    supportDynamicUrls
                );

                supportDynamicUrls =
                    staticUrls.length > 0
                        ? supportDynamicUrls?.concat(staticUrls)
                        : supportDynamicUrls;

                const supportSitemapPaths =
                    supportDynamicUrls && supportDynamicUrls.length > 0
                        ? pathsWithSlash.concat(supportDynamicUrls)
                        : pathsWithSlash;

                const currentDate = `<!--Created ${new Date().toISOString()}-->`;
                let dataStream = `${currentDate}<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`;
                supportSitemapPaths.forEach((url: string) => {
                    dataStream = dataStream
                        .concat('<url>')
                        .concat('<loc>')
                        .concat(market.domain)
                        .concat(market.root)
                        .concat(url?.replace('&', '&amp;'))
                        .concat('</loc>')
                        .concat('</url>');
                });
                dataStream = dataStream.concat('</urlset>');
                fs.writeFile(
                    path.resolve(
                        pathToSitemaps,
                        `sitemap-${market.brand}_${market.languageRegionCode}.xml`
                    ),
                    dataStream,
                    err => {
                        if (err) {
                            console.error(err.message);
                        }
                    }
                );
            })
        );
    }

    private getSupportDynamicUrlFromHtvVideoCategoryUrl(
        htvVideosCategoryUrls: string[],
        supportDynamicUrls: (string | ConcatArray<string> | undefined)[]
    ) {
        if (htvVideosCategoryUrls && htvVideosCategoryUrls.length > 0) {
            for (const categoryUrl of htvVideosCategoryUrls) {
                supportDynamicUrls =
                    categoryUrl && categoryUrl.length > 0
                        ? supportDynamicUrls.concat(categoryUrl)
                        : supportDynamicUrls;
            }
        }
        return supportDynamicUrls;
    }

    private getSupportDynamicUrlFromDyfFeatureGuideUrls(
        dyfFeatureGuideUrls: null | (string | undefined)[],
        supportDynamicUrls: (string | undefined)[]
    ) {
        return dyfFeatureGuideUrls &&
            dyfFeatureGuideUrls.length > 0 &&
            supportDynamicUrls
            ? supportDynamicUrls.concat(dyfFeatureGuideUrls)
            : supportDynamicUrls;
    }

    private getSupportDynamicUrlsFromOwnerManualUrl(
        ownerManualsUrls: (string | undefined)[],
        supportDynamicUrls: (string | undefined)[]
    ) {
        return ownerManualsUrls &&
            ownerManualsUrls.length > 0 &&
            supportDynamicUrls
            ? supportDynamicUrls.concat(ownerManualsUrls)
            : supportDynamicUrls;
    }

    private getSupportDynamicUrlFromSupportArticlesUrl(
        supportArticlesUrls: null | (string | undefined)[]
    ) {
        return supportArticlesUrls && supportArticlesUrls.length > 0
            ? supportArticlesUrls
            : [];
    }

    private getPathWithSlash(market: MarketDetails, paths: string[]) {
        return ASTUTE.SUPPORTED_MARKETS_WITH_ENDING_SLASH.includes(
            market.languageRegionCode
        )
            ? paths.map((path: string) => path?.concat('/'))
            : paths;
    }

    private getPathValue(
        categoryUrls: { path: string; title: string }[],
        paths: string[]
    ) {
        return categoryUrls && categoryUrls.length > 0
            ? paths.concat(categoryUrls.map(category => category.path))
            : paths;
    }

    public async generateGenericHtmlSitemap(
        supportedMarket: string,
        brand: string
    ) {
        const market = this.getMarketDetails().find(market =>
            this.checkForBrandAndLanguageRegionCode(
                market,
                supportedMarket,
                brand
            )
        );
        let staticUrls = this.generateUrlsForAnyPage(supportedMarket, brand);

        const vehicles = await this.getVehiclesByLanguage(
            supportedMarket,
            market?.countryCode || '',
            brand
        );
        const filteredVehicles = vehicles.filter(
            vehicle => vehicle.make.toLowerCase() === brand.toLowerCase()
        );
        const pathsWithSlash = ASTUTE.SUPPORTED_MARKETS_WITH_ENDING_SLASH.includes(
            supportedMarket
        );
        const paths = filteredVehicles.map(vehicle => {
            let absoluteUrl = `${market?.domain}${
                market?.root
            }${this.getPathFromRouterConfigs(
                supportedMarket,
                brand,
                'VehicleYmmView',
                [vehicle.model, vehicle.year.toString()]
            )}`;
            absoluteUrl = this.getAbsoluteUrl(pathsWithSlash, absoluteUrl);
            return `<a href="${absoluteUrl}">${vehicle.year} ${brand
                .charAt(0)
                .toUpperCase() + brand.slice(1)} ${vehicle.marketName}</a>`;
        });

        const htvVideosCategoryUrl = await this.generateUrlsForHowToVideosCategory(
            filteredVehicles,
            supportedMarket,
            brand
        );

        staticUrls = this.getStaticUrlFromHtvVideosCategoryUrl(
            htvVideosCategoryUrl,
            staticUrls
        );

        const dyfFeatureGuideUrls = this.generateUrlsForDyfFeatureGuide(
            filteredVehicles,
            supportedMarket,
            brand
        );
        staticUrls = this.getStaticUrlFromDyfFeatureGuideUrl(
            dyfFeatureGuideUrls,
            staticUrls
        );

        const categoryUrls = await this.getCatSubcatPageUrls(
            supportedMarket,
            market?.countryCode || '',
            brand
        );

        const categoryPaths = categoryUrls.map(category => {
            let absoluteUrl = `${market?.domain}${market?.root}${category.path}`;
            absoluteUrl = this.getAbsoluteUrl(pathsWithSlash, absoluteUrl);
            return `<a href="${absoluteUrl}">${category.title}</a>`;
        });

        const staticAbsoluteUrls = staticUrls.map((url: string) => {
            const absoluteUrl = `${market?.domain}${market?.root}${url}`;
            return `<a href="${absoluteUrl}">${absoluteUrl}</a>`;
        });

        const pathsWithCoupons = ASTUTE.SUPPORTED_MARKETS_FOR_COUPONS.includes(
            supportedMarket
        );

        const generateUrlsforCouponsCategory = routesValues.map(function(
            element
        ) {
            let absoluteUrl = `${market?.domain}${market?.root}${element.path}`;
            absoluteUrl = SiteMapService.getAbsoluteUrlFromPathsWithCoupons(
                pathsWithCoupons,
                absoluteUrl
            );
            return `<a href="${absoluteUrl}">${absoluteUrl}</a><br>`;
        });

        const setDatetoString = () => {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            const todaysDateplusOne =
                (date.getMonth() > 8
                    ? date.getMonth() + 1
                    : '0' + (date.getMonth() + 1)) +
                '/' +
                (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
                '/' +
                date.getFullYear();
            return todaysDateplusOne;
        };

        const requestCouponData = await CouponApiData(brand);
        let subcategory: string;
        let title: any;
        const generateUrlsforIndividualCouponsCategory = requestCouponData.coupons.map(
            function(element) {
                const commodityDescriptionElement = element.commodityDescription.split(
                    ':'
                )[1];
                subcategory = commodityDescriptionElement;
                const categoryURL = catagoryNameForURL(subcategory, title);

                if (element.expiryDate > setDatetoString()) {
                    const couponIndividualUrls = displayOfferUrl(
                        element.couponId,
                        element.offer,
                        categoryURL
                    );

                    let absoluteUrl = couponIndividualUrls;
                    absoluteUrl = SiteMapService.getAbsoluteUrlFromPathsWithCoupons(
                        pathsWithCoupons,
                        absoluteUrl
                    );
                    return `<a href="${market?.domain}${market?.root}${absoluteUrl}">${market?.domain}${market?.root}${absoluteUrl}</a><br>`;
                }
            }
        );

        return {
            sitemapHtmlLinks:
                paths.concat(staticAbsoluteUrls).join('<br>') +
                '<br>' +
                generateUrlsforCouponsCategory.join('') +
                generateUrlsforIndividualCouponsCategory.join('') +
                categoryPaths.concat(staticAbsoluteUrls).join('<br>'),
        };
    }

    private static getAbsoluteUrlFromPathsWithCoupons(
        pathsWithCoupons: boolean,
        absoluteUrl: string
    ) {
        return pathsWithCoupons ? absoluteUrl.concat('') : '';
    }

    private getStaticUrlFromDyfFeatureGuideUrl(
        dyfFeatureGuideUrls: any,
        staticUrls: string[]
    ) {
        return dyfFeatureGuideUrls && dyfFeatureGuideUrls.length > 0
            ? staticUrls.concat(dyfFeatureGuideUrls)
            : staticUrls;
    }

    private getStaticUrlFromHtvVideosCategoryUrl(
        htvVideosCategoryUrl: string[],
        staticUrls: string[]
    ) {
        if (htvVideosCategoryUrl && htvVideosCategoryUrl.length > 0) {
            for (const categoryUrl of htvVideosCategoryUrl) {
                staticUrls =
                    categoryUrl && categoryUrl.length > 0
                        ? staticUrls.concat(categoryUrl)
                        : staticUrls;
            }
        }
        return staticUrls;
    }

    private checkForBrandAndLanguageRegionCode(
        market: MarketDetails,
        supportedMarket: string,
        brand: string
    ) {
        return (
            market.languageRegionCode === supportedMarket &&
            market.brand === brand
        );
    }

    private getAbsoluteUrl(pathsWithSlash: boolean, absoluteUrl: string) {
        return pathsWithSlash ? absoluteUrl.concat('/') : absoluteUrl;
    }
}

const siteMapService = new SiteMapService();
export default siteMapService;
