import {
    SyncMapsCard,
    SyncMapsConsolidatedResponse,
    SyncMapStatus,
} from '../use-syncs-maps-status-updates';

export const getNaMapAccordionContent = (
    syncGeneration: string,
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined,
    mapCards: SyncMapsCard[] | undefined,
    syncMapContent: SyncMapStatus | null,
    isSync4: boolean,
    isEU: boolean
) => {
    return {
        syncGeneration,
        hasSync:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.hasSync,
        cards: mapCards || [],
        heading: syncMapContent?.mapsHeading,
        title: isSync4
            ? syncMapContent?.mapsAutomaticUpdates
            : syncMapContent?.mapsUpdateAvailable,
        subtitle: syncMapContent?.mapsUpdateAvailableDescription,
        upToDate: syncMapContent?.mapsIsUpToDate,
        radioButtons: isEU,
        hasUpdate: true,
        notAvailable: syncMapContent?.notAvailableLabel,
        accordionType: 'maps',
        syncMapContent: syncMapContent,
    };
};
