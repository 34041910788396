import React, { useEffect, useRef, useState } from 'react';
import useUserProfile from '../../../../../hooks/use-user-profile';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';
import { Link, PrimaryButton, SecondaryButton } from '../../../../common';
import stateOptions from '../../../../common/states/state';
import { RedeemFormData } from '../redeem-rebates-steps';
import { useFinalizeDetailsContent } from '../../hooks/redeem-rebates-steps/use-finalize-details-content';
import { useAnalytics } from '../../../../../hooks/use-analytics';

import './finalize-details.scss';

interface Props {
    setCurrentStep(stepNumber: number): void;
    setFormData(data: RedeemFormData): void;
    redeemFormData: RedeemFormData;
}

export const FinalizeDetails = (props: Props) => {
    const TABLET_BREAKPOINT = 768;
    const profile = useUserProfile();
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const [fireEvents] = useAnalytics();
    const rebatesStepName = {
        rebatesStepName: 'finalize details',
    };
    let memberId = '';
    let email = '';
    useAnalytics(['redeem-rebates-generic'], undefined, rebatesStepName);

    const memberIdAnalytics = () => {
        fireEvents(
            'rr-finalize-details-cta-onclick-event',
            undefined,
            { ctaType: 'find member id' },
            false
        );
    };
    const downloadAnalytics = (ctaTypeParameter: any) => {
        fireEvents(
            'rr-finalize-details-cta-onclick-event',
            undefined,
            { ctaType: ctaTypeParameter },
            false
        );
    };
    const [buttonState, setButtonState] = useState('');

    const [mailRebates, setMailRebates] = useState(true);

    const [size, setSize] = useState(window.innerWidth);

    const isPointsClicked: boolean[] = [];

    const isRebateOfferName: any[] = [];

    props.redeemFormData.rebateData?.map(detail => {
        isPointsClicked.push(detail.isPointsClicked);
        isRebateOfferName.push(detail.rebateOfferName);
    });

    const [country, setCountry] = useState({
        currentCountryCode: '',
        currentLanguageRegionCode: '',
        brand: '',
    });

    const finalizeDetailsContent = useFinalizeDetailsContent();

    useEffect(() => {
        const {
            currentCountryCode,
            currentLanguageRegionCode,
            brand,
        } = new AppConfigurationService();
        setCountry({
            ...country,
            currentCountryCode,
            currentLanguageRegionCode,
            brand,
        });
    }, []);

    if (profile?.profile?.memberId) {
        memberId = profile.profile.memberId.toString();
    } else if (isPointsClicked.includes(true)) {
        memberId = props.redeemFormData.retainedInputMemberId;
    }

    if (profile?.profile?.email) {
        email = profile.profile.email;
    } else if (props.redeemFormData.finalizeDetails?.email) {
        email = props.redeemFormData.finalizeDetails.email;
    }
    const [finalizeDetails, setfinalizeDetails] = useState({
        memberId: memberId,
        firstName: profile
            ? profile.profile.firstName
            : props.redeemFormData.finalizeDetails?.firstName,
        lastName: profile
            ? profile.profile.lastName
            : props.redeemFormData.finalizeDetails?.lastName,
        streetAddress: profile
            ? profile.profile.address1
            : props.redeemFormData.finalizeDetails?.streetAddress,
        city: profile
            ? profile.profile.city
            : props.redeemFormData.finalizeDetails?.city,
        stateDropdown: profile
            ? profile.profile.state
            : props.redeemFormData.finalizeDetails?.stateDropdown,
        zipCode: profile
            ? profile.profile.zip
            : props.redeemFormData.finalizeDetails?.zipCode,
        email: email,
        confirmEmail: '',
        isEmailCommunications:
            props.redeemFormData.finalizeDetails?.isEmailCommunications ||
            false,
    });

    const [isTouched, setTouched] = useState({
        memberId: false,
        firstName: false,
        lastName: false,
        streetAddress: false,
        city: false,
        stateDropdown: false,
        zipCode: false,
        email: false,
        confirmEmail: false,
        isEmailCommunications: false,
    });

    useEffect(() => {
        if (profile && props.redeemFormData.finalizeDetails?.firstName !== '') {
            setfinalizeDetails({
                memberId: props.redeemFormData.finalizeDetails?.memberId,
                firstName: props.redeemFormData.finalizeDetails?.firstName,
                lastName: props.redeemFormData.finalizeDetails?.lastName,
                streetAddress:
                    props.redeemFormData.finalizeDetails?.streetAddress,
                city: props.redeemFormData.finalizeDetails?.city,
                stateDropdown:
                    props.redeemFormData.finalizeDetails?.stateDropdown,
                zipCode: props.redeemFormData.finalizeDetails?.zipCode,
                email: props.redeemFormData.finalizeDetails?.email || '',
                confirmEmail: '',
                isEmailCommunications:
                    props.redeemFormData.finalizeDetails
                        ?.isEmailCommunications || false,
            });
        }
        const handleResize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleInputChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setfinalizeDetails({
            ...finalizeDetails,
            [name]: value,
        });
    };

    const finalizeDetailsInputClickAnalytics = () => {
        fireEvents(
            ['finalize-details-input-onclick-event'],
            'finalize-details-input-onclick-event',
            undefined,
            false
        );
    };

    const onTouch = (event: any) => {
        finalizeDetailsInputClickAnalytics();
        const name = event.target.name;
        setTouched({
            ...isTouched,
            [name]: true,
        });
    };

    const validate = () => {
        const errors = {
            memberId: '',
            firstName: '',
            lastName: '',
            streetAddress: '',
            city: '',
            stateDropdown: '',
            zipCode: '',
            email: '',
            confirmEmail: '',
            selectState: '',
            isValid: false,
            success: [''],
        };

        if (
            !profile &&
            (!finalizeDetails.memberId ||
                !/^\d{10}$/i.test(finalizeDetails.memberId.toString()))
        ) {
            errors.memberId = finalizeDetailsContent.memberIdErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[0]);
        }

        if (!finalizeDetails.firstName) {
            errors.firstName = finalizeDetailsContent.firstNameErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[1]);
        }

        if (!finalizeDetails.lastName) {
            errors.lastName = finalizeDetailsContent.lastNameErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[2]);
        }

        if (!finalizeDetails.streetAddress) {
            errors.streetAddress =
                finalizeDetailsContent.streetAddressErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[3]);
        }

        if (!finalizeDetails.city) {
            errors.city = finalizeDetailsContent.cityErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[4]);
        }

        if (finalizeDetails.stateDropdown === 'State') {
            errors.stateDropdown = finalizeDetailsContent.stateErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[5]);
        }

        if (
            !finalizeDetails.zipCode ||
            !/^\d{5}$/i.test(finalizeDetails.zipCode)
        ) {
            errors.zipCode = finalizeDetailsContent.zipCodeErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[6]);
        }

        if (
            !finalizeDetails.email ||
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                finalizeDetails.email
            )
        ) {
            errors.email = finalizeDetailsContent.emailErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[7]);
        }

        if (
            !finalizeDetails.confirmEmail ||
            finalizeDetails.email.toLowerCase() !=
                finalizeDetails.confirmEmail.toLowerCase()
        ) {
            errors.confirmEmail =
                finalizeDetailsContent.reEnterEmailErrorMessage;
        } else {
            errors.success.push(Object.keys(errors)[8]);
        }

        if (profile || isPointsClicked.includes(true)) {
            if (errors.success.length === 10) {
                errors.isValid = true;
            }
        } else {
            if (errors.success.length === 9) {
                errors.isValid = true;
            }
        }
        return errors;
    };

    const continueCTAClickAnalytics = () => {
        //Continue CTA click analytics
        fireEvents(
            ['finalize-details-continue-onclick-event'],
            'finalize-details-continue-onclick-event',
            undefined,
            false
        );
    };

    const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        let inputMemberId: any = '';
        event.preventDefault();
        if (buttonState === 'submit') {
            continueCTAClickAnalytics();
            if (
                !profile &&
                finalizeDetails.memberId &&
                isPointsClicked.includes(true)
            ) {
                inputMemberId = finalizeDetails.memberId;
            } else {
                inputMemberId = props.redeemFormData.retainedInputMemberId;
            }
            props.setFormData({
                ...props.redeemFormData,
                finalizeDetails: {
                    ...finalizeDetails,
                },
                retainedInputMemberId: inputMemberId,
                showMemberId: isPointsClicked.includes(true),
            });
            props.setCurrentStep(5);
        }
    };

    return (
        <form className="form-finalize" onSubmit={handelSubmit}>
            <div className="finalize-download">
                <p className="finalize-form-text">
                    {finalizeDetailsContent.finalizeDetailsDescription}
                </p>
                {mailRebates ? (
                    <p className="finalize-download-text-link">
                        {finalizeDetailsContent.wantToMailText}{' '}
                        <a
                            className="finalize-download-link"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setMailRebates(false);
                                downloadAnalytics('download rebates forms');
                            }}
                        >
                            {finalizeDetailsContent.downloadLinkText}
                        </a>
                    </p>
                ) : (
                    <>
                        <div className="finalize-Mail-Rebate-Forms">
                            <h3 className="finalize-download-header">
                                {finalizeDetailsContent.mailInTitle}
                            </h3>
                            <p className="finalize-download-text">
                                {finalizeDetailsContent.mailInDescription}
                            </p>
                            {props.redeemFormData.rebateData?.map(
                                (detail, index) => {
                                    if (detail.rebateOfferName) {
                                        return (
                                            <>
                                                <div
                                                    className="finalize-download-form"
                                                    key={detail.key}
                                                >
                                                    <p>
                                                        <strong>
                                                            {detail.key}
                                                        </strong>
                                                    </p>
                                                    <Link
                                                        aria-label={
                                                            detail.key +
                                                            '' +
                                                            finalizeDetailsContent.mailInDownloadLinkText
                                                        }
                                                        className="finalize-form-link"
                                                        onClick={() => {
                                                            downloadAnalytics(
                                                                'download mail-in form'
                                                            );
                                                        }}
                                                        rel="noopener noreferrer"
                                                        href={
                                                            process.env
                                                                .REACT_APP_AEM_BASE_URL +
                                                            `/content/dam/global-owner/${country.brand}/${country.currentCountryCode}/${country.currentLanguageRegionCode}/documents/redeem-rebates/${detail.pdf}`
                                                        }
                                                        target="_blank"
                                                    >
                                                        {
                                                            finalizeDetailsContent.mailInDownloadLinkText
                                                        }
                                                    </Link>
                                                </div>
                                                {!isRebateOfferName.includes(
                                                    ''
                                                ) && (
                                                    <hr
                                                        className={`line ${
                                                            props.redeemFormData
                                                                .rebateData &&
                                                            props.redeemFormData
                                                                .rebateData
                                                                .length -
                                                                1 ==
                                                                index
                                                                ? 'hide'
                                                                : ''
                                                        }`}
                                                    />
                                                )}
                                            </>
                                        );
                                    }
                                }
                            )}
                        </div>
                    </>
                )}
            </div>
            {isPointsClicked.includes(true) && (
                <>
                    <hr />
                    <div className="finalize-member-rebates-row">
                        {profile ? (
                            <div className="finalize-signin-memberid">
                                <p>
                                    <span className="finalize-signin-memberid-text">
                                        {
                                            finalizeDetailsContent.memberIdPlaceholderText
                                        }
                                        :
                                    </span>{' '}
                                    <span className="finalize-signin-memberid-id">
                                        {finalizeDetails.memberId}
                                    </span>
                                </p>
                            </div>
                        ) : (
                            <>
                                <div className="row-elements">
                                    <div className="finalize-memberid-text">
                                        {finalizeDetailsContent.memberIdTitle}
                                    </div>
                                    {!profile && size < TABLET_BREAKPOINT && (
                                        <div className="sign-middle-button">
                                            <SecondaryButton
                                                link={
                                                    finalizeDetailsContent.registerSignInUrl ||
                                                    '#$userSignIn'
                                                }
                                            >
                                                {
                                                    finalizeDetailsContent.registerSignInButtonText
                                                }
                                            </SecondaryButton>
                                        </div>
                                    )}
                                    <div className="form__div">
                                        <input
                                            data-testid="member-id-number"
                                            placeholder=" "
                                            name="memberId"
                                            id="member-id"
                                            className={`form__input`}
                                            value={finalizeDetails.memberId}
                                            onInput={handleInputChange}
                                            onBlur={onTouch}
                                            aria-label={
                                                'Enter' +
                                                finalizeDetailsContent.memberIdPlaceholderText +
                                                ` ${
                                                    isTouched.memberId
                                                        ? validate().memberId
                                                        : ''
                                                }`
                                            }
                                        />
                                        <label
                                            htmlFor="member-id"
                                            className={`form__label`}
                                        >
                                            {
                                                finalizeDetailsContent.memberIdPlaceholderText
                                            }
                                        </label>
                                    </div>
                                    {isTouched.memberId &&
                                    validate().memberId ? (
                                        <div>
                                            <span className="form__error">
                                                {validate().memberId}
                                            </span>
                                        </div>
                                    ) : null}
                                    <div className="find-member-id">
                                        <Link
                                            className="find-member-id-text"
                                            onClick={memberIdAnalytics}
                                            tabIndex={0}
                                            href={
                                                finalizeDetailsContent.memberIdHelpLink
                                            }
                                            target="_blank"
                                        >
                                            {
                                                finalizeDetailsContent.memberIdHelpLinkText
                                            }
                                        </Link>
                                    </div>
                                </div>
                                {!profile && size >= TABLET_BREAKPOINT && (
                                    <div className="finalize-member-signin">
                                        <div className="finalize-member-button">
                                            <SecondaryButton
                                                link={
                                                    finalizeDetailsContent.registerSignInUrl ||
                                                    '#$userSignIn'
                                                }
                                            >
                                                {
                                                    finalizeDetailsContent.registerSignInButtonText
                                                }
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
            <hr />
            <div className="finalize-member-rebates-row-info">
                <fieldset>
                    <p className="finalize-member-contact-info">
                        {finalizeDetailsContent.addressTitle}
                    </p>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <div className="form__div">
                                <input
                                    data-testid="member-id-number"
                                    name="firstName"
                                    placeholder=" "
                                    id="first-name"
                                    className={`form__input`}
                                    value={finalizeDetails.firstName}
                                    onInput={handleInputChange}
                                    onBlur={onTouch}
                                    aria-label={
                                        'Enter' +
                                        finalizeDetailsContent.firstNamePlaceholderText +
                                        ` ${
                                            isTouched.firstName
                                                ? validate().firstName
                                                : ''
                                        }`
                                    }
                                />
                                <label
                                    htmlFor="first-name"
                                    className={`form__label`}
                                >
                                    {
                                        finalizeDetailsContent.firstNamePlaceholderText
                                    }
                                </label>
                            </div>
                            {isTouched.firstName && validate().firstName ? (
                                <div>
                                    <span className="form__error">
                                        {validate().firstName}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <div className="finalize-row-elements">
                            <div className="form__div">
                                <input
                                    data-testid="member-id-number"
                                    name="lastName"
                                    placeholder=" "
                                    id="last-name"
                                    className={`form__input`}
                                    value={finalizeDetails.lastName}
                                    onInput={handleInputChange}
                                    onBlur={onTouch}
                                    aria-label={
                                        'Enter' +
                                        finalizeDetailsContent.lastNamePlaceholderText +
                                        ` ${
                                            isTouched.lastName
                                                ? validate().lastName
                                                : ''
                                        }`
                                    }
                                />
                                <label
                                    htmlFor="last-name"
                                    className={`form__label`}
                                >
                                    {
                                        finalizeDetailsContent.lastNamePlaceholderText
                                    }
                                </label>
                            </div>
                            {isTouched.lastName && validate().lastName ? (
                                <div>
                                    <span className="form__error">
                                        {validate().lastName}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <div className="form__div">
                                <input
                                    data-testid="member-id-number"
                                    name="streetAddress"
                                    placeholder=" "
                                    id="street-address"
                                    className={`form__input`}
                                    value={finalizeDetails.streetAddress}
                                    onInput={handleInputChange}
                                    onBlur={onTouch}
                                    aria-label={
                                        'Enter' +
                                        finalizeDetailsContent.streetAddressPlaceholderText +
                                        ` ${
                                            isTouched.streetAddress
                                                ? validate().streetAddress
                                                : ''
                                        }`
                                    }
                                />
                                <label
                                    htmlFor="street-address"
                                    className={`form__label`}
                                >
                                    {
                                        finalizeDetailsContent.streetAddressPlaceholderText
                                    }
                                </label>
                            </div>
                            {isTouched.streetAddress &&
                            validate().streetAddress ? (
                                <div>
                                    <span className="form__error">
                                        {validate().streetAddress}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <div className="form__div">
                                <input
                                    data-testid="member-id-number"
                                    name="city"
                                    placeholder=" "
                                    id="finalize-city"
                                    className={`form__input`}
                                    value={finalizeDetails.city}
                                    onInput={handleInputChange}
                                    onBlur={onTouch}
                                    aria-label={
                                        'Enter' +
                                        finalizeDetailsContent.cityPlaceholderText +
                                        ` ${
                                            isTouched.city
                                                ? validate().city
                                                : ''
                                        }`
                                    }
                                />
                                <label
                                    htmlFor="finalize-city"
                                    className={`form__label`}
                                >
                                    {finalizeDetailsContent.cityPlaceholderText}
                                </label>
                            </div>
                            {isTouched.city && validate().city ? (
                                <div>
                                    <span className="form__error">
                                        {validate().city}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <select
                                value={finalizeDetails.stateDropdown}
                                name="stateDropdown"
                                onChange={handleInputChange}
                                className="state-dropdown"
                                onBlur={onTouch}
                                aria-label={
                                    finalizeDetailsContent.stateErrorMessage
                                }
                            >
                                <option value="State" disabled>
                                    {
                                        finalizeDetailsContent.statePlaceholderText
                                    }
                                </option>
                                {stateOptions.map(state => {
                                    return (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    );
                                })}
                            </select>
                            {isTouched.stateDropdown &&
                            validate().stateDropdown ? (
                                <div>
                                    <span className="form__error">
                                        {validate().stateDropdown}
                                    </span>
                                </div>
                            ) : null}
                        </div>

                        <div className="finalize-row-elements">
                            <div className="form__div">
                                <input
                                    data-testid="member-id-number"
                                    name="zipCode"
                                    placeholder=" "
                                    id="zip-code"
                                    className={`form__input`}
                                    value={finalizeDetails.zipCode}
                                    onInput={handleInputChange}
                                    onBlur={onTouch}
                                    aria-label={
                                        'Enter' +
                                        finalizeDetailsContent.zipCodePlaceholderText +
                                        ` ${
                                            isTouched.zipCode
                                                ? validate().zipCode
                                                : ''
                                        }`
                                    }
                                />
                                <label
                                    htmlFor="zip-code"
                                    className={`form__label`}
                                >
                                    {
                                        finalizeDetailsContent.zipCodePlaceholderText
                                    }
                                </label>
                            </div>
                            {isTouched.zipCode && validate().zipCode ? (
                                <div>
                                    <span className="form__error">
                                        {validate().zipCode}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <p className="finalize-member-contact-info">
                        {finalizeDetailsContent.emailTitle}
                    </p>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <div className="form__div">
                                <input
                                    data-testid="member-id-number"
                                    name="email"
                                    placeholder=" "
                                    id="email"
                                    className={`form__input`}
                                    value={finalizeDetails.email}
                                    onInput={handleInputChange}
                                    onBlur={onTouch}
                                    aria-label={
                                        'Enter' +
                                        finalizeDetailsContent.emailPlaceholderText +
                                        ` ${
                                            isTouched.email
                                                ? validate().email
                                                : ''
                                        }`
                                    }
                                />
                                <label
                                    htmlFor="email"
                                    className={`form__label`}
                                >
                                    {
                                        finalizeDetailsContent.emailPlaceholderText
                                    }
                                </label>
                            </div>
                            {isTouched.email && validate().email ? (
                                <div>
                                    <span className="form__error">
                                        {validate().email}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <div className="form__div">
                                <input
                                    data-testid="member-id-number"
                                    name="confirmEmail"
                                    placeholder=" "
                                    id="confirm-email"
                                    className={`form__input`}
                                    value={finalizeDetails.confirmEmail}
                                    onInput={handleInputChange}
                                    onBlur={onTouch}
                                    aria-label={
                                        finalizeDetailsContent.reEnterEmailPlaceholderText +
                                        ` ${
                                            isTouched.confirmEmail
                                                ? validate().confirmEmail
                                                : ''
                                        }`
                                    }
                                />
                                <label
                                    htmlFor="confirm-email"
                                    className={`form__label`}
                                >
                                    {
                                        finalizeDetailsContent.reEnterEmailPlaceholderText
                                    }
                                </label>
                            </div>
                            {isTouched.confirmEmail &&
                            validate().confirmEmail ? (
                                <div>
                                    <span className="form__error">
                                        {validate().confirmEmail}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="finalize-member-rebates-checkbox">
                        <div className="finalize-checkbox">
                            <input
                                name="isEmailCommunications"
                                type="checkbox"
                                id="finalize-checkbox"
                                checked={finalizeDetails.isEmailCommunications}
                                onChange={handleInputChange}
                                className="checkbox-finalize"
                            />
                        </div>
                        <label
                            className="finalize-checkbox-label"
                            htmlFor="finalize-checkbox"
                        >
                            {finalizeDetailsContent.mailCheckboxText}
                        </label>
                    </div>
                    <div className="finalize-checkbox-email">
                        <p className="checkbox-email">
                            {finalizeDetailsContent.noteText}
                        </p>
                    </div>
                </fieldset>
            </div>
            <div className="finalize-response-button">
                <PrimaryButton
                    ref={primaryButtonRef}
                    chevron={true}
                    color="dark"
                    fill="fill"
                    disabled={!validate().isValid}
                    onClick={() => setButtonState('submit')}
                >
                    {finalizeDetailsContent.continueButtonText}
                </PrimaryButton>
            </div>
        </form>
    );
};
