import React, { useState, useEffect, useContext } from 'react';
import { InputField } from '../../../../common';
import { useVinRegLookupContent } from './use-vin-reg-lookup-content';
import ServiceHandler from '../../../../../services/service-handler';
import VehicleTypeService from '../../../../../services/vehicle-type-service/vehicle-type-service';
import ServerContext from '../../../../../contexts/serverContext';

interface Props {
    vinOrRegInputFieldLabel: string;
    name: string;
    customErrorMessage: string;
    goToNextPage: (action: string) => void;
    showErrorPage: (isError: boolean) => void;
    isCtaClicked: boolean;
    resetIsCtaClicked: () => void;
}

export const VinRegLookup = (props: Props) => {
    const vinRegLookupContent = useVinRegLookupContent();
    const [vinRegNo, setVinRegNo] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [apiLoading, setApiLoading] = useState<boolean>(false);
    const [vinNo, setVinNo] = useState<string>('');
    const [pageAvailable, setPageAvailable] = useState<boolean>(false);
    const [defaultstatus, setDefaultStatus] = useState<boolean>(true);
    const { vinOrRegInputFieldLabel, name, customErrorMessage } = props;
    const {
        vinRegLookup,
        invalidVINorRegnoErrorMessage,
        complementaryWFOErrorMessage,
        vehicleNotFoundErrorMessage,
    } = vinRegLookupContent;
    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    const isMajorError = (error: any): boolean => {
        return (
            error.response &&
            (error.response.status == 500 ||
                error.response.status == 404 ||
                error.response.status == 403)
        );
    };
    const callPageService = (vin: string, goToNextPage: boolean) => {
        if (name === 'maintenance-schedule-vin') {
            ServiceHandler.MaintenanceScheduleService.getMaintenanceSchedule(
                vin
            )
                .then(maintenanceData => {
                    if (!maintenanceData) {
                        setErrorMessage(customErrorMessage);
                        setApiLoading(false);
                        setPageAvailable(false);
                    } else if (maintenanceData && maintenanceData.values) {
                        setErrorMessage('');
                        setPageAvailable(true);
                        setApiLoading(false);
                        setDefaultStatus(false);
                        goToNextPage && props.goToNextPage(vin);
                    }
                })
                .catch(error => {
                    setPageAvailable(false);
                    setApiLoading(false);
                    if (isMajorError(error)) {
                        setErrorMessage(customErrorMessage);
                    } else {
                        props.showErrorPage(true);
                    }
                });
        }
    };

    const handleVehicleTypeServiceRequest = (
        vinRegNo: string,
        goToNextPage: boolean
    ) => {
        new VehicleTypeService()
            .request(
                vinRegNo,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            )
            .then(result => {
                if (!result) {
                    setErrorMessage(vehicleNotFoundErrorMessage);
                    setApiLoading(false);
                    setVinNo('');
                } else {
                    setErrorMessage('');
                    const vinNumber: string =
                        result.vehicleData?.vin?.toUpperCase() || '';
                    setVinNo(vinNumber);
                    if (vinNumber !== '' && vinNumber !== null) {
                        callPageService(vinNumber, goToNextPage);
                    }
                }
            })
            .catch((error: any) => {
                setApiLoading(false);
                if (isMajorError(error)) {
                    props.showErrorPage(true);
                } else {
                    setErrorMessage(vehicleNotFoundErrorMessage);
                }
            });
    };

    const getVINRegLookup = (vinRegNo: string, goToNextPage: boolean) => {
        if (vinRegNo.length > 10) {
            setApiLoading(true);
            setVinNo(vinRegNo);
            callPageService(vinRegNo, goToNextPage);
        } else {
            setApiLoading(true);
            handleVehicleTypeServiceRequest(vinRegNo, goToNextPage);
        }
    };

    const getVinRegRegex = () => {
        const vinOnlyRegex = /(^(?!WFO)[A-Za-z0-9]{17}$)/;
        const vinWithRegNumRegex = /(^(?!WFO)[A-Za-z0-9]{17}$)|(^[A-Za-z0-9 -]{3,10}$)/;

        return vinRegLookup ? vinWithRegNumRegex : vinOnlyRegex;
    };

    const validateVinRegNo = (value: any, goToNextPage: boolean) => {
        if (value === '') {
            setErrorMessage(invalidVINorRegnoErrorMessage);
        } else {
            const vinValue = value.toUpperCase();
            setVinRegNo(vinValue);
            const vinRegEx = getVinRegRegex();
            if (vinValue.match(vinRegEx)) {
                setErrorMessage('');
                getVINRegLookup(vinValue, goToNextPage);
            } else if (
                vinValue.match(/[A-Za-z0-9]{17}$/) &&
                vinValue.startsWith('WFO')
            ) {
                setErrorMessage(complementaryWFOErrorMessage);
            } else {
                setErrorMessage(invalidVINorRegnoErrorMessage);
            }
        }
    };
    const validatePage = () => {
        if (pageAvailable && props.isCtaClicked) {
            props.goToNextPage(vinNo);
        } else if (!pageAvailable && props.isCtaClicked) {
            validateVinRegNo(vinRegNo, false);
        }
    };

    useEffect(() => {
        if (apiLoading) {
            setTimeout(() => {
                validatePage();
            }, 4000);
        } else {
            validatePage();
        }
    }, [pageAvailable, props.isCtaClicked]);

    const updateVinInput = (e: any) => {
        props.resetIsCtaClicked();
        if (e.type === 'change') {
            setVinRegNo(e.target.value);
        }
        if (e.type === 'blur') {
            validateVinRegNo(e.target.value, false);
        }
    };

    return (
        <InputField
            label={vinOrRegInputFieldLabel}
            ariaLabel={vinOrRegInputFieldLabel}
            name="VIN"
            maxLength={17}
            className={`${name} vin-textField`}
            errorMessage={errorMessage}
            value={vinRegNo}
            onChange={e => updateVinInput(e)}
            onBlur={e => updateVinInput(e)}
            onFocus={e => updateVinInput(e)}
            fdsStyle={true}
            validate={defaultstatus}
        />
    );
};
