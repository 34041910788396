import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ViewTemplate } from '..';
import { PageType } from '../view-template/page-type';
import { Greeting } from './components/greeting/greeting';
import { FeatureTrio } from './components/feature-trio/feature-trio';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { FeatureContentA } from './components/feature-content-a/feature-content-a';
import { FeatureContentB } from './components/feature-content-b/feature-content-b';
import { FeaturedArticlesCarousel } from './components/featured-articles-carousel/featured-articles-carousel';
import { useFeatureCards } from './hooks/use-feature-cards';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import { useFeatureContentA } from './hooks/use-feature-a-content';
import { useFeatureContentB } from './hooks/use-feature-b-content';
import { useVehicleDashboardContent } from './hooks/use-vehicle-dashboard-content';
import { useVehiclePageContent } from './hooks/use-vehicle-page-content';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import useUserProfile from '../../../hooks/use-user-profile';
import { MouseflowService } from '../../../services/mouseflow-service/mouseflow-service';
import './vehicle-portal.scss';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import NotificationBanner from '../../../components/sections/notification-banner/notification-banner';
import { useNotificationBannerContent } from '../notification-banner/hooks/use-notification-banner';
import backgroundImageDesktop from '../../../assets/fm-masthead-banner-desktop.png';
import lincolnBackgroundImageDesktop from '../../../assets/lincoln-masthead-banner-desktop.jpeg';
import backgroundImageMobile from '../../../assets/fm-masthead-banner-mobile.jpg';
import lincolnBackgroundImageMobile from '../../../assets/lincoln-masthead-banner-mobile.jpeg';
import ServerContext from '../../../contexts/serverContext';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import { LoginButton } from '../login-button/login-button';
import { StringReplacer } from '../../utils/string-replacer/string-replacer';
import VehicleImageService from './services/vehicle-image-service/vehicle-image-service';
import { VehicleChecklistCarousel } from '../vehicle-checklist-carousel/vehicle-checklist-carousel';
import MMOTAModal from '../../vehicle-checklist/vehicle-checklist-mmota/mmota-modal/mmota-modal';
import { ConnectedVehicleBar } from '../connected-vehicle-bar/connected-vehicle-bar';
import { useSkinnyBannerContent } from '../../common/skinny-banner/hook/use-skinny-banner';
import SkinnyBanner from '../../common/skinny-banner/skinny-banner';
import { QuickLinksGrid } from '../quick-links-grid/quick-links-grid';
import { MMOTAStatusResponse } from '../../../services/mmota-status-service/mmota-status.interface';
import MmotaStatusService from '../../../services/mmota-status-service/mmota-status-service';
import {
    ConnectedVehicleResponse,
    Vehiclestatus,
} from '../../../models/connected-vehicle';
import ConnectedVehicleService from '../../../services/connected-vehicle-service/connected-vehicle-service';
import { BRAND } from '../../../constants';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { FMCard, FMCarousel, FMFullWidthBanner } from '@own/fds-react';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props {
    page: PageType;
    vehicleAttributes: VehicleAttributes;
    topMasthead?: ReactNode;
}

export const VehiclePortal = (props: Props) => {
    const vehiclePageContent = useVehiclePageContent();
    const vehicleDashboardContent = useVehicleDashboardContent();
    const contentFeatureA = useFeatureContentA(
        'vehicle-data',
        getVehicleDataSelector('feature-content-a', props.vehicleAttributes),
        true
    );
    const contentFeatureB = useFeatureContentB(
        'vehicle-data',
        getVehicleDataSelector('feature-content-b', props.vehicleAttributes),
        true
    );
    const PAGE_DASHBOARD = 'DASHBOARD';
    const profile = useUserProfile();

    const skinnyBannerContent = useSkinnyBannerContent(
        'vehicles',
        getVehicleDataSelector('skinny-banner', props.vehicleAttributes),
        true
    );
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const serverContext = useContext(ServerContext);
    const isLincoln = serverContext.brand === 'lincoln';
    const [authenticationState] = useAuthentication();
    const vehicleName = `${props.vehicleAttributes?.year ?? ''} ${
        props.vehicleAttributes.model
    }`;
    const isPageDashboard = props.page === PAGE_DASHBOARD;
    const breadcrumb = isPageDashboard
        ? vehicleDashboardContent?.breadcrumb
        : vehicleName;
    const {
        fmaRegion,
        brand,
    } = new AppConfigurationService().getAppConfiguration();
    const currentVersion = brand === BRAND.lincoln.LONG_NAME;
    const [fireEvents] = useAnalytics();
    const [connectedVehicleInfo, setConnectedVehicleInfo] = useState<
        Vehiclestatus
    >();
    const [vehicleStatus, setVehicleStatus] = useState<
        ConnectedVehicleResponse | undefined
    >();

    function clientSideSetItem() {
        if (!ServerSideService.isClientSide()) return;

        const apaCheck = profile && fmaRegion === 'apa';
        if (apaCheck) {
            sessionStorage.setItem('email', profile.profile.email);
        }

        if (props.vehicleAttributes.vin) {
            sessionStorage.setItem('selectedVin', props.vehicleAttributes.vin);
            localStorage.setItem('USER_VIN', props.vehicleAttributes.vin);
        } else {
            sessionStorage.removeItem('selectedVin');
        }
    }

    clientSideSetItem();
    const featureTrioContent = useFeatureCards(
        'vehicle-data',
        getVehicleDataSelector('feature-trio', props.vehicleAttributes),
        'featuretrio',
        true,
        props.vehicleAttributes.vin
    );
    const featuredArticlesCarouselContent = useFeatureCards(
        'vehicle-data',
        getVehicleDataSelector(
            'featured-articles-carousel',
            props.vehicleAttributes
        ),
        'articlescarousel',
        true
    );

    const [vehicleImageUrl, setVehicleImageUrl] = useState('');

    const VehicleDashboardPageHeaderText = () => {
        const testId = `vehicle-portal-header-${vehicleName.replace(
            / /g,
            '-'
        )}`;

        return (
            <div className="vehicle-portal-cta">
                <Greeting
                    firstName={profile ? profile.profile.firstName : ''}
                    testId={testId}
                    content={vehiclePageContent}
                >
                    {vehiclePageContent?.vehInfoAuthText && (
                        <span className="vehicle-portal-desc">
                            {vehiclePageContent.vehInfoAuthText.replace(
                                '%ymm%',
                                vehicleName
                            )}
                        </span>
                    )}
                </Greeting>
            </div>
        );
    };
    const YmmVinPageHeaderText = () => {
        const testId = `vehicle-portal-header-${vehicleName.replace(
            / /g,
            '-'
        )}`;
        return (
            <div className="vehicle-portal-cta">
                <h1 className="vehicle-portal-header" data-testid={testId}>
                    {vehicleName}
                    {vehiclePageContent?.vehInfoUnauthText && (
                        <span className="vehicle-portal-subhead">
                            {vehiclePageContent.vehInfoUnauthText}
                        </span>
                    )}
                </h1>

                {vehiclePageContent?.registerOrSignInText &&
                    authenticationState ===
                        AuthenticationState.Unauthenticated && (
                        <div className="vehicle-portal-desc">
                            <StringReplacer
                                rules={[
                                    {
                                        replace: '%signin%',
                                        replaceWith: (
                                            <LoginButton
                                                page="YMM"
                                                fill="outline"
                                                chevron={false}
                                                dataTestId="login"
                                                customCtaTitle={
                                                    vehiclePageContent?.registerOrSignInCTALabel
                                                }
                                                analyticsEventName="smash-home-signin-onclick-event"
                                            />
                                        ),
                                    },
                                ]}
                            >
                                {vehiclePageContent.registerOrSignInText}
                            </StringReplacer>
                        </div>
                    )}
            </div>
        );
    };

    const VehiclePortalTopComponent = () => {
        if (isPageDashboard) {
            return (
                <>
                    <VehicleDashboardPageHeaderText />
                    <VehicleImageSection />
                </>
            );
        } else {
            return (
                <>
                    <YmmVinPageHeaderText />
                    <VehicleImageSection />
                </>
            );
        }
    };
    useEffect(() => {
        setVehicleImageUrl('');
        if (props.vehicleAttributes.vin) {
            new VehicleImageService()
                .getVehicleImage(props.vehicleAttributes, props.page)
                .then((response: any) => {
                    if (response) {
                        setVehicleImageUrl(response);
                    }
                });
        }
    }, [props.vehicleAttributes.vin]);

    const VehicleImageSection = () => {
        const isNa =
            (serverContext.current3LetterCountryCode &&
                serverContext.current3LetterCountryCode.toLowerCase() ===
                    'usa') ||
            serverContext.current3LetterCountryCode.toLowerCase() === 'can';

        return (
            <>
                {vehicleImageUrl &&
                    vehiclePageContent &&
                    !vehiclePageContent.hideVehicleImage && (
                        <img
                            className={
                                isNa ? 'vehicle-image-na' : 'vehicle-image'
                            }
                            src={vehicleImageUrl}
                            alt={vehicleImageUrl ? vehicleName : ''}
                            crossOrigin="anonymous"
                        />
                    )}
            </>
        );
    };

    useEffect(() => {
        MouseflowService.setVariable(
            'vehicleYMM',
            `${props.vehicleAttributes.year}·${props.vehicleAttributes.make}·${props.vehicleAttributes.model}`
        );
    }, [
        [
            props.vehicleAttributes.year,
            props.vehicleAttributes.make,
            props.vehicleAttributes.model,
            props.vehicleAttributes.vin,
        ].join(' '),
    ]);
    const notificationBannerContent = useNotificationBannerContent(
        'vehicles',
        'notification-banner'
    );

    const getBackgroundImageDesktop = () => {
        if (isLincoln) {
            return lincolnBackgroundImageDesktop;
        } else if (
            serverContext.current3LetterCountryCode &&
            serverContext.current3LetterCountryCode.toLowerCase() === 'usa'
        ) {
            return `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-owner/ford/us/en-us/images/vehicle-page/fm-masthead-banner-desktop.jpg`;
        } else return backgroundImageDesktop;
    };

    const getBackgroundImageMobile = () => {
        if (isLincoln) {
            return lincolnBackgroundImageMobile;
        } else if (
            serverContext.current3LetterCountryCode &&
            serverContext.current3LetterCountryCode.toLowerCase() === 'usa'
        ) {
            return `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-owner/ford/us/en-us/images/vehicle-page/fm-masthead-banner-mobile.jpg`;
        } else return backgroundImageMobile;
    };
    const [isMMOTAModalVisible, setIsMMOTAModalVisible] = useState(false);
    const displayMMOTAModal = () => {
        setIsMMOTAModalVisible(true);
    };
    const closeMMOTAModal = () => {
        setIsMMOTAModalVisible(false);
    };

    const [otaStatus, setOtaStatus] = useState<MMOTAStatusResponse>();
    useEffect(() => {
        if (
            props?.vehicleAttributes?.vin &&
            authenticationState === AuthenticationState.Authenticated &&
            props.page === 'DASHBOARD'
        )
            new MmotaStatusService()
                .getStatus(props?.vehicleAttributes?.vin)
                .then(response => {
                    if (response) {
                        setOtaStatus(response);
                    }
                });
    }, [props?.vehicleAttributes?.vin, authenticationState]);
    const mountMMOTAModal = () => {
        if (isMMOTAModalVisible && otaStatus) {
            return (
                <MMOTAModal
                    isVisible={isMMOTAModalVisible}
                    onClose={closeMMOTAModal}
                    vin={props?.vehicleAttributes?.vin}
                    otaStatus={otaStatus}
                />
            );
        }

        return <></>;
    };
    useEffect(() => {
        setConnectedVehicleInfo(undefined);
        if (
            !vehicleStatus &&
            props.vehicleAttributes.vin &&
            authenticationState == AuthenticationState.Authenticated
        )
            new ConnectedVehicleService()
                .getVehicleStatus(
                    props.vehicleAttributes.vin,
                    props.vehicleAttributes?.year
                )
                .then(vehicleStatusData => {
                    setVehicleStatus(vehicleStatusData);
                });
        if (
            authenticationState === AuthenticationState.Authenticated &&
            props.page === 'DASHBOARD' &&
            props.vehicleAttributes.vin
        ) {
            new ConnectedVehicleService()
                .getVehicleStatus(
                    props.vehicleAttributes?.vin || '',
                    props.vehicleAttributes?.year
                )
                .then(response => {
                    if (response?.vehiclestatus) {
                        setConnectedVehicleInfo(response.vehiclestatus);
                    } else {
                        setConnectedVehicleInfo(undefined);
                    }
                })
                .catch(() => {
                    setConnectedVehicleInfo(undefined);
                });
        }
    }, [props?.vehicleAttributes?.vin, authenticationState]);
    const fullWidthBannerEventClick = async () => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            featureContentCtaHrefUrl
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (featureContentCtaAnalytic) {
            fireEvents('feature-content-b-click-event', undefined, {
                ctaType: featureContentCtaAnalytic,
            });
        }
    };
    const featureContentCtaHrefUrl = contentFeatureB?.ctaLink ?? '';
    const splitUrl = featureContentCtaHrefUrl
        ?.split('/')
        .filter(slug => slug && slug !== '');
    let featureContentCtaAnalytic = splitUrl?.pop();
    featureContentCtaAnalytic = featureContentCtaAnalytic?.replaceAll('-', ' ');
    return (
        <>
            {vehiclePageContent && vehicleDashboardContent ? (
                <>
                    <div>
                        {notificationBannerContent &&
                            notificationBannerContent.bannerText && (
                                <NotificationBanner
                                    hide={notificationBannerContent?.hide}
                                    type={notificationBannerContent?.type}
                                    bannerDismissible={
                                        notificationBannerContent?.bannerDismissible
                                    }
                                    dismissAriaLabel={
                                        notificationBannerContent?.dismissAriaLabel
                                    }
                                    bannerText={
                                        notificationBannerContent?.bannerText
                                    }
                                    showChevron={
                                        notificationBannerContent?.showChevron
                                    }
                                    shortCode={{
                                        ctaType: 'vehicle portal banner cta',
                                    }}
                                    eventName={'vehicle-portal-banner-cta'}
                                />
                            )}
                    </div>
                    <ViewTemplate
                        topMasthead={props.topMasthead}
                        page={props.page}
                        breadcrumb={breadcrumb}
                        ctaTopComponent={<VehiclePortalTopComponent />}
                        backgroundImageDesktop={getBackgroundImageDesktop()}
                        backgroundImageMobile={getBackgroundImageMobile()}
                        className="vehicle-portal"
                        vehicleAttributes={props.vehicleAttributes}
                        carousel={
                            featuredArticlesCarouselContent &&
                            (currentVersion ? (
                                <FeaturedArticlesCarousel
                                    {...featuredArticlesCarouselContent}
                                />
                            ) : (
                                <FMCarousel
                                    isUniMode={
                                        featuredArticlesCarouselContent.cards
                                            ?.length < 4
                                    }
                                    items={
                                        featuredArticlesCarouselContent.cards
                                    }
                                    render={(item: FMCardProps) => (
                                        <FMCard
                                            {...item}
                                            imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imageSrc}`}
                                            ctaType={'primary'}
                                            ctaTheme={'light'}
                                        >
                                            {item?.children}
                                        </FMCard>
                                    )}
                                    {...featuredArticlesCarouselContent}
                                />
                            ))
                        }
                    >
                        <div>
                            <ConnectedVehicleBar
                                vehicleAttributes={props.vehicleAttributes}
                                pageType={props.page}
                                connectedVehicleInfo={connectedVehicleInfo}
                            />
                            <VehicleChecklistCarousel
                                vehicleAttributes={props?.vehicleAttributes}
                                displayMMOTAModal={displayMMOTAModal}
                                otaStatus={otaStatus}
                                pageType={props.page}
                                vehicleStatus={vehicleStatus}
                            />
                        </div>
                        {skinnyBannerContent && (
                            <SkinnyBanner {...skinnyBannerContent} />
                        )}
                        {featureTrioContent &&
                            (currentVersion ? (
                                <FeatureTrio
                                    page={props.page}
                                    {...featureTrioContent}
                                />
                            ) : (
                                <FMCarousel
                                    items={featureTrioContent.cards}
                                    isUniMode={true}
                                    render={(item: FMCardProps) => (
                                        <FMCard
                                            {...item}
                                            imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imageSrc}`}
                                            ctaType={'primary'}
                                            ctaTheme={'light'}
                                        >
                                            {item?.children}
                                        </FMCard>
                                    )}
                                    {...featureTrioContent}
                                />
                            ))}
                        {contentFeatureA && (
                            <FeatureContentA {...contentFeatureA} />
                        )}
                        {contentFeatureB &&
                            (currentVersion ? (
                                <FeatureContentB {...contentFeatureB} />
                            ) : (
                                <FMFullWidthBanner
                                    title={contentFeatureB.title}
                                    imgPath={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        contentFeatureB.backgroundImage
                                    }
                                    subCopy={contentFeatureB.description}
                                    button={{
                                        type: 'tertiary',
                                        chevron: 'right',
                                        label: contentFeatureB.ctaLabel,
                                        href: contentFeatureB.ctaLink,
                                        disabled: false,
                                        onClick: () =>
                                            fullWidthBannerEventClick(),
                                    }}
                                    imgAlt={contentFeatureB.backgroundImageAlt}
                                />
                            ))}
                        <QuickLinksGrid {...props} />
                    </ViewTemplate>
                    {mountMMOTAModal()}
                </>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
};
