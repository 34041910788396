import React, { useEffect, useState } from 'react';
import { FinalizeDetails } from './step4-finalize-details/finalize-details';
import { RedeemRebate } from './step1-redeem-rebate/redeem-rebate';
import { ChooseMethod } from './step3-choose-method/choose-method';
import { SelectDealer } from './step2-select-dealer/select-dealer';
import { ReviewAndSubmit } from './step5-review-and-submit/review-and-submit';
import { RebatesConfirmation } from './step6-confirmation/rebates-confirmation';
import { useRebatesTrackerContent } from '../hooks/use-rebates-tracker-content';
import { Tracker } from '@own/fds-react';
import './redeem-rebates-steps.scss';

export interface RedeemFormData {
    serviceDate?: string;
    vin?: string;
    searchBy?: SearchBy;
    selectedDealerDetails?: SelectedDealerDetails;
    rebateData?: any[];
    year?: string;
    make?: string;
    model?: string;
    finalizeDetails?: FinalizeDetailsData;
    retainedInputMemberId?: any;
    showMemberId?: boolean;
}

interface SearchBy {
    zipCode?: string;
    city?: string;
    state?: string;
    dealerName?: string;
}

export interface SelectedDealerDetails {
    dealerName: string;
    dealerCode: number;
    streetAddress: string;
    city: string;
    state: string;
    dealerZipCode: string;
    phone: string;
    oarDealer: boolean;
    creditDealer: boolean;
}

export interface FinalizeDetailsData {
    memberId?: any;
    firstName?: string;
    lastName?: string;
    streetAddress?: string;
    city?: string;
    stateDropdown?: string;
    zipCode?: string;
    email?: string;
    confirmEmail?: string;
    isEmailCommunications?: boolean;
}

export const RedeemRebatesSteps = () => {
    const rebatesTrackerContent = useRebatesTrackerContent();
    const [size, setSize] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [step, setStep] = useState(1);
    const setCurrentStep = (stepNumber: number) => {
        setStep(stepNumber);
        if (size < 350) {
            window.scrollTo({
                top: 2450,
                behavior: 'smooth',
            });
        } else if (size < 500) {
            window.scrollTo({
                top: 2150,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({
                top: 1350,
                behavior: 'smooth',
            });
        }
    };

    const [redeemFormData, setRedeemFormData] = useState({
        serviceDate: '',
        vin: '',
        searchBy: {
            zipCode: '',
            city: '',
            state: 'State',
            dealerName: '',
        },
        selectedDealerDetails: {
            dealerName: '',
            dealerCode: 0,
            streetAddress: '',
            city: '',
            state: '',
            dealerZipCode: '',
            phone: '',
            oarDealer: false,
            creditDealer: false,
        },
        rebateData: [{}],
        year: '',
        make: '',
        model: '',
        finalizeDetails: {
            memberId: '',
            firstName: '',
            lastName: '',
            streetAddress: '',
            city: '',
            stateDropdown: 'State',
            zipCode: '',
            email: '',
            confirmEmail: '',
            isEmailCommunications: false,
        },
        retainedInputMemberId: '',
        showMemberId: false,
    });

    const setFormData = (data: RedeemFormData) => {
        setRedeemFormData({
            ...redeemFormData,
            serviceDate: data.serviceDate || '',
            vin: data.vin || '',
            searchBy: {
                zipCode: data.searchBy?.zipCode || '',
                city: data.searchBy?.city || '',
                state: data.searchBy?.state || '',
                dealerName: data.searchBy?.dealerName || '',
            },
            selectedDealerDetails: {
                dealerName: data.selectedDealerDetails?.dealerName || '',
                dealerCode: data.selectedDealerDetails?.dealerCode || 0,
                streetAddress: data.selectedDealerDetails?.streetAddress || '',
                city: data.selectedDealerDetails?.city || '',
                state: data.selectedDealerDetails?.state || '',
                dealerZipCode: data.selectedDealerDetails?.dealerZipCode || '',
                phone: data.selectedDealerDetails?.phone || '',
                oarDealer: data.selectedDealerDetails?.oarDealer || false,
                creditDealer: data.selectedDealerDetails?.creditDealer || false,
            },
            rebateData: data.rebateData || [{}],
            year: data.year || '',
            make: data.make || '',
            model: data.model || '',
            finalizeDetails: {
                memberId: data.finalizeDetails?.memberId || '',
                firstName: data.finalizeDetails?.firstName || '',
                lastName: data.finalizeDetails?.lastName || '',
                streetAddress: data.finalizeDetails?.streetAddress || '',
                city: data.finalizeDetails?.city || '',
                stateDropdown: data.finalizeDetails?.stateDropdown || 'State',
                zipCode: data.finalizeDetails?.zipCode || '',
                email: data.finalizeDetails?.email || '',
                confirmEmail: data.finalizeDetails?.confirmEmail || '',
                isEmailCommunications:
                    data.finalizeDetails?.isEmailCommunications || false,
            },
            retainedInputMemberId: data.retainedInputMemberId || '',
            showMemberId: data.showMemberId || false,
        });
    };

    const trackerSteps = [
        {
            order: 1,
            title: rebatesTrackerContent.stepOneText || 'Redeem Rebate(s)',
        },
        {
            order: 2,
            title: rebatesTrackerContent.stepTwoText || 'Select Dealer',
        },
        {
            order: 3,
            title: rebatesTrackerContent.stepThreeText || 'Choose Method',
        },
        {
            order: 4,
            title: rebatesTrackerContent.stepFourText || 'Finalize Details',
        },
        {
            order: 5,
            title: rebatesTrackerContent.stepFiveText || 'Review and Submit',
        },
        {
            order: 6,
            title: rebatesTrackerContent.stepSixText || 'Confirmation',
        },
    ];

    const rebatesComponent = () => {
        switch (step) {
            case 1:
                return (
                    <RedeemRebate
                        redeemFormData={redeemFormData}
                        setCurrentStep={setCurrentStep}
                        setFormData={setFormData}
                    />
                );
            case 2:
                return (
                    <SelectDealer
                        redeemFormData={redeemFormData}
                        setCurrentStep={setCurrentStep}
                        setFormData={setFormData}
                    />
                );
            case 3:
                return (
                    <ChooseMethod
                        redeemFormData={redeemFormData}
                        setCurrentStep={setCurrentStep}
                        setFormData={setFormData}
                    />
                );
            case 4:
                return (
                    <FinalizeDetails
                        redeemFormData={redeemFormData}
                        setCurrentStep={setCurrentStep}
                        setFormData={setFormData}
                    />
                );
            case 5:
                return (
                    <ReviewAndSubmit
                        redeemFormData={redeemFormData}
                        setCurrentStep={setCurrentStep}
                    />
                );
            case 6:
                return (
                    <RebatesConfirmation
                        redeemFormData={redeemFormData}
                        setCurrentStep={setCurrentStep}
                    />
                );

            default:
                return null;
        }
    };
    return (
        <div className="redeem-rebates-tracker-inclusion">
            <Tracker currentStep={step} steps={trackerSteps} />
            {rebatesComponent()}
        </div>
    );
};
