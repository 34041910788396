import { DSL_API_PATH, DSL_BASE_URL } from '../../../../../constants';
import HttpService from '../../../../../services/http-service/http-service';
import ServiceHandler from '../../../../../services/service-handler';
import { VehicleAttributes } from '@own/fds-react/dist/types/vehicleSelector.types';

class VehicleImageService {
    getVehicleImage = async (
        vehicleAttributes: VehicleAttributes,
        page: string
    ): Promise<string | null> => {
        if (vehicleAttributes == undefined) {
            return null;
        }
        if (!vehicleAttributes.vin || vehicleAttributes.year < 2013)
            return null;
        const {
            current3LetterCountryCode,
        } = ServiceHandler.AppConfigurationService;

        const imageId = Buffer.from(vehicleAttributes?.vin).toString('base64');
        const serviceUrlDefault = `${DSL_BASE_URL +
            DSL_API_PATH.VEHICLE_IMAGE_V2}?imageId=${imageId}&year=${
            vehicleAttributes.year
        }&countryCode=${current3LetterCountryCode.toUpperCase()}`;

        if (
            page === 'DASHBOARD' ||
            page === 'VIN' ||
            page === 'Towing Calculator'
        ) {
            const serviceUrlAuth = serviceUrlDefault + '&angle=5';
            return (await this.imageAvailable(serviceUrlDefault))
                ? serviceUrlAuth
                : null;
        }

        return (await this.imageAvailable(serviceUrlDefault))
            ? serviceUrlDefault
            : null;
    };

    private async imageAvailable(serviceUrl: string): Promise<boolean> {
        try {
            const response = HttpService.get(
                serviceUrl,
                true,
                undefined,
                undefined,
                false
            );
            await response;
        } catch (error) {
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }
}
export default VehicleImageService;
