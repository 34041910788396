import { Emplifi } from '../eu-help-router/emplifi';
import ChatbotError from './customErrors';
import serverSideService from '../../../services/server-side-service/server-side-service';
export const KEYS = {
    LAST_MESSAGE: 'chatbot-session',
    CONVERSATION_ID: 'chatbot-conversation',
    TAB_REGISTRY: 'chatbot-tabs',
};
export const ERROR_TYPES = {
    SELF_CLOSING: 'self-closing',
    ACTIONABLE: 'actionable',
};
export const ERROR_TIMERS = {
    STILL_LOADING: 10_000,
    UNABLE_TO_CONNECT: 20_000,
};
export const MESSAGE_TYPES = {
    TEXT: 0,
    QUICK_REPLIES: 2,
    CARDS: 5,
    KNOWLEDGE_BASE: 1000,
};
export const MESSAGE_ORIGIN = {
    BOT: 'bot',
    USER: 'user',
};
export const FEEDBACK_TIMEOUT = 7_000;
export const SNACKBAR_SELF_CLOSING_DELAY = 6_000;
export const TAB_TIMEOUT = 30 * 1_000;
export const TAB_CHECK_INTERVAL = 10 * 1_000;

class EmplifiWrapper extends Emplifi {
    conversationId = serverSideService.isClientSide()
        ? localStorage.getItem(KEYS.CONVERSATION_ID)
        : '';
    id?: string;
    throwChatbotError(previousError: any, newErrorMessage: string) {
        if (previousError instanceof ChatbotError) {
            throw previousError;
        }

        throw new ChatbotError(newErrorMessage);
    }
    async createConversation() {
        return super
            .createConversation()
            .then(data => {
                this.conversationId = data.id;
                window.localStorage.setItem(KEYS.CONVERSATION_ID, data.id);
                return data;
            })
            .then(data => {
                super.setConversationLanguage(data.id);
            })
            .catch(error =>
                this.throwChatbotError(error, 'Unable to connect to server')
            );
    }
    async getBotResponse(userMessage: string) {
        return super
            .getBotResponse(userMessage, this.conversationId || '')
            .then(data => {
                if (!data || !data.generatedResponses) {
                    this.throwChatbotError(null, 'Empty response');
                } else {
                    return data.generatedResponses?.reduce(
                        (acc: any, res: { type: any; responseJson: string }) =>
                            this.parseBotResponse(acc, res),
                        []
                    );
                }
            })
            .catch(error =>
                this.throwChatbotError(error, "Can't send the message")
            );
    }
    async getConversation() {
        const conversationId = window.localStorage.getItem(
            KEYS.CONVERSATION_ID
        );

        if (conversationId !== null) {
            return this.fetchChatHistory(conversationId);
        }
    }
    async getAllMessages() {
        return this.fetchChatHistory(this.conversationId!);
    }
    async fetchChatHistory(conversationId: string) {
        return super
            .getConversation(conversationId)
            .then((data: { messages: any }) => {
                return this.parseChatHistory(data.messages);
            });
    }
    parseOneMessage(origin: string, messageType: any, messageContent: string) {
        switch (messageType) {
            case MESSAGE_TYPES.TEXT:
                if (JSON.parse(messageContent).article) {
                    return [
                        {
                            origin,
                            type: MESSAGE_TYPES.KNOWLEDGE_BASE,
                            content: JSON.parse(messageContent).speech,
                            fullContent: JSON.parse(messageContent).article
                                .html,
                        },
                    ];
                } else {
                    return [
                        {
                            origin,
                            type: MESSAGE_TYPES.TEXT,
                            content: JSON.parse(messageContent).speech,
                        },
                    ];
                }

            case MESSAGE_TYPES.QUICK_REPLIES: {
                const quickRepliesButtons = {
                    origin,
                    type: MESSAGE_TYPES.QUICK_REPLIES,
                    content: JSON.parse(messageContent).replies.map(
                        (repl: { label: any }) => repl.label
                    ),
                    isVertical:
                        JSON.parse(messageContent).orientation === 'vertical',
                };

                if (JSON.parse(messageContent).article) {
                    return [
                        {
                            origin,
                            type: MESSAGE_TYPES.KNOWLEDGE_BASE,
                            content: JSON.parse(messageContent).title,
                            fullContent: JSON.parse(messageContent).article
                                .html,
                        },
                        quickRepliesButtons,
                    ];
                } else {
                    return [
                        {
                            origin,
                            type: MESSAGE_TYPES.TEXT,
                            content: JSON.parse(messageContent).title,
                        },
                        quickRepliesButtons,
                    ];
                }
            }

            case MESSAGE_TYPES.CARDS:
                return [
                    {
                        origin,
                        type: messageType,
                        content: JSON.parse(messageContent).elements,
                    },
                ];
            default:
                return [];
        }
    }

    parseBotResponse(acc: any, res: { type: any; responseJson: string }) {
        return [
            ...acc,
            ...this.parseOneMessage('bot', res.type, res.responseJson),
        ];
    }
    parseChatHistory(messages: any) {
        const parsedMessages = [];
        const userIdRegex = /^us[0-9]+,[0-9A-z-]+$/;

        for (const message of messages) {
            const origin = userIdRegex.test(message.fromServiceId)
                ? 'user'
                : 'bot';

            parsedMessages.push(
                ...this.parseOneMessage(
                    origin,
                    message.contentType,
                    message.content
                )
            );
        }

        return parsedMessages;
    }
}
export default EmplifiWrapper;
