import axios, { AxiosRequestConfig } from 'axios';
import { HttpsProxyAgent } from 'https-proxy-agent';
import httpAdapter from 'axios/lib/adapters/http';
import HttpService from '../http-service/http-service';
import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import {
    ServiceHistoryResponse,
    ServiceHistory,
} from '../../models/service-history';
import { LegalTerms } from '../../models/legal-terms';
import ServiceHandler from '../service-handler';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export interface GetVehicleServiceHistoryResponse {
    consumerId: string;
    userGUID: string;
    vin: string;
    dealerService: DealerService[];
    userService: UserService[];
}
export interface DealerService {
    repairOrderNumber: string;
    repairOrderDate: string;
    vehicleMileage: number;
    laborCost: number;
    partsCost: number;
    miscCost: number;
    repairCost: number;
    serviceLocation: DealerServiceLocation;
    serviceOperations: DealerServiceOperations[];
    partNumbers: PartNumbers;
    nextService: NextService;
}
export interface NextService {
    desc: string;
    milesDesc: string;
    kmsDesc: string;
    dueDate: string;
    mileageInKilometres: string;
    mileageInMiles: string;
}
export interface DealerServiceLocation {
    dealer: Dealer;
}
export interface Dealer {
    authorised: boolean;
    dealerCode?: string;
    dealerName?: string;
    dealerPACode?: string;
}
export interface DealerServiceOperations {
    reactCategory: ReactCategory;
    laborOpCode: string;
    laborOpDesc: string;
}
export interface ReactCategory {
    code: string;
    desc: string;
}
export interface PartNumbers {
    partGroup: PartGroup[];
}
export interface PartGroup {
    code: string;
    desc: string;
}
export interface UserService {
    serviceId: string;
    serviceMileage: number;
    serviceDate: string;
    serviceAmount: number;
    serviceDescription: string;
    servicesPerformed: UserServicesPerformed;
    serviceLocation: UserServiceLocation;
    serviceOperations: UserServiceOperations;
}

export interface UserServicesPerformed {
    scheduled: SevicePerformedSCheduled;
    operations: ServicePerformedOperations[];
}
export interface SevicePerformedSCheduled {
    kmsDesc: string;
    milesDesc: string;
}
export interface ServicePerformedOperations {
    id: string;
    desc: string;
}
export interface UserServiceLocation {
    serviceProvider: ServiceProvider;
    dealer: Dealer;
    selfService: boolean;
    otherServiceLocation: string;
}
export interface ServiceProvider {
    serviceProviderCode: string;
    serviceProviderName: string;
}
export interface ServiceProviders {
    code: string;
    name: string;
}
export interface ServiceProviderValues {
    serviceProviders: ServiceProviders[];
}
export interface ServiceProviderResponse {
    value: ServiceProviderValues;
}
export interface UserServiceOperations {
    reactCategory: ReactCategory[];
}
interface ErrorResponse {
    code: string;
    message: string;
    status: string;
}
export interface AddVehicleServiceHistoryResponse {
    userGUID: string;
    vin: string;
    userService: UserService;
    error: ErrorResponse;
}

export interface DeleteVehicleServiceHistoryResponse {
    successful: boolean;
}
export interface ServiceType {
    value: ServiceTypeValue;
}
export interface ServiceTypeValue {
    serviceTypes: ServiceTypes[];
}
export interface ServiceTypes {
    code: string;
    description: string;
}

export default class VehicleServiceHistory {
    public async getVehicleServiceHistory(
        vin: string
    ): Promise<ServiceHistoryResponse> {
        const appConfig = new AppConfigurationService();
        const { current3LetterCountryCode, currentLanguageCode } = appConfig;
        const isNotEuRegion = !appConfig.isRegionEU();
        const DSL_SERVICE_HISTORY = isNotEuRegion
            ? DSL_API_PATH.SERVICE_HISTORY_AUTH
            : DSL_API_PATH.SERVICE_HISTORY_UNAUTH;

        const headers = isNotEuRegion
            ? HttpService.getAppIdAndAuthTokenRequestHeaders()
            : HttpService.getAppIdRequestHeaders();

        const url = `${DSL_BASE_URL +
            DSL_SERVICE_HISTORY}?vin=${vin}&countryCode=${current3LetterCountryCode.toUpperCase()}&languageCode=${currentLanguageCode}`;

        return HttpService.get<ServiceHistoryResponse>(url, false, {
            headers,
            adapter: httpAdapter,
            proxy: false,
            httpAgent: proxyAgent,
        }).then(response => {
            return response.data;
        });
    }

    public async getVehicleServiceProviders(): Promise<
        ServiceProviderResponse
    > {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            adapter: httpAdapter,
        };

        const url = DSL_BASE_URL + DSL_API_PATH.SERVICE_HISTORY_PROVIDERS;
        return HttpService.get<ServiceProviderResponse>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }

    public async addUpdateServiceHistory(
        serviceHistory: ServiceHistory,
        serviceId: string
    ): Promise<AddVehicleServiceHistoryResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.ADD_SERVICE_HISTORY;
        const {
            current3LetterCountryCode,
        } = ServiceHandler.AppConfigurationService;
        const dateArray = serviceHistory.date?.split('/');
        const dateEdit = dateArray
            ? dateArray[2] + '-' + dateArray[0] + '-' + dateArray[1]
            : '';
        const bodyRequest = `{
            "option": "${serviceHistory.providerType}",
            "reactList": [${serviceHistory.servicesPerformed.map(
                (item: { toString: () => any }) => `"${item}"`
            )}],
            "vin": "${serviceHistory.vin}",
            "servMilege": ${serviceHistory.mileage},
            "servDate": "${dateEdit}",
            "servCost": "${serviceHistory.cost}",
            "emissionInspection": ${serviceHistory.emissionInspection},
            "safetyInspection": ${serviceHistory.safetyInspection},
            "servDesc":"${serviceHistory.notes}",
            "code":"${serviceHistory.provider?.serviceProvider.code}",
            "value":"${serviceHistory.provider?.serviceProvider.value}",
            "servId":"${serviceId ? serviceId : ''}"
        }`;
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            },
            params: {
                vin: serviceHistory.vin,
                countryCode: current3LetterCountryCode.toUpperCase(),
            },

            data: bodyRequest,
        };
        let result: Promise<AddVehicleServiceHistoryResponse>;
        if (!serviceId) {
            result = axios
                .post<AddVehicleServiceHistoryResponse>(
                    url,
                    config.data,
                    config
                )
                .then(response => {
                    return response.data;
                });
            await result.catch(console.error);
            return result;
        } else {
            result = axios
                .put<AddVehicleServiceHistoryResponse>(url, config.data, config)
                .then(response => {
                    return response.data;
                });
            await result.catch(console.error);
            return result;
        }
    }
    public async deleteServiceHistory(
        serviceId: string
    ): Promise<DeleteVehicleServiceHistoryResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.DELETE_SERVICE_HISTORY;

        const config: AxiosRequestConfig = {
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            proxy: false,
            httpsAgent: proxyAgent,
            params: {
                serviceId: serviceId,
            },
        };
        return axios
            .delete<DeleteVehicleServiceHistoryResponse>(url, config)
            .then(response => {
                return response.data;
            });
    }
    public async getServiceTypes(): Promise<ServiceType> {
        const config: AxiosRequestConfig = {
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url = DSL_BASE_URL + DSL_API_PATH.SERVICE_TYPES;
        return HttpService.get<ServiceType>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }
    public async getVehicleLegalTerms(): Promise<LegalTerms> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url = DSL_BASE_URL + DSL_API_PATH.LEGAL_TERMS;
        return HttpService.get<LegalTerms>(url, true, config).then(response => {
            return response.data;
        });
    }
}
