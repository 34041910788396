import React, {
    useState,
    useEffect,
    useRef,
    KeyboardEvent,
    useLayoutEffect,
} from 'react';
import './fm-tabs.scss';
import { FMTabProps, FMTabsProps } from '../../types/fm-tabs.types';
import { KEYBOARD_KEYS } from '../vehicleSelector/vehicleSelector.constants';

const FMTabs = (props: FMTabsProps) => {
    const { panels } = props;
    const panelRef = useRef<HTMLDivElement>(null);
    const tabRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const handleTabKey = (event: KeyboardEvent) => {
        if (
            event.code === KEYBOARD_KEYS.ARROW_UP ||
            event.code === KEYBOARD_KEYS.ARROW_DOWN
        ) {
            event.preventDefault();
        } else if (event.code === KEYBOARD_KEYS.HOME) {
            tabRefs?.current[0]?.focus();
        } else if (event.code === KEYBOARD_KEYS.END) {
            tabRefs?.current[panels?.length]?.focus();
        }
    };

    const handleTabChange = (index: number) => {
        setActiveIndex(index);
    };
    useEffect(() => {
        if (tabRefs.current[activeIndex]) {
            tabRefs.current[activeIndex]?.focus();
        }
    }, [activeIndex]);
    useLayoutEffect(() => {
        const panels = document.querySelectorAll('.fm-tab__panel');
        let imagesToLoad = 0;

        const calculateHeight = () => {
            let maxHeight = 0;
            panels.forEach((panel) => {
                const panelHeight = panel.clientHeight;
                if (panelHeight > maxHeight) {
                    maxHeight = panelHeight;
                }
            });

            panels.forEach((panel: HTMLElement) => {
                panel.style.height = `${maxHeight}px`;
            });
        };

        const handleImageLoad = () => {
            imagesToLoad--;
            if (imagesToLoad === 0) {
                calculateHeight();
            }
        };

        panels.forEach((panel: HTMLElement) => {
            panel.style.height = 'auto';
            const images = panel.getElementsByTagName('img');
            imagesToLoad += images.length;

            for (let i = 0; i < images.length; i++) {
                if (!images[i].complete) {
                    images[i].addEventListener('load', handleImageLoad);
                } else {
                    handleImageLoad();
                }
            }
        });
    }, [window?.innerWidth]);
    return (
        <section className='fm-tabs' ref={panelRef}>
            {panels?.map((panel: FMTabProps, index: number) => {
                const { panel: tabPanel, title, handleClick } = panel;
                const isActive = index === activeIndex;
                const bodyHasMarkup =
                    typeof tabPanel === 'string' &&
                    tabPanel.trim().startsWith('<') &&
                    tabPanel.trim().endsWith('>');

                const panelAnimationClass = [
                    'fm-tab__panel',
                    isActive ? 'animate' : '',
                ]
                    .join(' ')
                    .trim();
                const handleOnChange = (e: any) => {
                    handleTabChange(index);
                    handleClick && handleClick(e);
                };

                return (
                    <React.Fragment key={title}>
                        <input
                            type='radio'
                            id={`tab-${index}`}
                            name='fm-tab-group'
                            onChange={(e) => handleOnChange(e)}
                            defaultChecked={isActive}
                            className='fm-tab__header'
                            onKeyDown={handleTabKey}
                            ref={(el) => (tabRefs.current[index] = el)}
                        />
                        <label
                            htmlFor={`tab-${index}`}
                            className='fm-tab__title'
                        >
                            {title}
                        </label>

                        {!bodyHasMarkup && (
                            <section className={panelAnimationClass}>
                                {tabPanel}
                            </section>
                        )}
                        {bodyHasMarkup && (
                            <section
                                className={panelAnimationClass}
                                dangerouslySetInnerHTML={{
                                    __html: tabPanel,
                                }}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </section>
    );
};
export default FMTabs;
