import siteMapService from '../../services/site-map-service/site-map-service';
import { RECALLS } from '../../support-constants';
import { NavLink } from 'react-router-dom';

export const getRecallsLandingLink = (
    languageRegionCode: string,
    brand: string
): string => {
    const recallsComponent =
        RECALLS.TAKATA_RECALLS_AVAILABLE.includes(
            languageRegionCode.toLowerCase()
        ) && brand.toLowerCase() === 'ford'
            ? 'TakataRecallsPage'
            : 'RecallsPage';

    return siteMapService.getPathFromRouterConfigs(
        languageRegionCode,
        brand,
        recallsComponent,
        []
    );
};
export const isYmmAvailable = (lngRegCode: string, brand: string): boolean => {
    let hasYmm = false;
    RECALLS.YMM_AVAILABLE.forEach(couple => {
        if (
            brand?.toLowerCase() === couple.brand &&
            lngRegCode?.toLowerCase() === couple.lngRegCode
        )
            hasYmm = true;
    });
    return hasYmm;
};

export const RecallPageBreadCrumb = (title: string, href: string) => {
    return (
        <>
            <NavLink
                className="recalls-breadcrumb-link"
                to={href}
                onClick={() => {
                    sessionStorage.removeItem('QUERY_VIN');
                    localStorage.removeItem('USER_VIN');
                }}
            >
                <span>{title}</span>
            </NavLink>
            <span className="back-slash">{'>'}</span>
        </>
    );
};
