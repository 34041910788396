export enum DeviceType {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
    TABLET = 'tablet',
}

const CheckExperienceType = (): DeviceType => {
    let deviceType: DeviceType = DeviceType.DESKTOP;
    const screenWidth = window.innerWidth;

    if (screenWidth >= 768 && screenWidth < 1024) {
        deviceType = DeviceType.TABLET;
    } else if (screenWidth < 768) {
        deviceType = DeviceType.MOBILE;
    } else {
        deviceType = DeviceType.DESKTOP;
    }
    return deviceType;
};

export default CheckExperienceType;
