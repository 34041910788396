import {
    SyncMapsCard,
    SyncMapsConsolidatedResponse,
    SyncMapStatus,
} from '../use-syncs-maps-status-updates';

export const getSyncAccordionContent = (
    syncOption: string,
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined,
    syncGeneration: string,
    syncCards: SyncMapsCard[] | undefined,
    syncMapContent: SyncMapStatus | null,
    dealerOnlyCardExists: boolean | undefined,
    hasTicket: boolean,
    isSync4: boolean
) => {
    return {
        selectedOption: syncOption,
        hasSync:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.hasSync,
        hasUpdate:
            isSync4 ||
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.hasFirmwareUpdate,
        syncGeneration,
        cards: syncCards || [],
        heading: syncMapContent?.syncHeading,
        title: isSync4
            ? syncMapContent?.syncAutomaticUpdates
            : syncMapContent?.syncUpdateAvailable,
        subtitle: syncMapContent?.syncUpdateAvailableDescription,
        uploadPending: syncMapContent?.uploadPendingMessage,
        radioButtons: syncGeneration !== '4',
        upToDate: syncMapContent?.syncIsUpToDate,
        syncMapContent: syncMapContent,
        downloadUrl:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                .downloadUrl,
        downloadUrlError:
            (syncGeneration === '1' ||
                syncGeneration === '2' ||
                syncGeneration === '3') &&
            !syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                .downloadUrl,
        notAvailable: syncMapContent?.notAvailableLabel,
        accordionType: 'sync',
        eulaURL:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.eulaURL,
        error:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.syncError === 'Vehicle identified as SYNC GEN4'
                ? ''
                : syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                      ?.syncError ||
                  syncMapsUpdates?.syncMapUpdateResponse?.error,
        dealerOnlyCardExists: dealerOnlyCardExists,
        hasTicket: hasTicket,
    };
};
